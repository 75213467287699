import {
  ButtonGroup,
  Center,
  IconButton,
  Link,
  Spacer,
  Stack,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useColorsContext } from "@buildsoft-eu/react";

export default function Footer({ minH }) {
  const { i18n } = useTranslation("translation");

  const { footer: footerColors } = useColorsContext();
  const buildsoftWebLanguages = ["nl", "en", "fr", "es"];
  const currentLanguage = buildsoftWebLanguages.filter((language) => {
    return language === i18n.language.split("-")[0];
  });

  const sideSize = "240px";
  const mdHeight = "80px";

  const disclaimer = process.env.REACT_APP_DISCLAIMER;

  const socials = [
    {
      label: "YouTube",
      href: "https://www.youtube.com/channel/UCnGxgfJrvsL9ZEje-BvrdSg",
      icon: AiFillYoutube,
    },
    {
      label: "LinkedIn",
      href: "https://www.linkedin.com/company/buildsoft",
      icon: AiFillLinkedin,
    },
    {
      label: "Twitter",
      href: "https://www.twitter.com/BuildSoft",
      icon: FaXTwitter,
    },
    {
      label: "Facebook",
      href: "https://www.facebook.com/StructuralAnalysisSoftware",
      icon: AiFillFacebook,
    },
    {
      label: "Instagram",
      href: "https://www.instagram.com/buildsoft.eu",
      icon: AiFillInstagram,
    },
  ];

  return (
    <Stack
      as="footer"
      minH={minH}
      w="100%"
      bg={footerColors.background}
      color={footerColors.foreground}
      display="flex"
      justify={{ base: "center" }}
      align={{ base: "center", md: "center" }}
    >
      <Wrap
        w="100%"
        spacing="0"
        m="0"
        justify="center"
        direction={{
          base: "column",
          md: "row",
        }}
      >
        <WrapItem>
          <Center w={sideSize} h={{ base: "20px", md: mdHeight }} />
        </WrapItem>
        <Spacer />
        <WrapItem>
          <Center
            w={{ base: "100%", md: "280px" }}
            h={{ base: "40px", md: mdHeight }}
          >
            <Text fontSize="sm">
              &copy; {new Date().getFullYear()}
              <Link
                isExternal={true}
                href={`https://buildsoft.eu/${currentLanguage}`}
                pl={1}
              >
                BuildSoft
              </Link>
              . {disclaimer}
            </Text>
          </Center>
        </WrapItem>
        <Spacer />
        <WrapItem>
          <Center
            w={{ base: "100%", md: sideSize }}
            h={{ base: "60px", md: mdHeight }}
          >
            <ButtonGroup spacing="1">
              {socials.map((item) => {
                return (
                  <IconButton
                    aria-label={item.label}
                    as="a"
                    bg={footerColors.background}
                    fontSize="24px"
                    href={item.href}
                    icon={<item.icon />}
                    key={item.label}
                    target="_blank"
                  />
                );
              })}
            </ButtonGroup>
          </Center>
        </WrapItem>
      </Wrap>
    </Stack>
  );
}
