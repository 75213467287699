import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  AvatarGroup,
  HStack,
  Text,
  IconButton,
  VStack,
  Tooltip,
} from "@chakra-ui/react";
import { useIdentityContext } from "../contexts/IdentityContext";
import {
  useColorsContext,
  useDisplayPreferencesContext,
} from "@buildsoft-eu/react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { TeamDetailNavLink } from "../services/urlService";
import { IS_NOT_A_MEMBER } from "../services/memberRolesService";

export default function TeamInformation({
  team,
  t,
  previousPage = null,
  organizationName = null,
}) {
  const { user } = useIdentityContext();
  const { panel: panelColors } = useColorsContext();
  const {
    locale: { toDisplayDate },
  } = useDisplayPreferencesContext();

  const getUserRole = () => {
    return (
      team?.memberships.find(
        (m) => m.user.id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id
      )?.role ?? IS_NOT_A_MEMBER
    );
  };

  const displayMembers = (member) => {
    const memberInfo = member?.user;
    return (
      <Tooltip
        key={memberInfo?.id}
        label={memberInfo?.displayName}
        placement="bottom"
        hasArrow
      >
        <Avatar
          marginStart="-1"
          name={memberInfo?.displayName}
          src={memberInfo?.picture}
          size="sm"
        />
      </Tooltip>
    );
  };

  return (
    <Box
      bg={panelColors.background}
      h="100%"
      p="2"
      pb="4"
      rounded="2xl"
      borderWidth="1px"
    >
      <Flex w="100%" justifyContent="end" mb="0">
        <IconButton
          size="sm"
          icon={<HiOutlineDotsHorizontal />}
          as={NavLink}
          to={TeamDetailNavLink(team?.organizationId, team?.id)}
          state={previousPage}
        />
      </Flex>

      <VStack w="100%">
        <HStack w="100%" alignItems="start">
          <Center ml="5">
            <Avatar
              name={team?.displayName}
              src={team?.picture}
              pointerEvents="none"
              size="lg"
            />
          </Center>
          <VStack spacing="0" alignItems="start">
            <Text fontSize="lg" fontWeight="semibold">
              {team?.displayName}
            </Text>
            <Text fontSize="sm" fontWeight="light" pr="3" noOfLines={4}>
              {team?.description}
            </Text>
          </VStack>
        </HStack>
        <Divider />
        <HStack w="100%" pl="6" alignItems="initial">
          <Box>
            <FormControl w="100%">
              <FormLabel fontSize="sm" fontWeight="light">
                {t("organizations.yourRole")}
              </FormLabel>
              <Text pl="1.5" fontSize="sm">
                {t(`roles.${getUserRole()}`)}
              </Text>
            </FormControl>
          </Box>
        </HStack>
        <HStack w="100%" pl="6" alignItems="initial">
          <Box w="50%">
            <FormControl w="100%">
              <FormLabel fontSize="sm" fontWeight="light">
                {t("organizations.name")}
              </FormLabel>
              <Text pl="1.5" fontSize="sm">
                {team?.organizationName ?? organizationName}
              </Text>
            </FormControl>
          </Box>
          <Box w="50%">
            <FormControl w="100%">
              <FormLabel fontSize="sm" fontWeight="light">
                {t("organizations.lastModified")}
              </FormLabel>
              <Text pl="1.5" fontSize="sm">
                {toDisplayDate(team?.lastModified)}
              </Text>
            </FormControl>
          </Box>
        </HStack>
        <Divider />
        <Box w="100%" pl="6">
          <FormControl>
            <FormLabel fontSize="sm" fontWeight="light">
              {t("organizations.members")}
            </FormLabel>
            <AvatarGroup
              max={4}
              pl="2.5"
              size="sm"
              fontSize="sm"
              spacing="-1.5"
            >
              {team?.memberships.map(displayMembers)}
            </AvatarGroup>
          </FormControl>
        </Box>
      </VStack>
    </Box>
  );
}
