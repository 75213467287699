import { ROLES } from "./memberRolesService";

export const canDeleteOrganization = (organizationUserRole) => {
  return isOwner(organizationUserRole);
};

export const canDeleteTeam = (organizationUserRole) => {
  return isOwnerOrAdministrator(organizationUserRole);
};

export const canDeleteMember = (
  organizationUserRole,
  memberRole,
  isTeam = false
) => {
  return isTeam
    ? canDeleteTeamMember(organizationUserRole)
    : canDeleteOrganizationMember(organizationUserRole, memberRole);
};

export const canUpdateOrganizationInformation = (organizationUserRole) => {
  return isOwnerOrAdministrator(organizationUserRole) ? true : false;
};

export const canUpdateTeamInformation = (
  organizationUserRole,
  teamUserRole
) => {
  if (isOwnerOrAdministrator(organizationUserRole)) {
    return true;
  }
  return isAdmin(teamUserRole) ? true : false;
};

export const canCreateTeam = (organizationUserRole) => {
  return isOwnerOrAdministrator(organizationUserRole);
};

export const canSeeInvitations = (organizationUserRole) => {
  return isOwnerOrAdministrator(organizationUserRole);
};

export const canUpdateOrganizationMembershipRole = (organizationUserRole) => {
  return isOwner(organizationUserRole);
};

export const canUpdateTeamMembershipRole = (organizationUserRole) => {
  return isOwnerOrAdministrator(organizationUserRole);
};

export const canAddMembersToTeam = (organizationUserRole, teamUserRole) => {
  if (isOwnerOrAdministrator(organizationUserRole)) {
    return true;
  }
  return isAdmin(teamUserRole) ? true : false;
};

const canDeleteTeamMember = (organizationUserRole) => {
  return isOwnerOrAdministrator(organizationUserRole);
};

const canDeleteOrganizationMember = (organizationUserRole, memberRole) => {
  if (isOwner(organizationUserRole)) {
    return memberRole !== ROLES.owner ? true : false;
  }

  if (isAdmin(organizationUserRole)) {
    return memberRole !== ROLES.owner && memberRole !== ROLES.admin
      ? true
      : false;
  }

  return false;
};

export const canUpdateTeamMembershipTitle = (
  userId,
  targetUserId,
  organizationUserRole,
  teamUserRole,
  memberRole
) => {
  if (isOwnerOrAdministrator(organizationUserRole)) {
    return true;
  }
  if (isAdmin(teamUserRole)) {
    if (userId === targetUserId) {
      return true;
    }
    if (memberRole !== ROLES.admin) {
      return true;
    }
  }
  return false;
};

export const canUpdateOrganizationMembershipTitle = (
  userId,
  targetUserId,
  organizationUserRole,
  memberRole
) => {
  if (isOwner(organizationUserRole)) {
    return true;
  }
  if (isAdmin(organizationUserRole)) {
    if (userId === targetUserId) {
      return true;
    }
    if (memberRole === ROLES.standard || memberRole === ROLES.guest) {
      return true;
    }
  }
  return false;
};

const isAdmin = (role) => {
  return role === ROLES.admin ? true : false;
};

const isOwner = (role) => {
  return role === ROLES.owner ? true : false;
};

const isOwnerOrAdministrator = (role) => {
  return role === ROLES.owner || role === ROLES.admin ? true : false;
};
