import { Box, SimpleGrid } from "@chakra-ui/react";
import { useTeams } from "../hooks/useTeams";
import { teamsTab } from "../services/PagesService";
import { useHubContext } from "../contexts/HubContext";
import TeamInformation from "./TeamInformation";
import LoadingPage from "./pages/LoadingPage";

export default function Teams({ teams, setTeams, t , user}) {
  const { connection } = useHubContext();
  const { isLoading } = useTeams(setTeams, connection, user);

  const displayTeams = (team) => {
    return (
      <TeamInformation
        key={team?.id}
        team={team}
        t={t}
        previousPage={teamsTab}
      />
    );
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Box w="100%">
      {teams?.length > 0 && (
        <SimpleGrid w="100%" spacing="5" columns={{ lg: 3, md: 2, sm: 1 }}>
          {teams?.map(displayTeams)}
        </SimpleGrid>
      )}
    </Box>
  );
}
