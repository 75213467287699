import { createContext, useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const IdentityContext = createContext();

const IdentityProvider = ({ children }) => {
  const {
    isLoading,
    user: currentUser,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [user, setUser] = useState(currentUser);

  useEffect(() => {
    setUser(currentUser);
  }, [currentUser]);

  const identity = {
    user,
    setUser,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  };

  return (
    <IdentityContext.Provider value={identity}>
      {children}
    </IdentityContext.Provider>
  );
};

const useIdentityContext = () => {
  return useContext(IdentityContext);
};

export { useIdentityContext, IdentityProvider };
