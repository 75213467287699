import { useState, useEffect } from "react";
import {
  OrganizationByIdUrl,
  OrganizationInvitationsUrl,
  TeamsByOrganizationIdUrl,
  TeamByIdUrl,
  ProfileByIdUrl,
} from "../services/urlService";
import { useAxios } from "./useAxios";
import { ROLES } from "../services/memberRolesService";
import {
  MERGEACTION,
  TYPES,
  addConflictToList,
} from "../services/mergeToolService";
import {
  ORGANIZATIONPROPERTIES,
  NOTIFICATIONTYPES,
  ENTITYTYPES,
  POINTERTYPES,
  MEMBERSACTIONS,
  NOTIFICATIONNAMES,
} from "../services/PagesService";
import { useHubContext } from "../contexts/HubContext";

export const useOrganizationDetailsPage = (
  organizationId,
  getAccessTokenSilently,
  user,
  setRequestIsLoading,
  blobUrl,
  setBlobUrl,
  uploadProcessFinished,
  setUploadProcessFinished,
  showError,
  t,
  onOpenMergeTool,
  file,
  notificationOrganizationUpdated,
  onOpenNotifyDeletion,
  goToOrganizations
) => {
  const { connection } = useHubContext();
  const { getRequest, putRequest } = useAxios();
  const [organization, setOrganization] = useState({});
  const [organizationName, setOrganizationName] = useState("");
  const [organizationPicture, setOrganizationPicture] = useState("");
  const [organizationWebsite, setOrganizationWebsite] = useState("");
  const [etag, setEtag] = useState("");
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [organizationUpdates, setOrganizationUpdates] = useState([]);
  const [invitations, setInvitations] = useState([]);
  const [teams, setTeams] = useState([]);
  const [organizationInServer, setOrganizationInServer] = useState();
  const [contentToMerge, setContentToMerge] = useState([]);
  const [mergedValues, setMergedValues] = useState([]);
  const [takeMergedValues, setTakeMergedValues] = useState(false);
  const [takeServer, setTakeServer] = useState(false);
  const [pendingUploadPicture, setPendingUploadPicture] = useState(false);
  const [notificationAction, setNotificationAction] = useState(null);
  const [countDown, setCountDown] = useState();
  const [deletionMessageModal, setDeletionMessageModal] = useState("");
  const [initializeProperties, setInitializeProperties] = useState(true);
  const [userRole, setUserRole] = useState("");
  const [organizationBdPicture, setOrganizationBdPicture] = useState("");

  useEffect(() => {
    const getConflicts = (serverOrganization) => {
      let conflicts = [];
      if (organizationPicture !== serverOrganization.picture) {
        addConflictToList(
          conflicts,
          ORGANIZATIONPROPERTIES.PICTURE,
          organizationPicture,
          serverOrganization.picture,
          setOrganizationPicture,
          TYPES.PICTURE,
          organization?.picture ?? null,
          setBlobUrl
        );
      }
      if (organizationName !== serverOrganization.displayName) {
        addConflictToList(
          conflicts,
          ORGANIZATIONPROPERTIES.DISPLAYNAME,
          organizationName,
          serverOrganization.displayName,
          setOrganizationName
        );
      }
      if (organizationWebsite !== serverOrganization.website) {
        addConflictToList(
          conflicts,
          ORGANIZATIONPROPERTIES.WEBSITE,
          organizationWebsite,
          serverOrganization.website,
          setOrganizationWebsite
        );
      }
      return conflicts;
    };
    const getOrganizationById = async () => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        showError(t("errors.serverError"));
        return;
      }
      setOrganizationInServer(response.data);
      if (notificationAction === MERGEACTION.TAKESERVER) {
        setTakeServer(true);
        setNotificationAction(null);
        return;
      }
      const conflicts = getConflicts(response.data);
      setContentToMerge(conflicts);
      if (conflicts.length === 0) {
        setOrganization(response.data);
        setOrganizationUpdates([]);
        setNotificationAction(null);
        return;
      }
      onOpenMergeTool();
      setNotificationAction(null);
    };

    if (!notificationAction) {
      return;
    }
    getOrganizationById();
  }, [
    notificationAction,
    organization,
    organizationName,
    organizationPicture,
    organizationWebsite,
    getAccessTokenSilently,
    organizationId,
    showError,
    t,
    setBlobUrl,
    onOpenMergeTool,
    setOrganizationInServer,
    getRequest,
  ]);

  useEffect(() => {
    if (takeMergedValues) {
      let newOrganizationUpdates = [];
      mergedValues.map((mergedValue) => {
        mergedValue.setData(mergedValue.value);
        const serverValue = organizationInServer[mergedValue.key];
        if (mergedValue.value !== serverValue) {
          newOrganizationUpdates.push(mergedValue.key);
        }
        return mergedValue;
      });
      setOrganizationUpdates(newOrganizationUpdates);
      setEtag(organizationInServer.eTag);
      setTakeMergedValues(false);
      setMergedValues([]);
    }
  }, [takeMergedValues, mergedValues, organizationInServer]);

  useEffect(() => {
    if (takeServer) {
      setOrganization(organizationInServer);
      setInitializeProperties(true);
      setBlobUrl(organizationInServer.picture);
      setOrganizationUpdates([]);
      setTakeServer(false);
    }
  }, [takeServer, organizationInServer, setBlobUrl]);

  useEffect(() => {
    const restRequests = async () => {
      try {
        await getOrganizationById();
        await getOrganizationTeams();
        setPageIsLoading(false);
      } catch {
        setPageIsLoading(false);
      }
    };

    const getOrganizationById = async () => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        setOrganization({});
        return;
      }
      setOrganization(response.data);
    };

    const getOrganizationTeams = async () => {
      const { response, isError } = await getRequest(
        TeamsByOrganizationIdUrl(organizationId)
      );
      if (isError) {
        setTeams([]);
        return;
      }
      setTeams(response.data);
    };

    restRequests();
  }, [getAccessTokenSilently, organizationId, getRequest]);

  useEffect(() => {
    const initProperties = () => {
      setOrganizationName(organization.displayName ?? "");
      setOrganizationPicture(organization.picture);
      setOrganizationWebsite(organization.website ?? "");
      setEtag(organization.eTag);
      setOrganizationBdPicture(organization.picture);
    };

    const getInvitations = async () => {
      const { response, isError } = await getRequest(
        OrganizationInvitationsUrl(organization.id)
      );
      if (isError) {
        setInvitations([]);
        return;
      }
      setInvitations(response.data);
    };

    if (!initializeProperties || Object.keys(organization).length === 0) {
      return;
    }

    initProperties();
    const userRole = organization?.memberships?.find(
      (m) => m.user.id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id
    )?.role;

    if (userRole === ROLES.owner || userRole === ROLES.admin) {
      getInvitations(organization);
    }
    setInitializeProperties(false);
  }, [
    organization,
    getAccessTokenSilently,
    user,
    initializeProperties,
    getRequest,
  ]);

  useEffect(() => {
    setUserRole(
      organization?.memberships?.find(
        (m) => m.user.id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id
      )?.role
    );
  }, [organization, user]);

  useEffect(() => {
    const getConflicts = (serverOrganization) => {
      let conflicts = [];
      if (organizationPicture !== serverOrganization.picture) {
        addConflictToList(
          conflicts,
          ORGANIZATIONPROPERTIES.PICTURE,
          organizationPicture,
          serverOrganization.picture,
          setOrganizationPicture,
          TYPES.PICTURE,
          organization?.picture ?? "",
          setBlobUrl
        );
      }
      if (organizationName !== serverOrganization.displayName) {
        addConflictToList(
          conflicts,
          ORGANIZATIONPROPERTIES.DISPLAYNAME,
          organizationName,
          serverOrganization.displayName,
          setOrganizationName
        );
      }
      if (organizationWebsite !== serverOrganization.website) {
        addConflictToList(
          conflicts,
          ORGANIZATIONPROPERTIES.WEBSITE,
          organizationWebsite,
          serverOrganization.website,
          setOrganizationWebsite
        );
      }
      return conflicts;
    };
    const updateOrganization = async () => {
      const getLatestVersionOrganization = async () => {
        const { response, isError } = await getRequest(
          OrganizationByIdUrl(organizationId)
        );
        return isError ? null : response.data;
      };
      const updateStates = (orga) => {
        setOrganization(orga);
        setEtag(orga.eTag);
        setOrganizationInServer(orga);
        setUploadProcessFinished(false);
        setOrganizationUpdates([]);
        setRequestIsLoading(false);
        setOrganizationBdPicture(orga.picture);
      };
      const displayError = () => {
        setUploadProcessFinished(false);
        setRequestIsLoading(false);
        showError(t("errors.updateOrganization"));
      };
      const pictureIsTheOnlyUpdate = () => {
        return organizationUpdates.length === 1 &&
          organizationUpdates.find((x) => x === ORGANIZATIONPROPERTIES.PICTURE)
          ? true
          : false;
      };

      if (pictureIsTheOnlyUpdate()) {
        if (organizationBdPicture === blobUrl) {
          setOrganizationUpdates([]);
          setRequestIsLoading(false);
          setUploadProcessFinished(false);
          return;
        }
      }
      const { response, isError } = await putRequest(
        OrganizationByIdUrl(organization.id),
        {
          displayName: organizationName,
          website: organizationWebsite,
          picture: blobUrl,
        },
        etag
      );
      if (!isError) {
        updateStates(response.data);
        return;
      }
      if (response.response?.status !== 409) {
        displayError();
        return;
      }
      const latestVersionOrganization = await getLatestVersionOrganization();
      if (!latestVersionOrganization) {
        displayError();
        return;
      }
      setOrganizationInServer(latestVersionOrganization);
      setUploadProcessFinished(false);
      // Get conflicts
      const conflicts = getConflicts(latestVersionOrganization);
      setContentToMerge(conflicts);
      if (conflicts.length === 0) {
        setOrganization(latestVersionOrganization);
        setOrganizationUpdates([]);
        setRequestIsLoading(false);
        return;
      }
      notificationOrganizationUpdated(
        t("mergeTool.organizationNotificationMessage")
      );
      setRequestIsLoading(false);
    };
    if (!uploadProcessFinished) {
      return;
    }
    updateOrganization();
  }, [
    blobUrl,
    getAccessTokenSilently,
    uploadProcessFinished,
    setUploadProcessFinished,
    organization,
    organizationName,
    organizationWebsite,
    organizationPicture,
    etag,
    setRequestIsLoading,
    showError,
    t,
    organizationId,
    setBlobUrl,
    getRequest,
    putRequest,
    notificationOrganizationUpdated,
    organizationUpdates,
    organizationBdPicture,
  ]);

  useEffect(() => {
    if (organizationPicture === organizationBdPicture) {
      setOrganizationUpdates((prevState) =>
        prevState.filter((st) => st !== ORGANIZATIONPROPERTIES.PICTURE)
      );
      return;
    }
    setOrganizationUpdates((prevState) => {
      if (prevState.indexOf(ORGANIZATIONPROPERTIES.PICTURE) === -1) {
        return [...prevState, ORGANIZATIONPROPERTIES.PICTURE];
      }
      return prevState;
    });
  }, [organizationBdPicture, organizationPicture]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCountDown((prevState) => prevState - 1);
    }, 1000);
    if (countDown === 0) {
      goToOrganizations();
    }
    return () => clearInterval(intervalId);
  }, [countDown, goToOrganizations]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    const teamUpdated = async (teamId) => {
      const { response, isError } = await getRequest(TeamByIdUrl(teamId));
      if (isError) {
        return;
      }
      if (response.data.organizationId !== organizationId) {
        return;
      }
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        const teamModifiedIndex = newState.findIndex((t) => t.id === teamId);
        if (teamModifiedIndex === -1) {
          newState.push(response.data);
        }
        newState[teamModifiedIndex] = response.data;
        return newState;
      });
    };
    const teamCreated = async (teamId) => {
      const { response, isError } = await getRequest(TeamByIdUrl(teamId));
      if (isError) {
        return;
      }
      if (response.data.organizationId !== organizationId) {
        return;
      }
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        const teamModifiedIndex = newState.findIndex((t) => t.id === teamId);
        if (teamModifiedIndex === -1) {
          newState.push(response.data);
        }
        return newState;
      });
    };
    const refreshTeams = async (organizationId) => {
      const { response, isError } = await getRequest(
        TeamsByOrganizationIdUrl(organizationId)
      );
      if (isError) {
        return;
      }
      setTeams(response.data);
    };
    const refreshInvitations = async (organizationId) => {
      const { response, isError } = await getRequest(
        OrganizationInvitationsUrl(organizationId)
      );
      if (isError) {
        return;
      }
      setInvitations(response.data);
    };
    const refreshOrganizationMembers = async (organizationId) => {
      let previousUserRole;
      let currentUserRole;
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        return;
      }
      setOrganization((prevState) => {
        let newState = Object.assign({}, prevState);
        previousUserRole = newState.memberships.find(
          (member) =>
            member.user.id ===
            user?.[`${process.env.REACT_APP_APP_METADATA}`].id
        )?.role;
        newState.memberships = response.data.memberships;
        return newState;
      });
      setEtag(response.data.eTag);
      currentUserRole = response.data.memberships.find(
        (member) =>
          member.user.id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id
      )?.role;
      if (previousUserRole === currentUserRole) {
        return;
      }
      if (
        currentUserRole !== ROLES.guest &&
        currentUserRole !== ROLES.standard
      ) {
        refreshInvitations(organizationId);
      }
      if (previousUserRole === ROLES.guest || currentUserRole === ROLES.guest) {
        refreshTeams(organizationId);
      }
    };
    const memberProfileUpdated = async (memberId) => {
      const { response, isError } = await getRequest(ProfileByIdUrl(memberId));
      if (isError) {
        return;
      }
      setOrganization((prevState) => {
        let newState = Object.assign({}, prevState);
        newState.memberships.map((member) => {
          if (member.user.id === memberId) {
            member.user = response.data;
          }
          return member;
        });
        return newState;
      });
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        newState.map((team) => {
          return team.memberships.map((member) => {
            if (member.user.id === memberId) {
              member.user = response.data;
            }
            return member;
          });
        });
        return newState;
      });
      setInvitations((prevState) => {
        let newState = Object.assign([], prevState);
        newState.map((invitation) => {
          if (invitation.invitedBy.id === memberId) {
            invitation.invitedBy = response.data;
          }
          return invitation;
        });
        return newState;
      });
    };
    const refreshUser = async () =>
      await getAccessTokenSilently({ cacheMode: "off" });
    const deleteTeam = (teamId) =>
      setTeams((prevState) => {
        return prevState.filter((t) => t.id !== teamId);
      });
    const canUpdateOrganizationInfo = () => {
      return userRole === ROLES.owner || userRole === ROLES.admin
        ? true
        : false;
    };
    const isGuestRole = () => {
      return userRole === ROLES.guest ? true : false;
    };
    const deleteMemberOfTeams = (userId) => {
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        newState.map((team) => {
          const newMembershipList = team.memberships.filter(
            (member) => member.user.id !== userId
          );
          team.memberships = newMembershipList;
          return team;
        });
        return newState;
      });
    };

    connection.on(NOTIFICATIONNAMES.RECEIVENOTIFICATION, (notification) => {
      const type = notification.entity.type;
      const id = notification.entity.id;
      const userId = user?.[`${process.env.REACT_APP_APP_METADATA}`].id;
      if (type === ENTITYTYPES.ORGANIZATION && id === organizationId) {
        if (
          notification.authorId === userId &&
          etag === notification.entity.eTag
        ) {
          if (
            notification.pointer !== POINTERTYPES.INVITATIONS &&
            notification.type !== NOTIFICATIONTYPES.DELETED &&
            notification.pointer !== POINTERTYPES.MEMBERS
          ) {
            return;
          }
        }
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            setDeletionMessageModal("mergeTool.organizationDeletedMessage");
            onOpenNotifyDeletion();
            return setCountDown(5);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.INVITATIONS) {
              return refreshInvitations(id);
            }
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                if (
                  memberAction.name !== MEMBERSACTIONS.REMOVED &&
                  notification.authorId === userId &&
                  etag === notification.entity.eTag
                ) {
                  return memberAction;
                }
                switch (memberAction.name) {
                  case MEMBERSACTIONS.REMOVED:
                    memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        setDeletionMessageModal(
                          "mergeTool.memberDeletedMessage"
                        );
                        onOpenNotifyDeletion();
                        return setCountDown(5);
                      }
                      refreshOrganizationMembers(id);
                      return deleteMemberOfTeams(memberId);
                    });
                    return memberAction;
                  default:
                    return refreshOrganizationMembers(id);
                }
              });
              return;
            }
            if (canUpdateOrganizationInfo()) {
              return notificationOrganizationUpdated(
                t("mergeTool.organizationNotificationMessage")
              );
            }
            return setNotificationAction(MERGEACTION.TAKESERVER);
          case NOTIFICATIONTYPES.ADDED:
            // Currently only invitations use the "added" tag
            if (notification.pointer !== POINTERTYPES.INVITATIONS) {
              return;
            }
            return refreshInvitations(id);
          case NOTIFICATIONTYPES.REMOVED:
            return refreshInvitations(id);
          case NOTIFICATIONTYPES.CREATED:
            return; //No action is required when an organization is created
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.TEAM) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            return deleteTeam(id);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                switch (memberAction.name) {
                  case MEMBERSACTIONS.MODIFIED:
                    return memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        return teamUpdated(id);
                      }
                      return memberId;
                    });
                  case MEMBERSACTIONS.REMOVED:
                    return memberAction.data.map((memberId) => {
                      if (memberId === userId && isGuestRole()) {
                        return deleteTeam(id);
                      }
                      return teamUpdated(id);
                    });
                  default:
                    return teamUpdated(id);
                }
              });
              return;
            }
            return teamUpdated(id);
          case NOTIFICATIONTYPES.CREATED:
            return teamCreated(id);
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.USER) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.UPDATED:
            if (id === userId) {
              refreshUser();
            }
            return memberProfileUpdated(id);
          default:
            return;
        }
      }
    });
    return () => {
      connection.off(NOTIFICATIONNAMES.RECEIVENOTIFICATION);
    };
  }, [
    connection,
    user,
    getAccessTokenSilently,
    notificationOrganizationUpdated,
    onOpenNotifyDeletion,
    t,
    etag,
    userRole,
    getRequest,
    organizationId,
  ]);

  return {
    organization,
    setOrganization,
    organizationName,
    setOrganizationName,
    organizationPicture,
    setOrganizationPicture,
    organizationWebsite,
    setOrganizationWebsite,
    organizationUpdates,
    setOrganizationUpdates,
    pageIsLoading,
    invitations,
    setInvitations,
    teams,
    setTeams,
    etag,
    setEtag,
    pendingUploadPicture,
    setPendingUploadPicture,
    contentToMerge,
    mergedValues,
    setMergedValues,
    setTakeMergedValues,
    setTakeServer,
    organizationInServer,
    setNotificationAction,
    countDown,
    deletionMessageModal,
    userRole,
  };
};
