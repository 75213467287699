import { useEffect, useState } from "react";
import {
  OrganizationsUrl,
  OrganizationByIdUrl,
  TeamByIdUrl,
  TeamsByOrganizationIdUrl,
  ProfileByIdUrl,
} from "../services/urlService";
import { useAuth0 } from "@auth0/auth0-react";
import { useAxios } from "./useAxios";
import {
  ENTITYTYPES,
  NOTIFICATIONTYPES,
  POINTERTYPES,
  MEMBERSACTIONS,
  NOTIFICATIONNAMES,
} from "../services/PagesService";
import { ROLES } from "../services/memberRolesService";

export const useOrganizations = (setOrganizations, connectionHub, user) => {
  const { getRequest } = useAxios();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [onlyOrganizations, setOnlyOrganizations] = useState([]);

  useEffect(() => {
    const getOrganizations = async () => {
      const { response, isError } = await getRequest(OrganizationsUrl);
      if (isError) {
        setOnlyOrganizations([]);
        setIsLoading(false);
        return;
      }
      setOnlyOrganizations(response.data);
      setIsLoading(false);
    };
    getOrganizations();
  }, [setOnlyOrganizations, getRequest]);

  useEffect(() => {
    if (onlyOrganizations.length === 0) {
      return;
    }
    setOrganizations(onlyOrganizations);
    onlyOrganizations.map(async (organization) => {
      const { response, isError } = await getRequest(
        TeamsByOrganizationIdUrl(organization.id)
      );
      const teams = isError ? [] : response.data;
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        let organizationModifiedIndex = newState.findIndex(
          (o) => o.id === organization.id
        );
        newState[organizationModifiedIndex].teams = teams;
        return newState;
      });
    });
  }, [onlyOrganizations, setOrganizations, getRequest]);

  // useEffect for SignalR
  useEffect(() => {
    if (!connectionHub) {
      return;
    }

    const organizationDeleted = (organizationId) => {
      setOrganizations((prevState) => {
        return prevState.filter((o) => o.id !== organizationId);
      });
    };
    const organizationMembershipDeleted = (organizationId, memberId) => {
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        let organizationModifiedIndex = newState.findIndex(
          (o) => o.id === organizationId
        );
        if (organizationModifiedIndex === -1) {
          return prevState;
        }
        const newMembershipsList = newState[
          organizationModifiedIndex
        ].memberships.filter((member) => member.user.id !== memberId);
        newState[organizationModifiedIndex].memberships = newMembershipsList;
        return newState;
      });
    };
    const organizationUpdated = async (organizationId) => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        return;
      }
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        let organizationModifiedIndex = newState.findIndex(
          (o) => o.id === organizationId
        );
        if (organizationModifiedIndex === -1) {
          // user with more of a tab open. user accept the invitation
          newState.push(response.data);
          refreshTeams(organizationId);
          return newState;
        }
        newState[organizationModifiedIndex] = {
          ...newState[organizationModifiedIndex],
          ...response.data,
        };
        return newState;
      });
    };
    const organizationCreated = async (organizationId) => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        return;
      }
      const newOrganization = response.data;
      newOrganization.teams = [];
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        if (!newState.find((o) => o.id === response.data.id)) {
          newState.push(newOrganization);
        }
        return newState;
      });
    };
    const teamDeleted = (teamId) => {
      setOrganizations((prevState) => {
        return prevState.map((organization) => {
          const teams = organization.teams.filter((t) => t.id !== teamId);
          organization.teams = teams;
          return organization;
        });
      });
    };
    const teamUpdated = async (teamId) => {
      const { response, isError } = await getRequest(TeamByIdUrl(teamId));
      if (isError) {
        return;
      }
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        let organizationModifiedIndex = newState.findIndex(
          (o) => o.id === response.data.organizationId
        );
        if (organizationModifiedIndex === -1) {
          return prevState;
        }
        const teamModifiedIndex = newState[
          organizationModifiedIndex
        ].teams.findIndex((t) => t.id === teamId);
        if (teamModifiedIndex === -1) {
          newState[organizationModifiedIndex].teams.push(response.data);
          return newState;
        }
        newState[organizationModifiedIndex].teams[teamModifiedIndex] =
          response.data;
        return newState;
      });
    };
    const teamCreated = async (teamId) => {
      const { response, isError } = await getRequest(TeamByIdUrl(teamId));
      if (isError) {
        return;
      }
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        let organizationModifiedIndex = newState.findIndex(
          (o) => o.id === response.data.organizationId
        );
        if (organizationModifiedIndex === -1) {
          return prevState;
        }
        if (
          !newState[organizationModifiedIndex].teams.find(
            (t) => t.id === teamId
          )
        ) {
          newState[organizationModifiedIndex].teams.push(response.data);
        }
        return newState;
      });
    };
    const refreshTeams = async (organizationId) => {
      const { response, isError } = await getRequest(
        TeamsByOrganizationIdUrl(organizationId)
      );
      if (isError) {
        return;
      }
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        let organizationModifiedIndex = newState.findIndex(
          (o) => o.id === organizationId
        );
        if (organizationModifiedIndex === -1) {
          return prevState;
        }
        newState[organizationModifiedIndex].teams = response.data;
        return newState;
      });
    };
    const memberUpdated = async (memberId) => {
      const { response, isError } = await getRequest(ProfileByIdUrl(memberId));
      if (isError) {
        return;
      }
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        newState.map((org) => {
          return org.memberships.map((member) => {
            if (member.user.id === memberId) {
              member.user = response.data;
            }
            return member;
          });
        });
        return newState;
      });
    };
    // team member deleted is the logged in user
    const memberTeamDeleted = async (teamId, userId) => {
      setOrganizations((prevState) => {
        let newState = Object.assign([], prevState);
        let organization;
        newState.map((orga) => {
          if (orga.teams.some((t) => t.id === teamId)) {
            organization = orga;
          }
          return orga;
        });
        if (!organization) {
          return prevState;
        }
        const userRole = organization?.memberships.find(
          (m) => m.user.id === userId
        )?.role;
        if (userRole !== ROLES.guest) {
          return prevState;
        }
        let organizationIndex = newState.findIndex(
          (o) => o.id === organization.id
        );
        const newTeamList = newState[organizationIndex].teams.filter(
          (t) => t.id !== teamId
        );
        newState[organizationIndex].teams = newTeamList;
        return newState;
      });
    };
    const refreshUser = async () =>
      await getAccessTokenSilently({ cacheMode: "off" });

    connectionHub.on(NOTIFICATIONNAMES.RECEIVENOTIFICATION, (notification) => {
      const type = notification.entity.type;
      const id = notification.entity.id;
      const userId = user?.[`${process.env.REACT_APP_APP_METADATA}`].id;

      if (type === ENTITYTYPES.ORGANIZATION) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            return organizationDeleted(id);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.INVITATIONS) {
              return;
            }
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                switch (memberAction.name) {
                  case MEMBERSACTIONS.ADDED:
                    return organizationUpdated(id);
                  case MEMBERSACTIONS.MODIFIED:
                    return memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        organizationUpdated(id);
                        return refreshTeams(id);
                      }
                      return memberId;
                    });
                  case MEMBERSACTIONS.REMOVED:
                    memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        return organizationDeleted(id);
                      }
                      return organizationMembershipDeleted(id, memberId); // remove membership;
                    });
                    return memberAction;
                  default:
                    return memberAction;
                }
              });
              return;
            }
            return organizationUpdated(id);
          case NOTIFICATIONTYPES.CREATED:
            return organizationCreated(id);
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.TEAM) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            return teamDeleted(id);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                switch (memberAction.name) {
                  case MEMBERSACTIONS.ADDED:
                    return teamUpdated(id);
                  case MEMBERSACTIONS.REMOVED:
                    memberAction.data.map((memberId) => {
                      if (userId === memberId) {
                        return memberTeamDeleted(id, memberId);
                      }
                      return memberId;
                    });
                    return memberAction;
                  default:
                    return memberAction;
                }
              });
            } else {
              return teamUpdated(id);
            }
            return;
          case NOTIFICATIONTYPES.CREATED:
            return teamCreated(id);
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.USER) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.UPDATED:
            if (id === userId) {
              refreshUser();
            }
            return memberUpdated(id);
          default:
            return;
        }
      }
    });
    return () => {
      connectionHub.off(NOTIFICATIONNAMES.RECEIVENOTIFICATION);
    };
  }, [
    connectionHub,
    setOrganizations,
    getAccessTokenSilently,
    user,
    getRequest,
  ]);

  return { isLoading };
};
