import { useState, useEffect } from "react";
import {
  TeamByOrganizationIdAndTeamIdUrl,
  OrganizationByIdUrl,
  TeamByIdUrl,
  ProfileByIdUrl,
} from "../services/urlService";
import {
  MERGEACTION,
  TYPES,
  addConflictToList,
} from "../services/mergeToolService";
import {
  TEAMPROPERTIES,
  NOTIFICATIONTYPES,
  ENTITYTYPES,
  POINTERTYPES,
  MEMBERSACTIONS,
  NOTIFICATIONNAMES,
} from "../services/PagesService";
import { ROLES } from "../services/memberRolesService";
import { useHubContext } from "../contexts/HubContext";
import { useAxios } from "./useAxios";
import { teamsTab } from "../services/PagesService";
import { useBreadcrumbsContext } from "../contexts/BreadcrumbsContext";
import { canUpdateTeamInformation } from "../services/rulesService";

export const useTeamDetailsPage = (
  teamId,
  organizationId,
  user,
  getAccessTokenSilently,
  setRequestIsLoading,
  blobUrl,
  setBlobUrl,
  uploadProcessFinished,
  setUploadProcessFinished,
  showError,
  t,
  onOpenMergeTool,
  file,
  onOpenNotifyDeletion,
  goToPreviousPage,
  navigate,
  notificationTeamUpdated
) => {
  const { connection } = useHubContext();
  const { getRequest, putRequest } = useAxios();
  const { setBreadcrumbs } = useBreadcrumbsContext();
  const [team, setTeam] = useState({});
  const [teamName, setTeamName] = useState("");
  const [teamPicture, setTeamPicture] = useState("");
  const [teamDescription, setTeamDescription] = useState("");
  const [etag, setEtag] = useState("");
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [teamUpdates, setTeamUpdates] = useState([]);
  const [organization, setOrganization] = useState({});
  const [organizationMembersOutOfTeam, setOrganizationMembersOutOfTeam] =
    useState([]);
  const [pendingUploadPicture, setPendingUploadPicture] = useState(false);
  const [teamInServer, setTeamInServer] = useState();
  const [contentToMerge, setContentToMerge] = useState([]);
  const [mergedValues, setMergedValues] = useState([]);
  const [takeMergedValues, setTakeMergedValues] = useState(false);
  const [takeServer, setTakeServer] = useState(false);
  const [countDown, setCountDown] = useState();
  const [entityDeleted, setEntityDeleted] = useState();
  const [notificationAction, setNotificationAction] = useState(null);
  const [initializeProperties, setInitializeProperties] = useState(true);
  const [deletionMessageModal, setDeletionMessageModal] = useState("");
  const [organizationUserRole, setOrganizationUserRole] = useState("");
  const [teamUserRole, setTeamUserRole] = useState("");
  const [teamBdPicture, setTeamBdPicture] = useState("");

  useEffect(() => {
    const getConflicts = (serverTeam) => {
      let conflicts = [];
      if (teamPicture !== serverTeam.picture) {
        addConflictToList(
          conflicts,
          TEAMPROPERTIES.PICTURE,
          teamPicture,
          serverTeam.picture,
          setTeamPicture,
          TYPES.PICTURE,
          team?.picture ?? null,
          setBlobUrl
        );
      }
      if (teamName !== serverTeam.displayName) {
        addConflictToList(
          conflicts,
          TEAMPROPERTIES.DISPLAYNAME,
          teamName,
          serverTeam.displayName,
          setTeamName
        );
      }
      if (teamDescription !== serverTeam.description) {
        addConflictToList(
          conflicts,
          TEAMPROPERTIES.DESCRIPTION,
          teamDescription,
          serverTeam.description,
          setTeamDescription
        );
      }
      return conflicts;
    };
    const getTeamById = async () => {
      const { response, isError } = await getRequest(
        TeamByOrganizationIdAndTeamIdUrl(organizationId, teamId)
      );
      if (isError) {
        showError(t("errors.updateOrganization"));
        return;
      }
      setTeamInServer(response.data);
      if (notificationAction === MERGEACTION.TAKESERVER) {
        setTakeServer(true);
        setNotificationAction(null);
        return;
      }
      const conflicts = getConflicts(response.data);
      setContentToMerge(conflicts);
      if (conflicts.length === 0) {
        setTeam(response.data);
        setTeamUpdates([]);
        setNotificationAction(null);
        return;
      }
      onOpenMergeTool();
      setNotificationAction(null);
    };
    if (!notificationAction) {
      return;
    }
    getTeamById();
  }, [
    notificationAction,
    getAccessTokenSilently,
    teamPicture,
    teamName,
    teamDescription,
    organizationId,
    setBlobUrl,
    showError,
    t,
    team?.picture,
    teamId,
    onOpenMergeTool,
    getRequest,
  ]);

  useEffect(() => {
    if (takeMergedValues) {
      let newTeamUpdates = [];
      mergedValues.map((mergedValue) => {
        mergedValue.setData(mergedValue.value);
        const serverValue = teamInServer[mergedValue.key];
        if (mergedValue.value !== serverValue) {
          newTeamUpdates.push(mergedValue.key);
        }
        return mergedValue;
      });
      setTeamUpdates(newTeamUpdates);
      setEtag(teamInServer.eTag);
      setTakeMergedValues(false);
      setMergedValues([]);
    }
  }, [takeMergedValues, mergedValues, teamInServer]);

  useEffect(() => {
    if (takeServer) {
      setTeam(teamInServer);
      setInitializeProperties(true);
      setBlobUrl(teamInServer.picture);
      setTeamUpdates([]);
      setTakeServer(false);
    }
  }, [takeServer, teamInServer, setBlobUrl]);

  useEffect(() => {
    const restRequests = async () => {
      try {
        await getTeamById();
        setPageIsLoading(false);
      } catch {
        setPageIsLoading(false);
      }
    };

    const getTeamById = async () => {
      const { response, isError } = await getRequest(
        TeamByOrganizationIdAndTeamIdUrl(organizationId, teamId)
      );
      if (isError) {
        setTeam({});
        return;
      }
      setTeam(response.data);
    };

    restRequests();
  }, [getAccessTokenSilently, teamId, organizationId, getRequest]);

  useEffect(() => {
    const initProperties = () => {
      setTeamName(team.displayName ?? "");
      setTeamPicture(team.picture);
      setTeamDescription(team.description ?? "");
      setEtag(team.eTag);
      setTeamBdPicture(team.picture);
    };
    const getOrganization = async () => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(team.organizationId)
      );
      if (isError) {
        setOrganization({});
        return;
      }
      setOrganization(response.data);
    };

    if (!initializeProperties || Object.keys(team).length === 0) {
      return;
    }
    initProperties();
    getOrganization();
    setInitializeProperties(false);
  }, [team, getAccessTokenSilently, initializeProperties, getRequest]);

  useEffect(() => {
    if (Object.keys(organization).length === 0) {
      return;
    }

    const possibleMemberListToAdd = [];
    organization.memberships.filter((organizationMember) => {
      const isInTeam = team.memberships.find(
        (f) => f.user.id === organizationMember.user.id
      );
      if (!isInTeam) {
        return possibleMemberListToAdd.push(organizationMember);
      }
      return possibleMemberListToAdd;
    });

    setOrganizationMembersOutOfTeam(possibleMemberListToAdd);
  }, [organization, team.memberships]);

  useEffect(() => {
    setOrganizationUserRole(
      organization?.memberships?.find(
        (m) => m.user.id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id
      )?.role
    );
  }, [organization, user]);

  useEffect(() => {
    setTeamUserRole(
      team?.memberships?.find(
        (m) => m.user.id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id
      )?.role
    );
  }, [team, user]);

  useEffect(() => {
    const getConflicts = (serverTeam) => {
      let conflicts = [];
      if (teamPicture !== serverTeam.picture) {
        addConflictToList(
          conflicts,
          TEAMPROPERTIES.PICTURE,
          teamPicture,
          serverTeam.picture,
          setTeamPicture,
          TYPES.PICTURE,
          team?.picture ?? "",
          setBlobUrl
        );
      }
      if (teamName !== serverTeam.displayName) {
        addConflictToList(
          conflicts,
          TEAMPROPERTIES.DISPLAYNAME,
          teamName,
          serverTeam.displayName,
          setTeamName
        );
      }
      if (teamDescription !== serverTeam.description) {
        addConflictToList(
          conflicts,
          TEAMPROPERTIES.DESCRIPTION,
          teamDescription,
          serverTeam.description,
          setTeamDescription
        );
      }
      return conflicts;
    };
    const updateTeam = async () => {
      const getLatestVersionTeam = async () => {
        const { response, isError } = await getRequest(
          TeamByOrganizationIdAndTeamIdUrl(organizationId, teamId)
        );
        return isError ? null : response.data;
      };
      const updateStates = (responseTeam) => {
        setTeam(responseTeam);
        setEtag(responseTeam.eTag);
        setTeamInServer(responseTeam);
        setUploadProcessFinished(false);
        setTeamUpdates([]);
        setRequestIsLoading(false);
        setTeamBdPicture(responseTeam.picture);
      };
      const displayError = () => {
        setUploadProcessFinished(false);
        setRequestIsLoading(false);
        showError(t("errors.updateTeam"));
      };
      const pictureIsTheOnlyUpdate = () => {
        return teamUpdates.length === 1 &&
          teamUpdates.find((x) => x === TEAMPROPERTIES.PICTURE)
          ? true
          : false;
      };

      if (pictureIsTheOnlyUpdate()) {
        if (teamBdPicture === blobUrl) {
          setTeamUpdates([]);
          setRequestIsLoading(false);
          setUploadProcessFinished(false);
          return;
        }
      }
      const { response, isError } = await putRequest(
        TeamByIdUrl(team.id),
        {
          displayName: teamName,
          description: teamDescription,
          picture: blobUrl,
        },
        etag
      );
      if (!isError) {
        updateStates(response.data);
        return;
      }
      if (response.response?.status !== 409) {
        displayError();
        return;
      }
      const latestVersionTeam = await getLatestVersionTeam();
      if (!latestVersionTeam) {
        displayError();
        return;
      }
      setTeamInServer(latestVersionTeam);
      setUploadProcessFinished(false);
      // Get conflicts
      const conflicts = getConflicts(latestVersionTeam);
      setContentToMerge(conflicts);
      if (conflicts.length === 0) {
        setTeam(latestVersionTeam);
        setTeamUpdates([]);
        setRequestIsLoading(false);
        return;
      }
      notificationTeamUpdated(t("mergeTool.teamNotificationMessage"));
      setRequestIsLoading(false);
    };
    if (!uploadProcessFinished) {
      return;
    }
    updateTeam();
  }, [
    blobUrl,
    getAccessTokenSilently,
    uploadProcessFinished,
    setUploadProcessFinished,
    team,
    teamName,
    teamPicture,
    teamDescription,
    etag,
    setRequestIsLoading,
    showError,
    t,
    organizationId,
    teamId,
    notificationTeamUpdated,
    setBlobUrl,
    getRequest,
    putRequest,
    teamBdPicture,
    teamUpdates,
  ]);

  useEffect(() => {
    if (teamPicture === teamBdPicture) {
      setTeamUpdates((prevState) =>
        prevState.filter((st) => st !== TEAMPROPERTIES.PICTURE)
      );
      return;
    }
    setTeamUpdates((prevState) => {
      if (prevState.indexOf(TEAMPROPERTIES.PICTURE) === -1) {
        return [...prevState, TEAMPROPERTIES.PICTURE];
      }
      return prevState;
    });
  }, [teamBdPicture, teamPicture]);

  useEffect(() => {
    const goToTeams = () =>
      navigate("/", {
        replace: true,
        state: { tab: teamsTab },
      });
    const intervalId = setInterval(() => {
      setCountDown((prevState) => prevState - 1);
    }, 1000);

    if (countDown === 0) {
      entityDeleted === ENTITYTYPES.TEAM ? goToPreviousPage() : goToTeams();
    }
    return () => clearInterval(intervalId);
  }, [countDown, entityDeleted, goToPreviousPage, navigate]);

  useEffect(() => {
    if (!connection) {
      return;
    }

    const refreshTeamMembers = async (teamId) => {
      const { response, isError } = await getRequest(TeamByIdUrl(teamId));
      if (isError) {
        return;
      }
      setTeam((prevState) => {
        let newState = Object.assign({}, prevState);
        newState.memberships = response.data.memberships;
        return newState;
      });
      setEtag(response.data.eTag);
    };
    const memberProfileUpdated = async (memberId) => {
      const { response, isError } = await getRequest(ProfileByIdUrl(memberId));
      if (isError) {
        return;
      }
      setTeam((prevState) => {
        let newState = Object.assign({}, prevState);
        newState.memberships.map((member) => {
          if (member.user.id === memberId) {
            member.user = response.data;
          }
          return member;
        });
        return newState;
      });
    };
    const refreshUser = async () =>
      await getAccessTokenSilently({ cacheMode: "off" });
    const refreshOrganization = async (organizationId) => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        return;
      }
      setOrganization(response.data);
    };
    const isGuestRole = () => {
      return organizationUserRole === ROLES.guest ? true : false;
    };

    connection.on(NOTIFICATIONNAMES.RECEIVENOTIFICATION, (notification) => {
      const type = notification.entity.type;
      const id = notification.entity.id;
      const userId = user?.[`${process.env.REACT_APP_APP_METADATA}`].id;
      if (type === ENTITYTYPES.ORGANIZATION && id === organizationId) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            setEntityDeleted(ENTITYTYPES.ORGANIZATION);
            setDeletionMessageModal("mergeTool.organizationDeletedMessage");
            onOpenNotifyDeletion();
            return setCountDown(5);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.INVITATIONS) {
              return;
            }
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                switch (memberAction.name) {
                  case MEMBERSACTIONS.REMOVED:
                    memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        setEntityDeleted(ENTITYTYPES.ORGANIZATION);
                        setDeletionMessageModal(
                          "mergeTool.memberDeletedMessage"
                        );
                        onOpenNotifyDeletion();
                        return setCountDown(5);
                      }
                      setTeam((prevState) => {
                        let newState = Object.assign({}, prevState);
                        let newMemberships = newState.memberships.filter(
                          (m) => m.user.id !== memberId
                        );
                        newState.memberships = newMemberships;
                        return newState;
                      });
                      return setOrganization((prevState) => {
                        let newState = Object.assign({}, prevState);
                        let newMemberships = newState.memberships.filter(
                          (member) => member.user.id !== memberId
                        );
                        newState.memberships = newMemberships;
                        return newState;
                      });
                    });
                    return memberAction;
                  case MEMBERSACTIONS.MODIFIED:
                    memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        return refreshOrganization(id);
                      }
                      return memberAction;
                    });
                    return memberAction;
                  case MEMBERSACTIONS.ADDED:
                    return refreshOrganization(id);
                  default:
                    return memberAction;
                }
              });
            }
            setOrganization((prevState) => {
              return { ...prevState, displayName: notification.entity.name };
            });
            setBreadcrumbs((prevState) => {
              let newState = Object.assign([], prevState);
              let organizationBreadcrumbIndex = newState.findIndex(
                (b) => b.key === "organization"
              );
              newState[organizationBreadcrumbIndex].name =
                notification.entity.name;
              return newState;
            });
            return;
          case NOTIFICATIONTYPES.CREATED:
            return; //No action required
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.TEAM && id === teamId) {
        if (
          notification.authorId === userId &&
          etag === notification.entity.eTag
        ) {
          if (notification.type !== NOTIFICATIONTYPES.DELETED) {
            return;
          }
        }
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            setEntityDeleted(ENTITYTYPES.TEAM);
            setDeletionMessageModal("mergeTool.teamDeletedMessage");
            onOpenNotifyDeletion();
            return setCountDown(5);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                if (memberAction.name === MEMBERSACTIONS.REMOVED) {
                  memberAction.data.map((memberId) => {
                    if (memberId === userId && isGuestRole()) {
                      setEntityDeleted(ENTITYTYPES.ORGANIZATION);
                      setDeletionMessageModal(
                        "mergeTool.organizationDeletedMessage"
                      );
                      onOpenNotifyDeletion();
                      return setCountDown(5);
                    }
                    return refreshTeamMembers(id);
                  });
                  return memberAction;
                }
                return refreshTeamMembers(id);
              });
              return;
            }
            if (canUpdateTeamInformation(organizationUserRole, teamUserRole)) {
              return notificationTeamUpdated(
                t("mergeTool.teamNotificationMessage")
              );
            }
            return setNotificationAction(MERGEACTION.TAKESERVER);
          case NOTIFICATIONTYPES.CREATED:
            // No action required
            return;
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.USER) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.UPDATED:
            if (id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id) {
              refreshUser();
            }
            return memberProfileUpdated(id);
          default:
            return;
        }
      }
    });
    return () => {
      connection.off(NOTIFICATIONNAMES.RECEIVENOTIFICATION);
    };
  }, [
    connection,
    setBreadcrumbs,
    etag,
    user,
    getAccessTokenSilently,
    onOpenNotifyDeletion,
    teamId,
    notificationTeamUpdated,
    t,
    organizationUserRole,
    teamUserRole,
    getRequest,
    organizationId,
  ]);

  return {
    team,
    setTeam,
    teamName,
    setTeamName,
    teamPicture,
    setTeamPicture,
    teamDescription,
    setTeamDescription,
    teamUpdates,
    setTeamUpdates,
    pageIsLoading,
    organization,
    organizationMembersOutOfTeam,
    etag,
    setEtag,
    pendingUploadPicture,
    setPendingUploadPicture,
    contentToMerge,
    mergedValues,
    setMergedValues,
    setTakeMergedValues,
    setTakeServer,
    teamInServer,
    countDown,
    entityDeleted,
    setNotificationAction,
    deletionMessageModal,
    organizationUserRole,
    teamUserRole,
  };
};
