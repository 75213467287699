import {
  Avatar,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";

export default function NotifyEntityDeletionModal({
  isOpen,
  onClose,
  message,
  picture = null,
  name = null,
}) {
  var { mode } = useLayoutMode();
  const modalSize = mode === LAYOUTMODES.MOBILE ? "md" : "2xl";

  return (
    <Modal
      size={modalSize}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay opacity="0.5" />
      <ModalContent borderRadius="md" borderTop="4px" borderColor="red.600">
        <ModalCloseButton />
        <ModalBody mb="0" pb="0" pl="12" pr="12">
          <VStack w="100%" alignItems="initial">
            <HStack w="100%" p="3" pr="5" justifyContent="center">
              <Avatar name={name} src={picture} size="md" mr="2" />
              <Text fontSize="lg" fontWeight="bold">
                {name}
              </Text>
            </HStack>
            <Text fontWeight="light" pl="12" pr="6" pb="8">
              {`${message}`}
            </Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
