import { useDisplayPreferencesContext } from "@buildsoft-eu/react";
import { LAYOUTMODES } from "../../hooks/useLayoutMode";
import { useTranslation } from "react-i18next";
import { useIdentityContext } from "../../contexts/IdentityContext";
import { useAuth0 } from "@auth0/auth0-react";
import { ProfileUrl } from "../../services/urlService";
import { useAxios } from "../../hooks/useAxios";
import VStackSwitcher from "./VStackSwitcher";
import DropDownSwitcher from "./DropDownSwitcher";
import MenuGroupSwitcher from "./MenuGroupSwitcher";

export default function ThemeSwitcher({ layoutMode = LAYOUTMODES.DESKTOP }) {
  const { isAuthenticated, user } = useIdentityContext();
  const { getRequest, putRequest } = useAxios();
  const { getAccessTokenSilently } = useAuth0();
  const {
    theme: { selected, all, update },
  } = useDisplayPreferencesContext();
  const { t } = useTranslation("translation");

  const items = all?.map((item) => {
    return {
      key: item.label,
      label: t(item.label),
      icon: item.icon,
      onClick: async () => {
        update(item.name);
        if (isAuthenticated) {
          const getNewEtag = async () => {
            const { response, isError } = await getRequest(ProfileUrl);
            return isError ? null : response.data?.eTag;
          };
          const { response, isError } = await putRequest(
            ProfileUrl,
            {
              theme: item.name,
            },
            user?.[`${process.env.REACT_APP_USER_METADATA}`].etag
          );
          if (!isError) {
            await getAccessTokenSilently({ cacheMode: "off" });
            return;
          }
          if (response.response?.status !== 409) {
            return;
          }
          const newEtag = await getNewEtag();
          if (!newEtag) {
            return;
          }
          const { isError: isErrorConflict } = await putRequest(
            ProfileUrl,
            {
              theme: item.name,
            },
            newEtag
          );
          if (!isErrorConflict) {
            await getAccessTokenSilently({ cacheMode: "off" });
          }
        }
      },
    };
  });

  const props = {
    icon: selected?.icon,
    title: t("themeSwitcher.theme"),
    items: items,
  };

  if (layoutMode === LAYOUTMODES.MOBILE) {
    return <VStackSwitcher {...props} />;
  }

  if (isAuthenticated) {
    return <MenuGroupSwitcher {...props} />;
  }

  return <DropDownSwitcher {...props} />;
}
