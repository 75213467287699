import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Text,
  Icon,
  IconButton,
  VStack,
  HStack,
  Spacer,
  Divider,
  useToast,
  CircularProgress,
  CircularProgressLabel,
  Center,
} from "@chakra-ui/react";
import { FaRegTrashAlt } from "react-icons/fa";
import { useColorsContext } from "@buildsoft-eu/react";
import { RiMailSendFill } from "react-icons/ri";
import { TbCalendarTime } from "react-icons/tb";
import { useRequestIsLoading } from "../hooks/useRequestIsLoading";
import { useAxios } from "../hooks/useAxios";
import ProfileUser from "./ProfileUser";

export default function InvitationCard({
  entityId,
  invitation,
  setInvitations,
  t,
  invitationByIdUrl,
  invitationRenewUrl,
  displayLocale,
  toDisplayDate,
}) {
  const { deleteRequest, putRequestWithParams } = useAxios();
  const toast = useToast();
  const {
    invitations: { card: cardColors },
  } = useColorsContext();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const {
    requestIsLoading: requestIsLoadingDelete,
    setRequestIsLoading: setRequestIsLoadingDelete,
  } = useRequestIsLoading();

  const isExpired = () => {
    if (new Date().getTime() > new Date(invitation.expires).getTime()) {
      return true;
    }
    return false;
  };

  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  const nExpirationDaysFunction = () => {
    const pendingDays = Math.ceil(
      (new Date(invitation.expires).getTime() - new Date().getTime()) /
        (3600000 * 24)
    );

    return pendingDays < 0 ? 0 : pendingDays;
  };

  const nExpirationDays = nExpirationDaysFunction();

  return (
    <VStack
      w="100%"
      pt="3"
      pl="3"
      pr="3"
      pb="1.5"
      rounded="xl"
      borderWidth="1px"
    >
      <Flex w="100%" justifyContent="start" mb="0" pt="2" pl="2" pb="1">
        <VStack w="100%">
          <FormControl w="100%" pb="1.5">
            <FormLabel fontSize="sm" fontWeight="light">
              {t("invitations.to")}
            </FormLabel>
            <HStack w="100%">
              <Avatar />
              <FormControl w="100%">
                <Text pl="1.5" fontSize="sm">
                  {invitation.email}
                </Text>
              </FormControl>
            </HStack>
          </FormControl>
          <FormControl w="100%" pb="1.5">
            <Text fontWeight="light" fontSize="sm">
              {`${t("common.as")} ${
                invitation.asGuest ? t("roles.guest") : t("roles.standard")
              }`}
            </Text>
          </FormControl>
        </VStack>
        <Spacer />
        <IconButton
          size="sm"
          icon={<FaRegTrashAlt />}
          isLoading={requestIsLoadingDelete}
          onClick={async () => {
            setRequestIsLoadingDelete(true);
            const { isError } = await deleteRequest(
              invitationByIdUrl(entityId, invitation.id)
            );
            if (isError) {
              showError(t("errors.deleteInvitation"));
              setRequestIsLoadingDelete(false);
              return;
            }
            setInvitations((prevState) => {
              return prevState.filter((i) => i.id !== invitation.id);
            });
            setRequestIsLoadingDelete(true);
          }}
        />
      </Flex>
      <Divider />
      <Box w="100%" pt="2" pl="2" pb="2">
        <FormControl w="100%" pb="4">
          <FormLabel fontSize="sm" fontWeight="light">
            {t("invitations.invitedBy")}
          </FormLabel>
          <ProfileUser user={invitation.invitedBy} smallSize={true} />
        </FormControl>
        <Flex w="100%" justifyContent="end" alignItems="center">
          <Icon
            as={RiMailSendFill}
            boxSize={5}
            color={cardColors.text.normal}
          />
          <Text pl="1.5" fontSize="sm">
            {toDisplayDate(invitation.invitationDate)}
          </Text>
          <Spacer />
          <Icon
            as={TbCalendarTime}
            boxSize={5}
            color={
              isExpired() ? cardColors.text.expires : cardColors.text.normal
            }
          />
          <Text
            pl="1.5"
            fontSize="sm"
            color={isExpired() && cardColors.text.expires}
          >
            {toDisplayDate(invitation.expires)}
          </Text>
        </Flex>
        <Center>
          <CircularProgress
            max="7"
            value={nExpirationDays}
            size="48px"
            thickness="4px"
            color={
              nExpirationDays < 4
                ? cardColors.progress.expires
                : cardColors.progress.normal
            }
          >
            <CircularProgressLabel
              fontSize="2xs"
              textColor={
                nExpirationDays === 0
                  ? cardColors.text.expires
                  : cardColors.text.normal
              }
            >{`${nExpirationDays} ${t("common.days")}`}</CircularProgressLabel>
          </CircularProgress>
        </Center>
        <Button
          mt="3"
          w="100%"
          size="xs"
          colorScheme={isExpired() ? "red" : "gray"}
          leftIcon={
            <RiMailSendFill
              color={
                isExpired() ? cardColors.text.expires : cardColors.text.normal
              }
            />
          }
          isLoading={requestIsLoading}
          variant="outline"
          onClick={async () => {
            setRequestIsLoading(true);
            const { response, isError } = await putRequestWithParams(
              invitationRenewUrl(entityId, invitation.id),
              null,
              { params: { locale: displayLocale } }
            );
            if (isError) {
              showError(t("errors.renewInvitation"));
              setRequestIsLoading(false);
            }
            setInvitations((prevState) => {
              const newState = prevState.filter((i) => i.id !== invitation.id);
              newState.push(response.data);
              return newState;
            });
            setRequestIsLoading(false);
          }}
        >
          {t("invitations.resendInvitation")}
        </Button>
      </Box>
    </VStack>
  );
}
