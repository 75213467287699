import { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { OrganizationInvitationAcceptUrl } from "../services/urlService";
import { useAxios } from "./useAxios";

export const useAcceptOrganizationInvitationPage = (
  organizationId,
  invitationId,
  code
) => {
  const { putRequest } = useAxios();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const renderAfterCalled = useRef(false);

  useEffect(() => {
    const acceptInvitation = async () => {
      const { response, isError } = await putRequest(
        OrganizationInvitationAcceptUrl(organizationId, invitationId),
        {
          code: code,
        }
      );
      if (isError) {
        setIsError(true);
        setApiResponse(response.response.data);
        setIsLoading(false);
      }
      setIsLoading(false);
    };
    if (!renderAfterCalled.current) {
      acceptInvitation();
    }
    renderAfterCalled.current = true;
  }, [organizationId, invitationId, code, getAccessTokenSilently, putRequest]);

  return {
    isLoading,
    apiResponse,
    isError,
  };
};
