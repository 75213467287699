import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OrganizationByIdUrl } from "../services/urlService";
import { useAuth0 } from "@auth0/auth0-react";
import { useAxios } from "./useAxios";
import { useToast } from "@chakra-ui/react";

export const useBreadcrumbs = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { getRequest } = useAxios();
  const toast = useToast();
  const location = useLocation();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const getOrganization = async (organizationId) => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        return null;
      }
      return response.data;
    };
    const createBreadcrumbsFromPath = async (path) => {
      let result = [];
      if (!path) {
        return result;
      }

      const elements = path.split("/");
      if (!elements || elements.length === 0) {
        return result;
      }

      if (elements.length === 4) {
        const organization = await getOrganization(elements[1]);
        if (organization) {
          result.push({
            key: "organization",
            name: organization.displayName,
            link: `${elements[0]}/${elements[1]}`,
          });
        }
      }

      return result;
    };

    const updateBreadcrumb = async () => {
      const newBreadcrumbs = await createBreadcrumbsFromPath(
        location.pathname.substring(1)
      );
      setBreadcrumbs(newBreadcrumbs);
    };
    toast.closeAll();
    updateBreadcrumb();
  }, [location, getAccessTokenSilently, toast, getRequest]);

  return { breadcrumbs, setBreadcrumbs };
};
