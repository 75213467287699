import {
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useIdentityContext } from "../../contexts/IdentityContext";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import ProfileUser from "../ProfileUser";
import LoadingPage from "../pages/LoadingPage";

export default function IdentityDisplayDesktop() {
  const { loginWithRedirect, isAuthenticated, logout, user, isLoading } =
    useIdentityContext();
  const { t, i18n } = useTranslation("translation");

  if (isLoading) {
    return <LoadingPage spinnerSize="lg" />;
  }

  if (isAuthenticated) {
    return (
      <Menu fontsize="md" size="md" color="current">
        <MenuButton as={Button} variant="unstyled">
          <Avatar size="sm" name={user?.name} src={user?.picture} m="1" />
        </MenuButton>
        <MenuList fontSize="16px" px="3">
          <ProfileUser user={user} />
          <MenuItem
            mt="3"
            icon={<FiLogOut />}
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            {t("identityDisplay.logout")}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  }

  return (
    <Button
      leftIcon={<FiLogIn />}
      onClick={() => loginWithRedirect({ ui_locales: i18n.language })}
    >
      {t("identityDisplay.login")}
    </Button>
  );
}
