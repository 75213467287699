import { useState, useEffect, useRef } from "react";
import { LinkUserAccountsUrl } from "../services/urlService";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

export const useCompleteLinkUserAccounts = (
  auth0Code,
  email,
  secondaryUserprovider,
  secondaryUserId
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [apiResponse, setApiResponse] = useState("");
  const renderAfterCalled = useRef(false);
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const getAccessToken = async () => {
      const data = {
        grant_type: "authorization_code",
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH0_CLIENT_SECRET,
        code: auth0Code,
        redirect_uri: `${window.location.origin}/completelinkaccounts`,
      };

      return await axios
        .post(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/oauth/token`, data)
        .then((response) => response.data.access_token)
        .catch((error) =>
          console.error(
            "Error exchanging authorization code for access token:",
            error.response.data
          )
        );
    };
    const linkAccounts = async () => {
      const accessToken = await getAccessToken();
      const payload = {
        email: email,
        auth0UserProvider: secondaryUserprovider,
        auth0UserId: `${secondaryUserId}`,
      };
      return await axios
        .put(LinkUserAccountsUrl, payload, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(async () => {
          await loginWithRedirect();
        })
        .catch((response) => {
          setApiResponse(response.response);
          setIsLoading(false);
          setIsError(true);
        });
    };
    if (!renderAfterCalled.current) {
      linkAccounts();
    }
    renderAfterCalled.current = true;
  }, [
    email,
    secondaryUserId,
    secondaryUserprovider,
    loginWithRedirect,
    auth0Code,
  ]);

  return {
    isLoading,
    apiResponse,
    isError,
  };
};
