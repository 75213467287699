import { useState, useEffect } from "react";
import { GeolocationFromIP } from "../services/urlService";
import axios from "axios";

export const useGeolocationFromIP = () => {
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    const getGeolocalization = async () => {
      await axios
        .get(GeolocationFromIP)
        .then((response) => {
          setCountryCode(response.data?.countryCode);
        })
        .catch(() => {
          setCountryCode("");
        });
    };

    getGeolocalization();
  }, []);

  return { countryCode };
};
