import {
  Button,
  Modal,
  ModalBody,
  ButtonGroup,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useColorsContext,
  Input,
  SelectOption,
  Select,
  Upload,
  UPLOAD_ERRORCODES,
} from "@buildsoft-eu/react";
import { getCountryCodes } from "../services/countryService";
import { useCreateOrganizationModal } from "../hooks/useCreateOrganizationModal";
import { BlobsImagesUrl } from "../services/urlService";
import { useGeolocationFromIP } from "../hooks/useGeolocationFromIP";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { useRequestIsLoading } from "../hooks/useRequestIsLoading";
import { useUploadPicture } from "../hooks/useUploadPicture";
import { fileName } from "../services/uploadImageService";
import { USERCLAIMS } from "../services/PagesService";

export default function CreateOrganizationModal({
  setOrganizations,
  isOpen,
  onClose,
  t,
}) {
  const { countryCode } = useGeolocationFromIP();
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const {
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    uploadPicture,
    file,
    setFile,
  } = useUploadPicture(getAccessTokenSilently, toast, setRequestIsLoading);
  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };
  const {
    organizationName,
    setOrganizationName,
    country,
    setCountry,
    picture,
    setPicture,
    website,
    setWebsite,
    cleanStates,
    pendingUploadPicture,
    setPendingUploadPicture,
  } = useCreateOrganizationModal(
    countryCode,
    setOrganizations,
    setRequestIsLoading,
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    onClose,
    showError,
    t
  );

  const { modal: modalColors } = useColorsContext();

  var { mode } = useLayoutMode();
  const modalSize = mode === LAYOUTMODES.MOBILE ? "md" : "2xl";

  const createCountriesList = () => {
    return getCountryCodes.map((code) => {
      return new SelectOption(code, code, t(`country.${code}`));
    });
  };

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay opacity="0.5" />
      <ModalContent
        pt="6"
        borderRadius="md"
        borderTop="4px"
        borderColor="teal.600"
      >
        <ModalBody>
          <VStack w="100%" spacing="1">
            <Input
              label={t("common.name")}
              isModal={true}
              isRequired
              value={organizationName}
              onChange={setOrganizationName}
              pb="5"
            />
            <Input
              label={t("organizations.website")}
              isModal={true}
              value={website}
              onChange={setWebsite}
              pb="5"
            />
            <Select
              label={t(`userClaims.${USERCLAIMS.COUNTRY}`)}
              isModal={true}
              pb="5"
              selected={country}
              items={createCountriesList()}
              onChange={setCountry}
            />
            <Upload
              label={t(`userClaims.${USERCLAIMS.PICTURE}`)}
              message={t("uploadFiles.mainMessage")}
              description={t("uploadFiles.restrictionsMessage")}
              isModal={true}
              fileUrl={picture}
              fileName={file?.name}
              setFile={(newFile) => {
                setFile(newFile);
                const url = URL.createObjectURL(newFile);
                setPicture(url);
                setPendingUploadPicture(true);
              }}
              clearFile={() => {
                setFile(undefined);
                setPicture(undefined);
                setPendingUploadPicture(false);
              }}
              showErrors={(errors) => {
                const error = errors[0];
                switch (error.code) {
                  case UPLOAD_ERRORCODES.INVALIDTYPE:
                    showError(t("errors.invalidType"));
                    break;
                  case UPLOAD_ERRORCODES.TOOMANY:
                    showError(t("errors.tooMany"));
                    break;
                  case UPLOAD_ERRORCODES.NOTSQUARE:
                    showError(t("errors.notSquare"));
                    break;
                  case UPLOAD_ERRORCODES.TOOLARGE:
                    showError(t("errors.pictureDimensions"));
                    break;
                  default:
                    showError(t("errors.pictureSize"));
                    break;
                }
              }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter bg={modalColors.footer.background} borderBottomRadius="md">
          <ButtonGroup spacing="8">
            <Button
              variant="unstyled"
              onClick={() => {
                onClose();
                setRequestIsLoading(false);
                cleanStates();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              isDisabled={organizationName.trim().length === 0 ? true : false}
              isLoading={requestIsLoading}
              loadingText={t("organizations.createOrganization")}
              spinnerPlacement="end"
              _hover={{ bgColor: "teal.600" }}
              bgColor="teal.600"
              textColor="teal.100"
              onClick={async () => {
                setRequestIsLoading(true);
                if (picture?.length === 0) {
                  setUploadProcessFinished(true);
                  return;
                }
                if (!pendingUploadPicture) {
                  setUploadProcessFinished(true);
                  return;
                }
                setPendingUploadPicture(false);
                let formData = new FormData();
                formData.append(fileName, file);
                await uploadPicture(
                  formData,
                  BlobsImagesUrl,
                  t("errors.createOrganization"),
                  setPicture
                );
              }}
            >
              {t("organizations.createOrganization")}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
