import { useEffect, useState, useCallback } from "react";
import { OrganizationsUrl } from "../services/urlService";
import { useAxios } from "./useAxios";

export const useCreateOrganizationModal = (
  countryCode,
  setOrganizations,
  setRequestIsLoading,
  blobUrl,
  setBlobUrl,
  uploadProcessFinished,
  setUploadProcessFinished,
  onClose,
  showError,
  t
) => {
  const { postRequest } = useAxios();
  const [organizationName, setOrganizationName] = useState("");
  const [picture, setPicture] = useState(undefined);
  const [country, setCountry] = useState("");
  const [website, setWebsite] = useState("");
  const [pendingUploadPicture, setPendingUploadPicture] = useState(false);

  const cleanStates = useCallback(() => {
    setOrganizationName("");
    setCountry(countryCode);
    setPicture("");
    setWebsite("");
  }, [countryCode]);

  useEffect(() => {
    setCountry(countryCode);
  }, [countryCode]);

  useEffect(() => {
    const createOrganization = async () => {
      const payload = {
        displayName: organizationName,
        picture: blobUrl,
        country: country.trim().length === 0 ? null : country,
        website: website.trim().length === 0 ? null : website,
      };
      const { response, isError } = await postRequest(
        OrganizationsUrl,
        payload
      );
      if (isError) {
        showError(t("errors.createOrganization"));
        setUploadProcessFinished(false);
        setRequestIsLoading(false);
        return;
      }
      setRequestIsLoading(false);
      setOrganizations((prevState) => {
        return [...prevState, { ...response.data, teams: [] }];
      });
      setUploadProcessFinished(false);
      setBlobUrl(null);
      cleanStates();
      onClose();
    };
    if (!uploadProcessFinished) {
      return;
    }
    createOrganization();
  }, [
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    setRequestIsLoading,
    onClose,
    setOrganizations,
    organizationName,
    country,
    website,
    showError,
    t,
    postRequest,
    cleanStates,
  ]);

  return {
    organizationName,
    setOrganizationName,
    picture,
    setPicture,
    country,
    setCountry,
    website,
    setWebsite,
    cleanStates,
    pendingUploadPicture,
    setPendingUploadPicture,
  };
};
