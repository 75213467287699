import { useEffect, useState } from "react";
import {
  TeamByIdUrl,
  TeamsUrl,
  OrganizationByIdUrl,
  ProfileByIdUrl,
} from "../services/urlService";
import { useAuth0 } from "@auth0/auth0-react";
import { useAxios } from "./useAxios";
import {
  ENTITYTYPES,
  NOTIFICATIONTYPES,
  POINTERTYPES,
  MEMBERSACTIONS,
  NOTIFICATIONNAMES,
} from "../services/PagesService";

export const useTeams = (setTeams, connectionHub, user) => {
  const { getRequest } = useAxios();
  const { getAccessTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [auxTeams, setAuxTeams] = useState([]);

  useEffect(() => {
    const getTeams = async () => {
      const { response, isError } = await getRequest(TeamsUrl);
      if (isError) {
        setAuxTeams([]);
        setIsLoading(false);
        return;
      }
      setAuxTeams(response.data);
      setIsLoading(false);
    };
    getTeams();
  }, [getAccessTokenSilently, setAuxTeams, getRequest]);

  useEffect(() => {
    if (auxTeams.length === 0) {
      return;
    }
    setTeams(auxTeams);
    auxTeams.map(async (team) => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(team.organizationId)
      );
      const organizationName = isError ? "" : response.data?.displayName;
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        let teamToModifyIndex = newState.findIndex((t) => t.id === team.id);
        newState[teamToModifyIndex].organizationName = organizationName;
        return newState;
      });
    });
  }, [auxTeams, getAccessTokenSilently, setTeams, getRequest]);

  useEffect(() => {
    if (!connectionHub) {
      return;
    }
    const teamDeleted = (teamId) => {
      setTeams((prevState) => {
        return prevState.filter((t) => t.id !== teamId);
      });
    };
    const teamUpdated = async (teamId, userId) => {
      const { response, isError } = await getRequest(TeamByIdUrl(teamId));
      if (isError) {
        return;
      }
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        const teamToModifyIndex = newState.findIndex((t) => t.id === teamId);
        if (teamToModifyIndex === -1) {
          const userIsMember = response.data.memberships.find(
            (m) => m.user.id === userId
          );
          if (!userIsMember) {
            return prevState;
          }
          newState.push(response.data);
        }
        newState[teamToModifyIndex] = {
          ...newState[teamToModifyIndex],
          ...response.data,
        };
        return newState;
      });
    };
    const organizationDeleted = (organizationId) => {
      setTeams((prevState) => {
        return prevState.filter((t) => t.organizationId !== organizationId);
      });
    };
    const organizationUpdated = async (organizationId) => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      if (isError) {
        return;
      }
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        newState.map((team) => {
          if (team.organizationId === organizationId) {
            team.organizationName = response.data?.displayName;
          }
          return team;
        });
        return newState;
      });
    };
    const getOrganizationName = async (organizationId) => {
      const { response, isError } = await getRequest(
        OrganizationByIdUrl(organizationId)
      );
      return isError ? "" : response.data?.displayName;
    };
    const memberAddedToTeam = async (teamId, userId) => {
      const { response, isError } = await getRequest(TeamByIdUrl(teamId));
      if (isError) {
        return;
      }
      const userIsMember = response.data.memberships.find(
        (m) => m.user.id === userId
      );
      if (!userIsMember) {
        return;
      }
      const organizationName = await getOrganizationName(
        response.data.organizationId
      );
      let newTeam = response.data;
      newTeam.organizationName = organizationName;
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        const teamToModifyIndex = newState.findIndex((t) => t.id === teamId);
        if (teamToModifyIndex === -1) {
          newState.push(newTeam);
        }
        return newState;
      });
    };
    const memberUpdated = async (memberId) => {
      const { response, isError } = await getRequest(ProfileByIdUrl(memberId));
      if (isError) {
        return;
      }
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        newState.map((team) => {
          return team.memberships.map((member) => {
            if (member.user.id === memberId) {
              member.user = response.data;
            }
            return member;
          });
        });
        return newState;
      });
    };
    const organizationMemberDeleted = (organizationId, memberIdDeleted) => {
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        newState.map((team) => {
          if (team.organizationId === organizationId) {
            const newMembers = team.memberships.filter(
              (member) => member.user.id !== memberIdDeleted
            );
            team.memberships = newMembers;
          }
          return team;
        });
        return newState;
      });
    };
    const teamMemberDeleted = (teamId, memberIdDeleted) => {
      setTeams((prevState) => {
        let newState = Object.assign([], prevState);
        let teamModifiedIndex = newState.findIndex((t) => t.id === teamId);
        if (teamModifiedIndex === -1) {
          return prevState;
        }
        const newMembershipsList = newState[
          teamModifiedIndex
        ].memberships.filter((member) => member.user.id !== memberIdDeleted);
        newState[teamModifiedIndex].memberships = newMembershipsList;
        return newState;
      });
    };
    const refreshUser = async () =>
      await getAccessTokenSilently({ cacheMode: "off" });

    connectionHub.on(NOTIFICATIONNAMES.RECEIVENOTIFICATION, (notification) => {
      const type = notification.entity.type;
      const id = notification.entity.id;
      const userId = user?.[`${process.env.REACT_APP_APP_METADATA}`].id;
      if (type === ENTITYTYPES.ORGANIZATION) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            return organizationDeleted(id);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.INVITATIONS) {
              return;
            }
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                switch (memberAction.name) {
                  case MEMBERSACTIONS.REMOVED:
                    return memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        return organizationDeleted(id);
                      }
                      return organizationMemberDeleted(id, memberId);
                    });
                  default:
                    return memberAction;
                }
              });
              return;
            }
            return organizationUpdated(id);
          case NOTIFICATIONTYPES.CREATED:
            // No action required
            return;
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.TEAM) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.DELETED:
            return teamDeleted(id);
          case NOTIFICATIONTYPES.UPDATED:
            if (notification.pointer === POINTERTYPES.MEMBERS) {
              notification.metadata.map((memberAction) => {
                switch (memberAction.name) {
                  case MEMBERSACTIONS.REMOVED:
                    return memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        return teamDeleted(id);
                      }
                      return teamMemberDeleted(id, memberId);
                    });
                  case MEMBERSACTIONS.MODIFIED:
                    return memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        return teamUpdated(id, userId);
                      }
                      return memberId;
                    });
                  case MEMBERSACTIONS.ADDED:
                    return memberAction.data.map((memberId) => {
                      if (memberId === userId) {
                        return memberAddedToTeam(id, memberId);
                      }
                      return teamUpdated(id, userId);
                    });
                  default:
                    return teamUpdated(id, userId);
                }
              });
              return;
            }
            return teamUpdated(id, userId);
          case NOTIFICATIONTYPES.CREATED:
            // No action required
            return;
          default:
            return;
        }
      }
      if (type === ENTITYTYPES.USER) {
        switch (notification.type) {
          case NOTIFICATIONTYPES.UPDATED:
            if (id === userId) {
              refreshUser();
            }
            return memberUpdated(id);
          default:
            return;
        }
      }
    });
    return () => {
      connectionHub.off(NOTIFICATIONNAMES.RECEIVENOTIFICATION);
    };
  }, [connectionHub, setTeams, getAccessTokenSilently, user, getRequest]);

  return { isLoading };
};
