import { useEffect, useState } from "react";

export const useProfilePage = (defaultSelectedTab) => {
  const [selectedTab, setSelectedTab] = useState(defaultSelectedTab);
  const [organizations, setOrganizations] = useState([]);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    window.history.replaceState({ usr: { tab: selectedTab } }, "");
  }, [selectedTab]);

  return {
    selectedTab,
    setSelectedTab,
    organizations,
    setOrganizations,
    teams,
    setTeams,
  };
};
