import { useIdentityContext } from "../contexts/IdentityContext";
import LoadingPage from "./pages/LoadingPage";
import NotAuthenticatedErrorPage from "./pages/NotAuthenticatedErrorPage";

export default function IfAuthenticated({ minH, page }) {
  const { isAuthenticated, isLoading } = useIdentityContext();

  if (isLoading) {
    return <LoadingPage minH={minH} />;
  }

  if (!isAuthenticated) {
    return <NotAuthenticatedErrorPage minH={minH} />;
  }

  const PageComponent = page;
  return <PageComponent minH={minH} />;
}
