import { Avatar, Text, HStack, VStack } from "@chakra-ui/react";

export default function ProfileUser({ user, smallSize = false }) {
  const userName = user?.name ?? user?.displayName;
  return (
    <HStack
      w="full"
      h="full"
      spacing={0}
      align="flex-start"
      alignItems="stretch"
    >
      <Avatar
        size={smallSize ? "sm" : "md"}
        ml="3"
        mr="3"
        name={userName}
        src={user?.picture}
      />
      <VStack w="full" align="left" spacing="-1">
        <Text fontSize={smallSize ? "sm" : "md"} fontWeight="medium">
          {userName}
        </Text>
        <Text fontSize={smallSize ? "xs" : "sm"} fontWeight="hairline">
          {user?.email}
        </Text>
      </VStack>
    </HStack>
  );
}
