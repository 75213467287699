import { useState } from "react";

export const useRequestIsLoading = () => {
  const [requestIsLoading, setRequestIsLoading] = useState(false);

  return {
    requestIsLoading,
    setRequestIsLoading,
  };
};
