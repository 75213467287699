import { ChakraProvider } from "@chakra-ui/react";
import {
  ColorsProvider,
  DisplayPreferencesProvider,
} from "@buildsoft-eu/react";
import { IdentityProvider } from "../contexts/IdentityContext";
import { HubProvider } from "../contexts/HubContext";
import { BreadcrumbsProvider } from "../contexts/BreadcrumbsContext";
import {
  colorOverrides,
  displayPreferencesOverrides,
} from "../buildsoft-eu-react";
import theme from "../theme";
import Header from "./header/Header";
import Content from "./Content";
import Footer from "./Footer";

export default function App() {
  return (
    <IdentityProvider>
      <HubProvider>
        <ChakraProvider theme={theme}>
          <DisplayPreferencesProvider config={displayPreferencesOverrides}>
            <ColorsProvider config={colorOverrides}>
              <BreadcrumbsProvider>
                <Header minH="7vh" />
                <Content minH={{ base: "78vh", md: "83vh" }} />
                <Footer minH={{ base: "15vh", md: "10vh" }} />
              </BreadcrumbsProvider>
            </ColorsProvider>
          </DisplayPreferencesProvider>
        </ChakraProvider>
      </HubProvider>
    </IdentityProvider>
  );
}
