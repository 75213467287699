import { useState, useEffect } from "react";

export const useMemberCard = (member) => {
  const [memberRole, setMemberRole] = useState(member?.role);
  const [title, setTitle] = useState(member?.title ?? "");
  const [isMemberUpdated, setIsMemberUpdated] = useState(false);

  useEffect(() => {
    setMemberRole(member.role);
    setTitle(member.title);
  }, [member]);

  useEffect(() => {
    if (memberRole !== member.role) {
      setIsMemberUpdated(true);
      return;
    }
    if (!member.title) {
      setIsMemberUpdated(title.trim().length === 0 ? false : true);
      return;
    }
    if (title !== member.title) {
      setIsMemberUpdated(true);
      return;
    }

    setIsMemberUpdated(false);
  }, [memberRole, title, member]);

  return {
    memberRole,
    setMemberRole,
    title,
    setTitle,
    isMemberUpdated,
    setIsMemberUpdated,
  };
};
