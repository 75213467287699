import { createContext, useContext } from "react";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";

const BreadcrumbsContext = createContext();

const BreadcrumbsProvider = ({ children }) => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <BreadcrumbsContext.Provider value={breadcrumbs}>
      {children}
    </BreadcrumbsContext.Provider>
  );
};

const useBreadcrumbsContext = () => {
  return useContext(BreadcrumbsContext);
};

export { useBreadcrumbsContext, BreadcrumbsProvider };
