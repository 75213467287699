import {
  Button,
  Flex,
  Center,
  Card,
  CardBody,
  CardFooter,
  Text,
  VStack,
  CardHeader,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { WebAuth } from "auth0-js";
import { useTranslation } from "react-i18next";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { createJwt } from "../services/jwtService";
import Logo from "./Logo";

export default function LinkAccounts({ tokenDecoded, tokenState, t }) {
  const { i18n } = useTranslation("translation");
  var { mode } = useLayoutMode();
  const toast = useToast();
  const cardWidth = mode === LAYOUTMODES.MOBILE ? "100%" : "400px";
  const cancelButtonColor = useColorModeValue("#635dff", "#9999a8");
  const usernamePasswordConnection = "Username-Password-Authentication";

  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  const displayError = (email) => {
    showError(`${t("errors.authorizeLinkingUserAccounts")} ${email}`);
  };

  const addJwt = async () => {
    const payload = { isRegistrationCompleted: false };
    return await createJwt(tokenDecoded, tokenState, payload);
  };

  const auth0 = new WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  });

  const getConnection = () => {
    try {
      const candidateIdentity = tokenDecoded?.candidate_identities[0];
      const identities = candidateIdentity?.identities;
      return identities[0]?.connection;
    } catch {
      return usernamePasswordConnection;
    }
  };

  const stateData = {
    id: tokenDecoded?.current_user?.user_id,
    email: tokenDecoded?.current_user?.email,
  };
  const stateParameter = encodeURIComponent(JSON.stringify(stateData));

  const authorizeOptions = {
    connection: getConnection(),
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri: `${window.location.origin}/completelinkaccounts`,
    origin: `${window.location.href}`,
    state: stateParameter,
    login_hint: tokenDecoded?.current_user?.email ?? "",
    responseType: "code",
    language: i18n.language,
  };

  const authenticatePrimaryUser = () => {
    auth0.authorize(authorizeOptions, (err, authResult) => {
      if (err) {
        displayError(tokenDecoded?.current_user?.email);
      }
    });
  };

  return (
    <Center>
      <Card w={cardWidth} justifyContent="center">
        <CardHeader justifyContent="center">
          <Center>
            <Logo h="20vmin" pointerEvents="none" />
          </Center>
        </CardHeader>
        <CardBody mt="5">
          <VStack spacing="5">
            <Flex>
              <Text w="100%" fontSize="xl">
                {t("linkUserAccounts.title")}
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="sm" w="100%">
                {t("linkUserAccounts.message1")}
              </Text>
            </Flex>
            <Flex>
              <Text fontSize="sm" w="100%">
                {t("linkUserAccounts.message2")}
              </Text>
            </Flex>
          </VStack>
        </CardBody>
        <CardFooter mt="5">
          <VStack w="100%">
            <Button
              w="100%"
              variant="ghost"
              _hover={{ bgColor: "none" }}
              fontSize="sm"
              color={cancelButtonColor}
              onClick={async () => {
                const token = await addJwt();
                window.location.replace(
                  `${tokenDecoded?.continueUri}?state=${tokenState}&session_token=${token}`
                );
              }}
            >
              {t("linkUserAccounts.cancelRegistration")}
            </Button>
            <Button
              w="100%"
              fontSize="sm"
              variant="solid"
              borderRadius="3px"
              color="white"
              background="#e74c3c"
              _hover={{ bgColor: "#cf4436" }}
              onClick={() => authenticatePrimaryUser()}
            >
              {t("linkUserAccounts.continue")}
            </Button>
          </VStack>
        </CardFooter>
      </Card>
    </Center>
  );
}
