import { Stack, Spacer, Button, Select, useToast } from "@chakra-ui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { useAddMembersToTeams } from "../hooks/useAddMembersToTeam";
import { useRequestIsLoading } from "../hooks/useRequestIsLoading";
import { TeamAddMemberUrl, TeamByIdUrl } from "../services/urlService";
import { useAxios } from "../hooks/useAxios";

export default function AddMembersToTeam({
  organizationId,
  teamId,
  setTeam,
  organizationMembersOutOfTeam,
  t,
  etag,
  setEtag,
}) {
  const { getRequest, postRequest } = useAxios();
  const { memberToAdd, setMemberToAdd } = useAddMembersToTeams();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const toast = useToast();

  const displayNewMembersList = (member) => {
    return (
      <option key={member.user.id} value={member.user.id}>
        {`${member.user.displayName} (${t(`roles.${member.role}`)})`}
        {}
      </option>
    );
  };

  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      w="100%"
      alignItems="center"
      spacing="3"
      pt="2"
      pb="5"
    >
      <Select
        value={memberToAdd}
        onChange={(event) => {
          setMemberToAdd(event.target.value);
        }}
      >
        <option value="" disabled></option>
        {organizationMembersOutOfTeam?.map(displayNewMembersList)}
      </Select>
      <Spacer />
      <Button
        isDisabled={memberToAdd.trim().length === 0 ? true : false}
        w={{ base: "100%", md: "20%" }}
        leftIcon={<HiOutlinePlus />}
        colorScheme="red"
        isLoading={requestIsLoading}
        variant="outline"
        onClick={async () => {
          const getLatestVersionTeam = async () => {
            const { response, isError } = await getRequest(TeamByIdUrl(teamId));
            return isError ? null : response.data;
          };
          const updateStates = (data) => {
            setTeam(data);
            setEtag(data.eTag);
            setRequestIsLoading(false);
            setMemberToAdd("");
          };
          const displayError = () => {
            showError(t("errors.addTeamMember"));
            setRequestIsLoading(false);
          };
          setRequestIsLoading(true);

          const { response, isError } = await postRequest(
            TeamAddMemberUrl(organizationId, teamId, memberToAdd),
            {},
            etag
          );
          if (!isError) {
            updateStates(response.data);
            return;
          }
          if (response.response?.status !== 409) {
            displayError();
            return;
          }
          const latestVersionTeam = await getLatestVersionTeam();
          if (!latestVersionTeam) {
            displayError();
            return;
          }
          if (
            latestVersionTeam?.memberships.find(
              (m) => m.user.id === memberToAdd
            )
          ) {
            updateStates(latestVersionTeam);
            return;
          }
          const { response: responseConflict, isError: isErrorConflict } =
            await postRequest(
              TeamAddMemberUrl(organizationId, teamId, memberToAdd),
              {},
              latestVersionTeam?.eTag
            );
          if (isErrorConflict) {
            displayError();
            return;
          }
          updateStates(responseConflict.data);
        }}
      >
        {t("teams.addMember")}
      </Button>
    </Stack>
  );
}
