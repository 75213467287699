import { Button, ButtonGroup, Box, VStack } from "@chakra-ui/react";
import { useCompleteRegistration } from "../hooks/useCompleteRegistration";
import {
  useColorsContext,
  Input,
  Select,
  SelectOption,
  Checkbox,
} from "@buildsoft-eu/react";
import { getCountryCodes } from "../services/countryService";
import { USERCLAIMS } from "../services/PagesService";

export default function CompleteRegistration({
  tokenDecoded,
  tokenState,
  countryCode,
  t,
}) {
  const {
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    displayName,
    setDisplayName,
    country,
    setCountry,
    trainingUpdates,
    setTrainingUpdates,
    distributorUpdates,
    setDistributorUpdates,
    isLoading,
    setIsLoading,
    isValid,
    addJwt,
  } = useCompleteRegistration(tokenDecoded, tokenState, countryCode);

  const createCountriesList = () => {
    return getCountryCodes.map((code) => {
      return new SelectOption(code, code, t(`country.${code}`));
    });
  };

  const {
    grid: gridColors,
    modal: { footer: footerColors },
  } = useColorsContext();
  const gridStyleProps = {
    bg: gridColors.background,
    w: "100%",
    boxShadow: "lg",
    display: "flex",
    borderRadius: "md",
    spacing: "3",
    direction: {
      base: "column",
      md: "row",
    },
    borderTop: "4px",
    borderColor: "teal.600",
    justify: { base: "center" },
    align: { base: "center", md: "center" },
  };

  const modalFooterBgColor = footerColors.background;

  return (
    <VStack
      maxW={{ base: "92vw", lg: "90vw", xl: "80vw", "2xl": "75vw" }}
      {...gridStyleProps}
    >
      <VStack w="100%" p="4" spacing="5">
        <Input
          label={t(`userClaims.${USERCLAIMS.GIVENNAME}`)}
          isRequired
          value={givenName ?? ""}
          onChange={setGivenName}
          isModal
        />
        <Input
          label={t(`userClaims.${USERCLAIMS.FAMILYNAME}`)}
          isRequired
          value={familyName ?? ""}
          onChange={setFamilyName}
          isModal
        />
        <Input
          label={t(`userClaims.${USERCLAIMS.DISPLAYNAME}`)}
          isRequired
          value={displayName ?? ""}
          onChange={setDisplayName}
          isModal
        />
        <Select
          label={t(`userClaims.${USERCLAIMS.COUNTRY}`)}
          selected={country}
          items={createCountriesList()}
          onChange={setCountry}
          isModal
        />
        <Checkbox
          label={t(`userClaims.${USERCLAIMS.TRAININGUPDATES}`)}
          value={trainingUpdates}
          onChange={setTrainingUpdates}
          isModal
        />
        <Checkbox
          label={t(`userClaims.${USERCLAIMS.DISTRIBUTORUPDATES}`)}
          value={distributorUpdates}
          onChange={setDistributorUpdates}
          isModal
        />
      </VStack>
      <Box w="100%" bg={modalFooterBgColor} borderBottomRadius="md">
        <ButtonGroup spacing="8" w="100%" justifyContent="end" p="4" pr="5">
          <Button
            variant="unstyled"
            onClick={async () => {
              const token = await addJwt(false);
              window.location.replace(
                `${tokenDecoded?.continueUri}?state=${tokenState}&session_token=${token}`
              );
            }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            loadingText={t("common.save")}
            spinnerPlacement="end"
            isDisabled={!isValid}
            isLoading={isLoading}
            _hover={{ bgColor: "teal.600" }}
            bgColor="teal.600"
            textColor="teal.100"
            onClick={async () => {
              setIsLoading(true);
              const token = await addJwt();
              window.location.replace(
                `${tokenDecoded?.continueUri}?state=${tokenState}&session_token=${token}`
              );
            }}
          >
            {t("registration.registerUser")}
          </Button>
        </ButtonGroup>
      </Box>
    </VStack>
  );
}
