import { useEffect, useState, useRef } from "react";
import { ProfileUrl } from "../services/urlService";
import {
  USERCLAIMS,
  NOTIFICATIONNAMES,
  ENTITYTYPES,
} from "../services/PagesService";
import { useAxios } from "./useAxios";

export const useProfileInformation = (
  user,
  getAccessTokenSilently,
  setRequestIsLoading,
  blobUrl,
  uploadProcessFinished,
  setUploadProcessFinished,
  showError,
  t,
  connectionHub,
  isOpen
) => {
  const deleteButtonRef = useRef();
  const { getRequest, putRequest } = useAxios();
  const userMetadata = user
    ? user[`${process.env.REACT_APP_USER_METADATA}`]
    : undefined;
  const [picture, setPicture] = useState("");
  const [givenName, setGivenName] = useState("");
  const [familyName, setFamilyName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [country, setCountry] = useState();
  const [preferredLocale, setPreferredLocale] = useState();
  const [preferredTheme, setPreferredTheme] = useState();
  const [trainingUpdates, setTrainingUpdates] = useState();
  const [distributorUpdates, setDistributorUpdates] = useState();
  const [userUpdates, setUserUpdates] = useState([]);
  const [etag, setEtag] = useState("");
  const [pendingUploadPicture, setPendingUploadPicture] = useState(false);
  const [userDatabasePicture, setUserDatabasePicture] = useState("");
  const [isDeleteRequested, setIsDeleteRequested] = useState(false);
  const [refreshIsDeleteRequested, setRefreshIsDeleteRequested] =
    useState(true);

  useEffect(() => {
    setPicture(user?.picture ?? "");
    setGivenName(user?.given_name ?? "");
    setFamilyName(user?.family_name ?? "");
    setDisplayName(user?.name ?? "");
    setCountry(userMetadata?.country ?? "");
    setPreferredLocale(userMetadata?.locale ?? "");
    setPreferredTheme(userMetadata?.preferred_theme ?? "");
    setTrainingUpdates(userMetadata?.training_updates ?? false);
    setDistributorUpdates(userMetadata?.distributor_updates ?? false);
    setEtag(userMetadata?.etag ?? null);
    setUserDatabasePicture(user?.picture);
  }, [user, userMetadata]);

  //Just when user is registered, the access token is created before to add the eTag. I have to get the access token again
  useEffect(() => {
    const getAccessToken = async () => {
      await getAccessTokenSilently({ cacheMode: "off" });
    };
    if (!etag) {
      getAccessToken();
    }
  }, [etag, getAccessTokenSilently]);

  useEffect(() => {
    const pictureIsTheOnlyUpdate = () => {
      return userUpdates.length === 1 &&
        userUpdates.find((x) => x === USERCLAIMS.PICTURE)
        ? true
        : false;
    };

    const updateProfile = async () => {
      if (pictureIsTheOnlyUpdate()) {
        if (userDatabasePicture === blobUrl) {
          setRequestIsLoading(false);
          setUserUpdates([]);
          return;
        }
      }
      const { isError } = await putRequest(
        ProfileUrl,
        {
          givenName: givenName,
          familyName: familyName,
          displayName: displayName,
          theme: preferredTheme,
          locale: preferredLocale,
          picture: blobUrl,
          hasTrainingUpdates: trainingUpdates,
          hasDistributorUpdates: distributorUpdates,
        },
        etag
      );
      if (isError) {
        setRequestIsLoading(false);
        showError(t("errors.updateUserProfile"));
        setUserUpdates([]);
        return;
      }
      await getAccessTokenSilently({ cacheMode: "off" });
      setUserUpdates([]);
      setRequestIsLoading(false);
    };

    if (!uploadProcessFinished) {
      return;
    }
    setUploadProcessFinished(false);
    updateProfile();
  }, [
    givenName,
    familyName,
    displayName,
    preferredLocale,
    preferredTheme,
    trainingUpdates,
    distributorUpdates,
    etag,
    getAccessTokenSilently,
    uploadProcessFinished,
    setRequestIsLoading,
    setUploadProcessFinished,
    blobUrl,
    showError,
    t,
    putRequest,
    userDatabasePicture,
    userUpdates,
  ]);

  useEffect(() => {
    if (picture === userDatabasePicture) {
      setUserUpdates((prevState) =>
        prevState.filter((st) => st !== USERCLAIMS.PICTURE)
      );
      return;
    }

    setUserUpdates((prevState) => {
      if (prevState.indexOf(USERCLAIMS.PICTURE) === -1) {
        return [...prevState, USERCLAIMS.PICTURE];
      }
      return prevState;
    });
  }, [picture, userDatabasePicture, blobUrl]);

  useEffect(() => {
    const isRequestedTodelete = async () => {
      setRefreshIsDeleteRequested(false);
      const { response, isError } = await getRequest(ProfileUrl);
      if (isError) {
        return;
      }
      response.data?.isDeleteRequested
        ? setIsDeleteRequested(true)
        : setIsDeleteRequested(false);
    };
    if (!refreshIsDeleteRequested) {
      return;
    }
    isRequestedTodelete();
  }, [getRequest, refreshIsDeleteRequested]);

  useEffect(() => {
    if (isOpen) {
      deleteButtonRef.current?.blur();
    }
  }, [isOpen]);

  useEffect(() => {
    const refreshUser = async () => {
      await getAccessTokenSilently({ cacheMode: "off" });
      setUserUpdates([]);
    };
    if (!connectionHub) {
      return;
    }
    connectionHub.on(NOTIFICATIONNAMES.RECEIVENOTIFICATION, (notification) => {
      if (notification.entity.type !== ENTITYTYPES.USER) {
        return;
      }
      setRefreshIsDeleteRequested(true);
      refreshUser();
    });
    return () => {
      connectionHub.off(NOTIFICATIONNAMES.RECEIVENOTIFICATION);
    };
  }, [connectionHub, getAccessTokenSilently]);

  return {
    picture,
    setPicture,
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    displayName,
    setDisplayName,
    country,
    setCountry,
    preferredLocale,
    setPreferredLocale,
    preferredTheme,
    setPreferredTheme,
    trainingUpdates,
    setTrainingUpdates,
    distributorUpdates,
    setDistributorUpdates,
    userMetadata,
    userUpdates,
    setUserUpdates,
    pendingUploadPicture,
    setPendingUploadPicture,
    setEtag,
    etag,
    isDeleteRequested,
    setIsDeleteRequested,
    deleteButtonRef,
  };
};
