import { useEffect, useState } from "react";

export const LAYOUTMODES = {
  MOBILE: "mobile",
  DESKTOP: "desktop",
};

const determineMode = (width) => {
  return width < 768 ? LAYOUTMODES.MOBILE : LAYOUTMODES.DESKTOP;
};

export const useLayoutMode = () => {
  const [mode, setMode] = useState(undefined);

  useEffect(() => {
    const handleResize = () => {
      var newMode = determineMode(window.innerWidth);
      if (mode !== newMode) {
        setMode(newMode);
      }
    };
    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [mode]);

  return { mode };
};
