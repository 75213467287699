import { Box, Button, Flex, SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { HiOutlinePlus } from "react-icons/hi";
import { useOrganizations } from "../hooks/useOrganizations";
import { useHubContext } from "../contexts/HubContext";
import OrganizationInformation from "./OrganizationInformation";
import CreateOrganizationModal from "./CreateOrganizationModal";
import LoadingPage from "./pages/LoadingPage";

export default function Organizations({
  organizations,
  setOrganizations,
  t,
  user,
}) {
  const { connection } = useHubContext();
  const { isLoading } = useOrganizations(setOrganizations, connection, user);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const displayOrganizations = (organization) => {
    return (
      <OrganizationInformation
        key={organization?.id}
        organization={organization}
        t={t}
      />
    );
  };

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <Box w="100%">
      <Flex w="100%" justifyContent="end">
        <Button
          leftIcon={<HiOutlinePlus />}
          mb="2"
          onClick={onOpen}
          fontSize="md"
          colorScheme="red"
          variant="outline"
        >
          {t("organizations.newOrganization")}
        </Button>
        <CreateOrganizationModal
          isOpen={isOpen}
          onClose={onClose}
          setOrganizations={setOrganizations}
          t={t}
        />
      </Flex>
      <SimpleGrid w="100%" spacing="5" columns={{ lg: 3, md: 2, sm: 1 }}>
        {organizations?.map(displayOrganizations)}
      </SimpleGrid>
    </Box>
  );
}
