import { Routes, Route } from "react-router-dom";
import CompleteRegistrationPage from "./pages/CompleteRegistrationPage";
import LandingPage from "./pages/LandingPage";
import IfAuthenticated from "./IfAuthenticated";
import OrganizationDetailsPage from "./pages/OrganizationDetailsPage";
import TeamDetailsPage from "./pages/TeamDetailsPage";
import AcceptOrganizationInvitationPage from "./pages/AcceptOrganizationInvitationPage";
import ConfirmProfileDeletionPage from "./pages/ConfirmProfileDeletionPage";
import NotFoundPage from "./pages/NotFoundPage";
import ResendProfileDeletinoCodePage from "./pages/ResendProfileDeletionCodePage";
import CompleteLinkUserAccounts from "./CompleteLinkUserAccounts";

export default function Content({ minH }) {
  return (
    <Routes>
      <Route path="/" element={<LandingPage minH={minH} />} />
      <Route
        path="/completeRegistration"
        element={<CompleteRegistrationPage minH={minH} />}
      />
      <Route
        path="/completelinkaccounts"
        element={<CompleteLinkUserAccounts minH={minH} />}
      />
      <Route
        path="/organization/:organizationId"
        element={<IfAuthenticated minH={minH} page={OrganizationDetailsPage} />}
      />
      <Route
        path="/organization/:organizationId/team/:teamId"
        element={<IfAuthenticated minH={minH} page={TeamDetailsPage} />}
      />
      <Route
        path="/organization/:organizationId/invitation/:invitationId/accept"
        element={
          <IfAuthenticated
            minH={minH}
            page={AcceptOrganizationInvitationPage}
          />
        }
      />
      <Route
        path="/confirm-delete"
        element={
          <IfAuthenticated minH={minH} page={ConfirmProfileDeletionPage} />
        }
      />
      <Route
        path="/resend-delete-code"
        element={
          <IfAuthenticated minH={minH} page={ResendProfileDeletinoCodePage} />
        }
      />
      <Route path="*" element={<NotFoundPage minH={minH} />} />
    </Routes>
  );
}
