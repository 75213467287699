import * as jsonWebToken from "jose";

const alg = "HS256";
const typ = "JWT";
const encodedSecret = new TextEncoder().encode(
  process.env.REACT_APP_REGISTRATION_SECRET
);
const audience = process.env.REACT_APP_URL;

export const createJwt = async (sessionToken, stateToken, payload) => {
  const requiredPayload = {
    signature: alg,
    ip: sessionToken?.ip,
  };

  const completePayload = { ...requiredPayload, ...payload };

  return await new jsonWebToken.SignJWT({
    state: stateToken,
    ...completePayload,
  })
    .setProtectedHeader({ alg, typ: typ })
    .setSubject(sessionToken.sub)
    .setIssuedAt()
    .setIssuer(sessionToken.iss)
    .setAudience(audience)
    .setExpirationTime(sessionToken.exp)
    .sign(encodedSecret);
};
