import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useAcceptOrganizationInvitationPage } from "../../hooks/useAcceptOrganizationInvitationPage";
import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { getLocalizedErrors } from "../../services/organizationInvitationService";
import LoadingPage from "./LoadingPage";
import BadRequestPage from "./BadRequestPage";
import NotFoundPage from "./NotFoundPage";
import UnauthorizedPage from "./UnauthorizedPage";

export default function AcceptOrganizationInvitationPage({ minH }) {
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId, invitationId } = useParams();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const code = urlSearchParams.get("code");

  const { isLoading, apiResponse, isError } =
    useAcceptOrganizationInvitationPage(
      organizationId,
      invitationId,
      code,
      getAccessTokenSilently
    );

  const goToOrganizationDetail = () => {
    navigate(`/organization/${organizationId}`, { replace: true });
  };

  const displayErrors = () => {
    switch (apiResponse.status) {
      case 400:
        return (
          <BadRequestPage minH={minH} title={t(`errors.securityCodeExpired`)} />
        );
      case 404:
        return (
          <NotFoundPage
            minH={minH}
            title={t(`errors.${getLocalizedErrors(apiResponse.title)}`)}
          />
        );
      default:
        return <UnauthorizedPage minH={minH} />;
    }
  };

  if (isLoading) {
    return <LoadingPage minH={minH} />;
  }

  if (isError) {
    return displayErrors();
  }

  return goToOrganizationDetail();
}
