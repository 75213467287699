import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useColorsContext } from "@buildsoft-eu/react";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";

export default function ConfirmDeletionModal({
  isOpen,
  onClose,
  t,
  message,
  requestIsLoading,
  setRequestIsLoading,
  action,
  picture = null,
  name = null,
  email = null,
  confirmButton = "common.remove",
}) {
  var { mode } = useLayoutMode();
  const { modal: modalColors } = useColorsContext();
  const modalSize = mode === LAYOUTMODES.MOBILE ? "md" : "2xl";

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay opacity="0.5" />
      <ModalContent borderRadius="md" borderTop="4px" borderColor="red.600">
        <ModalBody mb="0" pb="0" pl="12" pr="12">
          <VStack w="100%" alignItems="initial">
            <HStack w="100%" p="3" pr="5" justifyContent="center">
              <Avatar name={name} src={picture} size="md" mr="2" />
              <VStack w="100%" alignItems="start" spacing="0">
                <Text fontSize="xl" fontWeight="bold">
                  {name}
                </Text>
                {!email ? null : (
                  <Text fontWeight="light" fontSize="sm" pb="2">
                    {`${email}`}
                  </Text>
                )}
              </VStack>
            </HStack>
            <Box pl="12" pr="6" pb="8" fontWeight="light">
              {message}
            </Box>
          </VStack>
        </ModalBody>
        <ModalFooter bg={modalColors.footer.background} borderBottomRadius="md">
          <ButtonGroup spacing="8">
            <Button
              variant="unstyled"
              onClick={() => {
                setRequestIsLoading(false);
                onClose();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              isLoading={requestIsLoading}
              loadingText={t(confirmButton)}
              _hover={{ bgColor: "red.100" }}
              spinnerPlacement="end"
              bgColor="red.100"
              textColor="red.600"
              onClick={async () => {
                await action();
              }}
            >
              {t(confirmButton)}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
