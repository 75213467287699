import { useState, useEffect } from "react";
import { createJwt } from "../services/jwtService";

export const useCompleteRegistration = (
  sessionToken,
  tokenState,
  countryCode
) => {
  const [givenName, setGivenName] = useState(sessionToken?.givenName ?? "");
  const [familyName, setFamilyName] = useState(sessionToken?.familyName ?? "");
  const [displayName, setDisplayName] = useState(
    sessionToken?.displayName ?? ""
  );
  const [country, setCountry] = useState("");
  const [trainingUpdates, setTrainingUpdates] = useState(false);
  const [distributorUpdates, setDistributorUpdates] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const addJwt = async ( isRegistrationCompleted = true ) => {
    const payload = {
      isRegistrationCompleted: isRegistrationCompleted,
      country: country,
      trainingUpdates: trainingUpdates,
      distributorUpdates: distributorUpdates,
      givenName: givenName,
      familyName: familyName,
      displayName: displayName?.trim() || `${givenName} ${familyName}`,
    };
    return await createJwt(sessionToken, tokenState, payload);
  }

  useEffect(() => {
    const initializeStates = () => {
      setGivenName(sessionToken.givenName);
      setFamilyName(sessionToken.familyName);
      setDisplayName(sessionToken.displayName);
    };
    if (!sessionToken) {
      return;
    }
    initializeStates();
  }, [sessionToken]);

  useEffect(() => {
    if (!givenName?.trim()) {
      setIsValid(false);
      return;
    }
    if (!familyName?.trim()) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  }, [givenName, familyName]);

  useEffect(() => {
    setCountry(countryCode);
  }, [countryCode]);

  return {
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    displayName,
    setDisplayName,
    country,
    setCountry,
    trainingUpdates,
    setTrainingUpdates,
    distributorUpdates,
    setDistributorUpdates,
    isLoading,
    setIsLoading,
    isValid,
    addJwt,
  };
};
