import {
  Avatar,
  FormControl,
  Input,
  IconButton,
  Box,
  VStack,
  HStack,
  Select,
  Text,
  useRadioGroup,
  Center,
} from "@chakra-ui/react";
import { FaLaptop, FaArrowDown, FaServer } from "react-icons/fa";
import { useColorsContext } from "@buildsoft-eu/react";
import { TYPES } from "../services/mergeToolService";
import RadioButtons from "./RadioButtons";
import { USERCLAIMS } from "../services/PagesService";

export default function MergeToolContentMobile({
  propertyKey,
  localData,
  serverData,
  type,
  options,
  setBlobUrl,
  t,
  merge,
  setMerge,
}) {
  const { mergeTool: mergeToolColors } = useColorsContext();
  const updateStates = (value) => {
    setMerge(value);
  };
  const handlePictureChange = (value) => {
    updateStates(value);
    setBlobUrl(value);
  };
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "userPicture",
    defaultValue: type === TYPES.PICTURE ? localData : null,
    onChange: handlePictureChange,
  });
  const groupProperties = getRootProps();

  const displaySelectMerge = () => {
    const appThemes = options.map((item) => {
      return {
        key: item.name,
        label: t(item.label),
        icon: item.icon,
      };
    });
    const languages = options.map((item) => {
      return {
        key: item.name,
        label: t(item.label),
      };
    });

    const checkboxes = options.map((item) => {
      return {
        key: item.value,
        label: t(item.label),
      };
    });

    const createSelect = (element) => {
      return (
        <option key={element.key} value={element.key}>
          {element.label}
        </option>
      );
    };

    const selectList =
      propertyKey === USERCLAIMS.LOCALE
        ? languages
        : propertyKey === USERCLAIMS.PREFERREDTHEME
        ? appThemes
        : checkboxes;

    return (
      <VStack w="100%" pl="6" pr="6" pb="6" alignItems="start">
        <Text fontSize="lg" pb="2">
          {t(`userClaims.${propertyKey}`)}
        </Text>
        <VStack w="100%">
          <HStack w="100%">
            <IconButton
              variant="ghost"
              icon={
                <VStack spacing="-0.5">
                  <FaLaptop size="22px" />
                  <FaArrowDown size="14px" />
                </VStack>
              }
              onClick={() => {
                updateStates(localData);
              }}
            />
            <FormControl>
              <Input
                value={
                  selectList.find((o) => o.key === localData)?.label ??
                  localData
                }
                isReadOnly
              />
            </FormControl>
          </HStack>
          <HStack w="100%">
            <IconButton
              variant="ghost"
              icon={
                <VStack spacing="-0.5">
                  <FaServer size="22px" />
                  <FaArrowDown size="14px" />
                </VStack>
              }
              onClick={() => {
                updateStates(serverData);
              }}
            />
            <FormControl>
              <Input
                value={
                  selectList.find((o) => o.key === serverData)?.label ??
                  serverData
                }
                isReadOnly
              />
            </FormControl>
          </HStack>
        </VStack>
        <FormControl pt="3">
          <Select
            value={merge}
            onChange={(event) => {
              updateStates(event.target.value);
            }}
          >
            <option value="" disabled></option>
            {selectList.map(createSelect)}
          </Select>
        </FormControl>
      </VStack>
    );
  };

  const displayAvatar = (value, styleProps) => {
    const radioProperties = getRadioProps({ value });
    return (
      <Box w="100%" key={value} pb="3">
        <Center>
          <RadioButtons
            radioProperties={radioProperties}
            styleProps={styleProps}
            avatar={<Avatar name={value} src={value} size="lg" />}
          />
        </Center>
      </Box>
    );
  };

  const displayPictureMerge = () => {
    const styleProps = {
      cursor: "pointer",
      p: "5",
      _checked: {
        borderWidth: "3px",
        borderRadius: "md",
        borderColor: "teal.600",
      },
    };
    return (
      <VStack w="100%" pl="6" pr="6" pb="6" alignItems="start">
        <Text fontSize="lg">{t(`userClaims.${propertyKey}`)}</Text>
        <HStack w="100%" {...groupProperties} justifyContent="center">
          {options !== serverData ? displayAvatar(options, styleProps) : null}
          {options !== localData ? displayAvatar(localData, styleProps) : null}
          {displayAvatar(serverData, styleProps)}
        </HStack>
      </VStack>
    );
  };

  const displayInputMerge = () => {
    return (
      <VStack w="100%" pl="6" pr="6" pb="6" alignItems="start">
        <Text fontSize="lg" pb="2">
          {t(`userClaims.${propertyKey}`)}
        </Text>
        <VStack w="100%">
          <HStack w="100%" spacing="0.5">
            <IconButton
              variant="ghost"
              icon={
                <VStack spacing="-0.5">
                  <FaLaptop size="22px" />
                  <FaArrowDown size="14px" />
                </VStack>
              }
              onClick={() => {
                updateStates(localData);
              }}
            />
            <FormControl>
              <Input value={localData} isReadOnly />
            </FormControl>
          </HStack>
          <HStack w="100%">
            <IconButton
              variant="ghost"
              icon={
                <VStack spacing="-0.5">
                  <FaServer size="22px" />
                  <FaArrowDown size="14px" />
                </VStack>
              }
              onClick={() => {
                updateStates(serverData);
              }}
            />
            <FormControl>
              <Input value={serverData} isReadOnly />
            </FormControl>
          </HStack>
        </VStack>
        <FormControl pt="3">
          <Input
            value={merge}
            onChange={(event) => {
              updateStates(event.target.value);
            }}
          />
        </FormControl>
      </VStack>
    );
  };

  const content = () => {
    switch (type) {
      case TYPES.PICTURE:
        return displayPictureMerge();
      case TYPES.SELECT:
        return displaySelectMerge();
      case TYPES.RADIO:
        return displaySelectMerge();
      default:
        return displayInputMerge();
    }
  };

  return (
    <Box
      w="100%"
      mt="3"
      pt="4"
      borderRadius="md"
      bg={mergeToolColors.background}
    >
      {content()}
    </Box>
  );
}
