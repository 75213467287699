import {
  Button,
  Box,
  ButtonGroup,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
  useRadioGroup,
  useToast,
} from "@chakra-ui/react";
import { TbArrowBigDownLines } from "react-icons/tb";
import { useColorsContext } from "@buildsoft-eu/react";
import { useTransferOwnership } from "../hooks/useTransferOwnership";
import { ROLES } from "../services/memberRolesService";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { OrganizationByIdUrl } from "../services/urlService";
import { useAxios } from "../hooks/useAxios";
import ProfileUser from "./ProfileUser";
import RadioButtons from "./RadioButtons";

export default function TransferOwnershipModal({
  isOpen,
  onClose,
  t,
  entityId,
  currentOwner,
  otherMembers,
  setOrganization,
  setMemberRole,
  requestIsLoading,
  setRequestIsLoading,
  membersTransferOwnershipUrl,
  errorTransferOwnership,
  etag,
  setEtag,
}) {
  const { getRequest, putRequest } = useAxios();
  const { selectedMember, setSelectedMember } = useTransferOwnership();
  const toast = useToast();

  var { mode } = useLayoutMode();
  const modalSize = mode === LAYOUTMODES.MOBILE ? "md" : "2xl";
  const { modal: modalColors } = useColorsContext();

  const handleChange = (value) => {
    setSelectedMember(value);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "otherMembers",
    defaultValue: selectedMember,
    onChange: handleChange,
  });
  const groupProperties = getRootProps();

  const styleProps = {
    cursor: "pointer",
    p: "5",
    _checked: {
      borderWidth: "3px",
      borderRadius: "md",
      borderColor: "teal.600",
    },
  };

  const displayMemberList = (member) => {
    const value = member.user.id;
    const radioProperties = getRadioProps({ value });
    return (
      <Box w="100%" key={member.user.id}>
        <RadioButtons
          radioProperties={radioProperties}
          styleProps={styleProps}
          profileUser={<ProfileUser user={member.user} />}
        ></RadioButtons>
      </Box>
    );
  };

  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay opacity="0.5" />
      <ModalContent
        pt="3"
        borderRadius="md"
        borderTop="4px"
        borderColor="teal.600"
      >
        <ModalBody>
          <VStack w="100%" pt="5">
            <Box
              w="100%"
              borderWidth="3px"
              borderRadius="md"
              p="2"
              mt="3"
              borderColor="red.600"
            >
              <ProfileUser user={currentOwner.user} />
            </Box>
            <Icon as={TbArrowBigDownLines} boxSize={10} />
            <VStack {...groupProperties} w="100%" justify="end">
              {otherMembers.map(displayMemberList)}
            </VStack>
          </VStack>
        </ModalBody>
        <ModalFooter bg={modalColors.footer.background} borderBottomRadius="md">
          <ButtonGroup spacing="8">
            <Button
              variant="unstyled"
              onClick={() => {
                setRequestIsLoading(false);
                onClose();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              isDisabled={selectedMember === "" ? true : false}
              isLoading={requestIsLoading}
              loadingText={t("organizations.transferOwnership")}
              spinnerPlacement="end"
              _hover={{ bgColor: "teal.600" }}
              bgColor="teal.600"
              textColor="teal.100"
              onClick={async () => {
                const getNewEtag = async () => {
                  const { response, isError } = await getRequest(
                    OrganizationByIdUrl(entityId)
                  );
                  return isError ? null : response.data?.eTag;
                };
                const updateStates = (orga) => {
                  setOrganization(orga);
                  setEtag(orga?.eTag);
                  setMemberRole(ROLES.admin);
                  setSelectedMember("");
                  setRequestIsLoading(false);
                  onClose();
                };
                const displayError = () => {
                  setRequestIsLoading(false);
                  showError(errorTransferOwnership);
                };
                setRequestIsLoading(true);
                const { response, isError } = await putRequest(
                  membersTransferOwnershipUrl(entityId, selectedMember),
                  {},
                  etag
                );
                if (!isError) {
                  updateStates(response.data);
                  return;
                }
                if (response.response?.status !== 409) {
                  displayError();
                  return;
                }
                const newEtag = await getNewEtag();
                if (!newEtag) {
                  displayError();
                  return;
                }
                const { response: responseConflict, isError: isErrorConflict } =
                  await putRequest(
                    membersTransferOwnershipUrl(entityId, selectedMember),
                    {},
                    newEtag
                  );
                if (isErrorConflict) {
                  displayError();
                  return;
                }
                updateStates(responseConflict.data);
              }}
            >
              {t("organizations.transferOwnership")}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
