import { Stack } from "@chakra-ui/react";
import { useColorsContext } from "@buildsoft-eu/react";
import { LAYOUTMODES, useLayoutMode } from "../../hooks/useLayoutMode";
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";

export default function Header({ minH }) {
  var { mode } = useLayoutMode();
  const { header: headerColors } = useColorsContext();

  const determineContent = () => {
    switch (mode) {
      case LAYOUTMODES.MOBILE:
        return <HeaderMobile />;
      default:
        return <HeaderDesktop />;
    }
  };

  return (
    <Stack
      w="100%"
      direction="row"
      minH={minH}
      sx={{
        position: "sticky",
        top: "0",
        zIndex: 3,
        backgroundColor: headerColors.background,
      }}
    >
      {determineContent()}
    </Stack>
  );
}
