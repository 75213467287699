import { Button, MenuList, Menu, MenuButton, MenuItem } from "@chakra-ui/react";

export default function DropDownSwitcher({ icon, title, items }) {
  const LeftIcon = icon;
  const menuItemFactory = (item) => {
    const props = {
      key: item.key,
      onClick: item.onClick,
    };
    if (item.icon) {
      props.icon = <item.icon />;
    }
    return <MenuItem {...props}>{item.label}</MenuItem>;
  };
  return (
    <Menu fontsize="md" size="md" color="current">
      <MenuButton as={Button} leftIcon={<LeftIcon />} minW="130px">
        {title}
      </MenuButton>
      <MenuList fontSize="16px">{items.map(menuItemFactory)}</MenuList>
    </Menu>
  );
}
