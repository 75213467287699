import { FaMoon, FaUserCog } from "react-icons/fa";
import { MdWbSunny } from "react-icons/md";
import { Locale, LOCALENAMES, Theme, THEMENAMES } from "@buildsoft-eu/react";
import { useUserDisplayPreferences } from "./hooks/useUserDisplayPreferences";

const logger =
  process.env.REACT_APP_BUILDSOFTREACT_LOGGING === "true"
    ? {
        info: console.log,
        warn: console.warn,
        error: console.error,
      }
    : {
        info: undefined,
        warn: undefined,
        error: console.error,
      };

export const SUPPORTEDLOCALES = [
  new Locale(LOCALENAMES.ENGLISH, "English"),
  new Locale(LOCALENAMES.SPANISH, "Español"),
  new Locale(LOCALENAMES.FRENCH, "Français"),
  new Locale(LOCALENAMES.DUTCH, "Nederlands"),
];

export const systemLocaleLabel = "common.default";

export const SUPPORTEDTHEMES = [
  new Theme(THEMENAMES.DARK, "themeSwitcher.dark", FaMoon),
  new Theme(THEMENAMES.LIGHT, "themeSwitcher.light", MdWbSunny),
];
export const systemThemeIcon = FaUserCog;
export const systemThemeLabel = "common.default";

export const displayPreferencesOverrides = {
  locales: {
    supported: SUPPORTEDLOCALES,
    system: { label: systemLocaleLabel },
  },
  themes: {
    supported: SUPPORTEDTHEMES,
    system: {
      label: systemThemeLabel,
      icon: systemThemeIcon,
    },
  },
  hooks: {
    userDisplayPreferences: useUserDisplayPreferences,
  },
  logger,
};

const generalColors = {};
const lightColors = {
  mergeTool: {
    background: "gray.50",
  },
  form: {
    input: {
      background: {
        normal: "gray.200",
        hover: "gray.300",
      },
      border: {
        normal: "gray.200",
        hover: "#7e8b9f",
      },
      floatingLabel: {
        background: "white",
        text: "#7e8b9f",
      },
      icon: {
        normal: "gray.500",
      },
      text: {
        normal: "#7e8b9f",
        hover: "gray.600",
      },
    },
    radio: {
      border: "gray.300",
    },
  },
  grid: {
    background: "white",
  },
  invitations: {
    card: {
      text: {
        expires: "red.600",
        normal: "gray.500",
      },
      progress: {
        expires: "red.600",
        normal: "blue.500",
      },
    },
    create: {
      radio: {
        border: "gray.300",
      },
      input: {
        background: "gray.200",
      },
    },
  },
  modal: {
    input: {
      border: {
        normal: "gray.200",
        hover: "#cbd5e0",
        focus: "#3182ce",
      },
      floatingLabel: {
        background: "white",
        text: "#7e8b9f",
      },
      text: {
        hover: "gray.600",
      },
    },
  },
  panel: {
    background: "gray.50",
  },
};
const darkColors = {
  mergeTool: {
    background: "#2a3343",
  },
  form: {
    input: {
      background: {
        normal: "#2a3343",
        hover: "#252730",
      },
      border: {
        normal: "#2a3343",
        hover: "#5f6774",
      },
      floatingLabel: {
        background: "gray.700",
        text: "#5f6774",
      },
      icon: {
        normal: "gray.200",
      },
      text: {
        normal: "#5f6774",
        hover: "gray.400",
      },
    },
    radio: {
      border: "gray.600",
    },
  },
  grid: {
    background: "gray.700",
  },
  invitations: {
    card: {
      text: {
        expires: "red.200",
        normal: "gray.200",
      },
      progress: {
        expires: "red.400",
        normal: "blue.500",
      },
    },
    create: {
      radio: {
        border: "gray.600",
      },
      input: {
        background: "#2a3343",
      },
    },
  },
  modal: {
    input: {
      border: {
        normal: "gray.600",
        hover: "#5f6774",
        focus: "#63b3ed",
      },
      floatingLabel: {
        background: "gray.700",
        text: "#5f6774",
      },
      text: {
        hover: "gray.400",
      },
    },
  },
  panel: {
    background: "gray.900",
  },
};
export const colorOverrides = {
  general: generalColors,
  light: lightColors,
  dark: darkColors,
};
