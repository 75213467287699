import { useState, useEffect } from "react";
import { TeamsByOrganizationIdUrl } from "../services/urlService";
import { useAxios } from "./useAxios";

export const useCreateTeamModal = (
  getAccessTokenSilently,
  setTeams,
  organizationId,
  setRequestIsLoading,
  blobUrl,
  setBlobUrl,
  uploadProcessFinished,
  setUploadProcessFinished,
  onClose,
  showError,
  t
) => {
  const { postRequest } = useAxios();
  const [teamName, setTeamName] = useState("");
  const [picture, setPicture] = useState(undefined);
  const [description, setDescription] = useState("");
  const [pendingUploadPicture, setPendingUploadPicture] = useState(false);

  const cleanStates = () => {
    setTeamName("");
    setPicture("");
    setDescription("");
  };

  useEffect(() => {
    const createTeam = async () => {
      const payload = {
        displayName: teamName,
        picture: blobUrl,
        description: description.trim().length === 0 ? null : description,
      };
      const { response, isError } = await postRequest(
        TeamsByOrganizationIdUrl(organizationId),
        payload
      );
      if (isError) {
        showError(t("errors.createTeam"));
        setUploadProcessFinished(false);
        setRequestIsLoading(false);
        return;
      }
      setRequestIsLoading(false);
      setTeams((prevState) => {
        return [...prevState, response.data];
      });
      setUploadProcessFinished(false);
      setBlobUrl(null);
      cleanStates();
      onClose();
    };
    if (!uploadProcessFinished) {
      return;
    }
    createTeam();
  }, [
    blobUrl,
    setBlobUrl,
    getAccessTokenSilently,
    uploadProcessFinished,
    setRequestIsLoading,
    setUploadProcessFinished,
    organizationId,
    onClose,
    setTeams,
    teamName,
    description,
    showError,
    t,
    postRequest,
  ]);

  return {
    teamName,
    setTeamName,
    picture,
    setPicture,
    description,
    setDescription,
    cleanStates,
    pendingUploadPicture,
    setPendingUploadPicture,
  };
};
