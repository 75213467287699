import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useVerifyToken } from "../../hooks/useVerifyToken";
import { useSearchParams } from "react-router-dom";
import { useGeolocationFromIP } from "../../hooks/useGeolocationFromIP";
import NotFoundPage from "./NotFoundPage";
import LoadingPage from "./LoadingPage";
import CompleteRegistration from "../CompleteRegistration";
import LinkUserAccounts from "../LinkUserAccounts";

export default function CompleteRegistrationPage({ minH }) {
  const [searchParams] = useSearchParams();
  const { sessionToken, state } = Object.fromEntries([...searchParams]);
  const { tokenDecoded, isLoading } = useVerifyToken(
    sessionToken,
    process.env.REACT_APP_REGISTRATION_SECRET
  );
  const { t } = useTranslation();
  const { countryCode } = useGeolocationFromIP();

  if (!sessionToken || !state) {
    return <NotFoundPage minH={minH} />;
  }

  if (isLoading) {
    return <LoadingPage minH={minH} />;
  }

  return (
    <Box
      minH={minH}
      display="flex"
      alignItems={tokenDecoded?.linkAccounts ? "center" : "baseline"}
      p="5"
      justifyContent="center"
    >
      {tokenDecoded?.linkAccounts ? (
        <LinkUserAccounts
          tokenDecoded={tokenDecoded}
          tokenState={state}
          t={t}
        />
      ) : (
        <CompleteRegistration
          tokenDecoded={tokenDecoded}
          tokenState={state}
          countryCode={countryCode}
          t={t}
        />
      )}
    </Box>
  );
}
