import {
  Box,
  Flex,
  IconButton,
  VStack,
  RadioGroup,
  Radio,
  Stack,
  useDisclosure,
  useToast,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Center,
  HStack,
  Icon,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  canDeleteMember,
  canUpdateOrganizationMembershipRole,
  canUpdateOrganizationMembershipTitle,
  canUpdateTeamMembershipRole,
  canUpdateTeamMembershipTitle,
} from "../services/rulesService";
import { useColorsContext } from "@buildsoft-eu/react";
import { useMemberCard } from "../hooks/useMemberCard";
import { useRequestIsLoading } from "../hooks/useRequestIsLoading";
import { ROLES } from "../services/memberRolesService";
import { AiOutlineIdcard } from "react-icons/ai";
import { FaUserCheck } from "react-icons/fa";
import { OrganizationByIdUrl, TeamByIdUrl } from "../services/urlService";
import { useAxios } from "../hooks/useAxios";
import ProfileUser from "./ProfileUser";
import ConfirmDeletionModal from "./ConfirmDeletionModal";
import TransferOwnershipModal from "./TransferOwnershipModal";

export default function MemberCard({
  user,
  organizationUserRole,
  entityId,
  member,
  members,
  setEntity,
  roleList,
  membersByIdUrl,
  confirmRemoveMessage,
  errorUpdateMember,
  errorDeleteMember,
  errorTransferOwnership,
  t,
  membersTransferOwnershipUrl = null,
  isTeam = false,
  teamUserRole,
  etag,
  setEtag,
}) {
  const {
    memberRole,
    setMemberRole,
    title,
    setTitle,
    isMemberUpdated,
    setIsMemberUpdated,
  } = useMemberCard(member);
  const { getRequest, putRequest, deleteRequest } = useAxios();
  const {
    isOpen: isOpenConfirmRemoveMemberModal,
    onOpen: onOpenConfirmRemoveMemberModal,
    onClose: onCloseConfirmRemoveMemberModal,
  } = useDisclosure();
  const {
    isOpen: isOpenTransferOwnershipModal,
    onOpen: onOpenTransferOwnershipModal,
    onClose: onCloseTransferOwnershipModal,
  } = useDisclosure();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const toast = useToast();
  const {
    form: { input: inputColors, radio: radioColors },
  } = useColorsContext();
  const userId = user?.[`${process.env.REACT_APP_APP_METADATA}`].id;
  const inputBg = inputColors.background.normal;
  const inputHover = inputColors.background.hover;
  const inputProps = {
    variant: "filled",
    borderRadius: "full",
    bg: inputBg,
  };
  const apiUrl = isTeam ? TeamByIdUrl : OrganizationByIdUrl;

  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  const canUpdateMembershipRole = (role) => {
    return isTeam
      ? canUpdateTeamMembershipRole(role)
      : canUpdateOrganizationMembershipRole(role);
  };

  const canUpdateMembersTitle = (
    userId,
    targetUserId,
    organizationUserRole,
    teamUserRole,
    memberRole
  ) => {
    return isTeam
      ? canUpdateTeamMembershipTitle(
          userId,
          targetUserId,
          organizationUserRole,
          teamUserRole,
          memberRole
        )
      : canUpdateOrganizationMembershipTitle(
          userId,
          targetUserId,
          organizationUserRole,
          memberRole
        );
  };

  const canUpdateMembershipTitle = canUpdateMembersTitle(
    userId,
    member.user.id,
    organizationUserRole,
    teamUserRole,
    member.role
  );

  const canBecomeNewOwner = (organizationMember) => {
    if (organizationMember.user.id === member.user.id) {
      return false;
    }
    if (organizationMember.role === ROLES.guest) {
      return false;
    }
    return true;
  };

  const transferOwner = () => {
    return (
      <Box w="100%" pl="3" pr="3">
        <InputGroup w="100%">
          <InputLeftElement
            pb="2"
            pointerEvents="none"
            children={<FaUserCheck color="gray" />}
          />
          <Input
            fontWeight="light"
            isReadOnly={true}
            size="sm"
            _focus={{
              bg: inputBg,
              borderColor: inputBg,
            }}
            _hover={{
              bg: inputBg,
            }}
            w="100%"
            {...inputProps}
            value={t(`roles.${member.role}`)}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
          <InputRightElement pb="2" width="40%" pr="2">
            <Button
              variant="outline"
              size="xs"
              onClick={onOpenTransferOwnershipModal}
              fontWeight="hairline"
              fontSize="sm"
              bg={inputBg}
              borderColor={inputHover}
              _hover={{ bg: inputHover }}
            >
              <Text ml="2" mr="2">
                {t("organizations.transferOwnership")}
              </Text>
            </Button>
          </InputRightElement>
        </InputGroup>
        <TransferOwnershipModal
          isOpen={isOpenTransferOwnershipModal}
          onClose={onCloseTransferOwnershipModal}
          t={t}
          setMemberRole={setMemberRole}
          entityId={entityId}
          currentOwner={member}
          otherMembers={members.filter(canBecomeNewOwner)}
          setOrganization={setEntity}
          requestIsLoading={requestIsLoading}
          setRequestIsLoading={setRequestIsLoading}
          membersTransferOwnershipUrl={membersTransferOwnershipUrl}
          errorTransferOwnership={errorTransferOwnership}
          etag={etag}
          setEtag={setEtag}
        />
      </Box>
    );
  };

  const ownerActions = () => {
    return (
      <Box w="100%" pl="3" pr="3">
        <Center w="100%" bg={inputBg} borderRadius="full">
          <HStack w="100%" spacing="1">
            <Box pl="3.5">
              <Icon as={FaUserCheck} color="gray" />
            </Box>
            <Center w="85%" pr="1">
              <RadioGroup
                pt="1.5"
                pb="1.5"
                defaultValue={memberRole}
                value={memberRole}
                onChange={async (event) => {
                  setMemberRole(event);
                }}
              >
                <Stack spacing="3" direction="row">
                  {roleList?.map((role) => {
                    return (
                      <Radio
                        key={role.name}
                        colorScheme={role.color}
                        borderColor={radioColors.border}
                        value={role.name}
                        size="sm"
                      >
                        {t(`roles.${role.name}`)}
                      </Radio>
                    );
                  })}
                </Stack>
              </RadioGroup>
            </Center>
          </HStack>
        </Center>
      </Box>
    );
  };

  const readOnlyActions = () => {
    return (
      <Box w="100%" pl="3" pr="3">
        <InputGroup>
          <InputLeftElement
            pb="2"
            pointerEvents="none"
            children={<FaUserCheck color="gray" />}
          />
          <Input
            fontWeight="light"
            isReadOnly={true}
            size="sm"
            _focus={{
              bg: inputBg,
              borderColor: inputBg,
            }}
            _hover={{
              bg: inputBg,
            }}
            w={`${t(`roles.${member.role}`)?.length + 9}ch`}
            {...inputProps}
            value={t(`roles.${member.role}`)}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </InputGroup>
      </Box>
    );
  };

  const displayActions = () => {
    if (canUpdateMembershipRole(organizationUserRole)) {
      return isTeam
        ? ownerActions()
        : userId === member.user.id
        ? transferOwner()
        : ownerActions();
    }

    return readOnlyActions();
  };

  const displayTitle = () => {
    return (
      <Box w="100%" pl="3" pr="3">
        <InputGroup>
          <InputLeftElement
            pb="2"
            pointerEvents="none"
            children={<AiOutlineIdcard color="gray" />}
          />
          <Input
            fontWeight="light"
            isReadOnly={!canUpdateMembershipTitle}
            size="sm"
            minW="5%"
            _hover={{ bg: canUpdateMembershipTitle ? inputHover : inputBg }}
            _focus={{
              borderColor: canUpdateMembershipTitle ? {} : inputBg,
              bg: inputBg,
              minW: canUpdateMembershipTitle ? "35%" : "5%",
            }}
            w={`${title?.length + 9}ch`}
            {...inputProps}
            value={title}
            onChange={(event) => {
              setTitle(event.target.value);
            }}
          />
        </InputGroup>
      </Box>
    );
  };

  return (
    <VStack w="100%" p="2" rounded="xl" borderWidth="1px">
      <Flex w="100%" justifyContent="end" mb="0">
        <ProfileUser user={member.user}></ProfileUser>
        {canDeleteMember(organizationUserRole, member.role, isTeam) && (
          <IconButton
            size="sm"
            icon={<FaRegTrashAlt />}
            onClick={onOpenConfirmRemoveMemberModal}
          />
        )}
      </Flex>
      <ConfirmDeletionModal
        isOpen={isOpenConfirmRemoveMemberModal}
        onClose={onCloseConfirmRemoveMemberModal}
        t={t}
        message={<Text>{confirmRemoveMessage}</Text>}
        requestIsLoading={requestIsLoading}
        setRequestIsLoading={setRequestIsLoading}
        picture={member.user.picture}
        name={member.user.displayName}
        email={member.user.email}
        action={async () => {
          const updateStates = (entity) => {
            setRequestIsLoading(false);
            setEntity(entity);
            setEtag(entity?.eTag);
            onCloseConfirmRemoveMemberModal();
          };
          const displayError = () => {
            showError(errorDeleteMember);
            setRequestIsLoading(false);
            onCloseConfirmRemoveMemberModal();
          };

          setRequestIsLoading(true);
          const { response, isError } = await deleteRequest(
            membersByIdUrl(entityId, member.user.id),
            etag
          );
          if (!isError) {
            updateStates(response.data);
            return;
          }
          if (response.response?.status !== 409) {
            displayError();
            return;
          }
          let newEtag;
          let isMember;
          const { response: responseGetEntity, isError: isErrorGetEntity } =
            await getRequest(apiUrl(entityId));
          if (isErrorGetEntity) {
            return;
          }
          newEtag = responseGetEntity.data?.eTag;
          isMember = responseGetEntity.data.memberships.find(
            (m) => m.user.id === member.user.id
          );
          if (!isMember) {
            updateStates(responseGetEntity.data);
            return;
          }

          const { response: responseConflict, isError: isErrorConflict } =
            await deleteRequest(
              membersByIdUrl(entityId, member.user.id),
              newEtag
            );
          if (!isErrorConflict) {
            updateStates(responseConflict.data);
            return;
          }
          displayError();
        }}
      />
      {displayTitle()}
      {displayActions()}
      <Box w="100%">
        <Button
          size="sm"
          fontWeight="medium"
          fontSize="xs"
          isDisabled={!isMemberUpdated}
          _hover={!isMemberUpdated ? { bgColor: "red.600" } : {}}
          w="100%"
          isLoading={requestIsLoading}
          loadingText={t("common.updateMember")}
          spinnerPlacement="end"
          bgColor="red.600"
          textColor="red.100"
          onClick={async () => {
            const getNewEtag = async () => {
              const { response, isError } = await getRequest(apiUrl(entityId));
              return isError ? null : response.data?.eTag;
            };
            const updateStates = (entity) => {
              setRequestIsLoading(false);
              setIsMemberUpdated(false);
              setEntity(entity);
              setEtag(entity?.eTag);
            };
            const displayError = () => {
              setRequestIsLoading(false);
              setMemberRole(member.role);
              setTitle(member.title);
              setIsMemberUpdated(false);
              showError(errorUpdateMember);
            };

            setRequestIsLoading(true);
            const { response, isError } = await putRequest(
              membersByIdUrl(entityId, member.user.id),
              { role: memberRole, title: title },
              etag
            );
            if (!isError) {
              updateStates(response.data);
              return;
            }
            if (response.response?.status !== 409) {
              displayError();
              return;
            }
            let newEtag = await getNewEtag();
            if (!newEtag) {
              displayError();
              return;
            }
            const { response: responseConflict, isError: isErrorConflict } =
              await putRequest(
                membersByIdUrl(entityId, member.user.id),
                {
                  role: memberRole,
                  title: title,
                },
                newEtag
              );
            if (!isErrorConflict) {
              updateStates(responseConflict.data);
              return;
            }
            if (
              responseConflict.response?.status === 409 &&
              responseConflict.response.data[0].title ===
                "The user is not a member"
            ) {
              showError(responseConflict.response.data[0].title);
            }
            displayError();
          }}
        >
          {t("common.updateMember")}
        </Button>
      </Box>
    </VStack>
  );
}
