const AccountsApiUrl = process.env.REACT_APP_AUTH0_AUDIENCE;
// Main urls
export const ProfileUrl = `${AccountsApiUrl}/v2/profile`;
export const OrganizationsUrl = `${AccountsApiUrl}/v2/organizations`;
export const TeamsUrl = `${AccountsApiUrl}/v2/teams`;
export const BlobsImagesUrl = `${AccountsApiUrl}/blobs/images`;
export const GeolocationFromIP = `${AccountsApiUrl}/geolocation`;
export const NotificationsUrl = `${AccountsApiUrl}/notifications`;
// Profile urls
export const ProfileByIdUrl = (profileId) => `${ProfileUrl}/${profileId}`;
export const DeleteProfileUrl = `${ProfileUrl}/request-delete`;
export const UndoDeleteProfileUrl = `${ProfileUrl}/undo-request-delete`;
export const LinkUserAccountsUrl = `${ProfileUrl}/link-user-accounts`;
// Organization urls
export const OrganizationByIdUrl = (organizationId) =>
  `${OrganizationsUrl}/${organizationId}`;
export const OrganizationInvitationsUrl = (organizationId) =>
  `${OrganizationsUrl}/${organizationId}/invitations`;
export const OrganizationInvitationByIdUrl = (organizationId, invitationId) =>
  `${OrganizationsUrl}/${organizationId}/invitations/${invitationId}`;
export const OrganizationInvitationRenewUrl = (organizationId, invitationId) =>
  `${OrganizationsUrl}/${organizationId}/invitations/${invitationId}/renew`;
export const OrganizationInvitationAcceptUrl = (organizationId, invitationId) =>
  `${OrganizationsUrl}/${organizationId}/invitations/${invitationId}/accept`;
export const OrganizationMembersByIdUrl = (organizationId, memberId) =>
  `${OrganizationsUrl}/${organizationId}/memberships/${memberId}`;
export const OrganizationMembersTransferOwnershipUrl = (
  organizationId,
  memberId
) =>
  `${OrganizationsUrl}/${organizationId}/memberships/${memberId}/transferOwnership`;
export const TeamsByOrganizationIdUrl = (organizationId) =>
  `${OrganizationsUrl}/${organizationId}/teams`;
export const TeamByOrganizationIdAndTeamIdUrl = (organizationId, teamId) =>
  `${OrganizationsUrl}/${organizationId}/teams/${teamId}`;
// Team urls
export const TeamByIdUrl = (teamId) => `${TeamsUrl}/${teamId}`;
export const TeamMembersByIdUrl = (teamId, memberId) =>
  `${TeamsUrl}/${teamId}/memberships/${memberId}`;
export const TeamAddMemberUrl = (organizationId, teamId, memberId) =>
  `${OrganizationsUrl}/${organizationId}/teams/${teamId}/memberships/${memberId}`;

// Internal links
export const OrganizationDetailNavLink = (organizationId) =>
  `/organization/${organizationId}`;
export const TeamDetailNavLink = (organizationId, teamId) =>
  `/organization/${organizationId}/team/${teamId}`;
