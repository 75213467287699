import { Box, Flex, Text, VStack, SimpleGrid } from "@chakra-ui/react";
import { canAddMembersToTeam } from "../services/rulesService";
import { TeamMembersByIdUrl } from "../services/urlService";
import AddMembersToTeam from "./AddMembersToTeam";
import MemberCard from "./MemberCard";

export default function TeamMembers({
  user,
  organizationUserRole,
  teamUserRole,
  teamId,
  organizationId,
  organizationMembersOutOfTeam,
  members,
  setTeam,
  roleList,
  gridStyleProps,
  t,
  etag,
  setEtag,
}) {
  const displayMembers = (member) => {
    return (
      <MemberCard
        key={member.user.id}
        user={user}
        organizationUserRole={organizationUserRole}
        entityId={teamId}
        member={member}
        members={members}
        setEntity={setTeam}
        roleList={roleList}
        membersByIdUrl={TeamMembersByIdUrl}
        confirmRemoveMessage={t("teams.confirmRemoveMember")}
        errorUpdateMember={t("errors.updateTeamMember")}
        errorDeleteMember={t("errors.deleteTeamMember")}
        errorTransferOwnership={t("errors.transferTeamOwnership")}
        isTeam={true}
        teamUserRole={teamUserRole}
        t={t}
        etag={etag}
        setEtag={setEtag}
      ></MemberCard>
    );
  };

  return (
    <Box {...gridStyleProps}>
      <VStack w="100%">
        <Flex w="100%" justifyContent="left" mb="0">
          <Text fontWeight="light" fontSize="sm" pt="2">
            {t("organizations.members")}
          </Text>
        </Flex>
        {canAddMembersToTeam(organizationUserRole, teamUserRole) && (
          <AddMembersToTeam
            organizationId={organizationId}
            teamId={teamId}
            setTeam={setTeam}
            organizationMembersOutOfTeam={organizationMembersOutOfTeam}
            t={t}
            etag={etag}
            setEtag={setEtag}
          />
        )}
        <SimpleGrid w="100%" columns={{ lg: 3, md: 2, sm: 1 }} spacing="5">
          {members?.map(displayMembers)}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
