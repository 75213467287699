import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
  useToast,
  ButtonGroup,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useColorsContext,
  Input,
  Textarea,
  Upload,
  UPLOAD_ERRORCODES,
} from "@buildsoft-eu/react";
import { useCreateTeamModal } from "../hooks/useCreateTeamModal";
import { BlobsImagesUrl } from "../services/urlService";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { useRequestIsLoading } from "../hooks/useRequestIsLoading";
import { useUploadPicture } from "../hooks/useUploadPicture";
import { fileName } from "../services/uploadImageService";
import { USERCLAIMS } from "../services/PagesService";

export default function CreateTeamModal({
  isOpen,
  onClose,
  t,
  setTeams,
  organizationId,
}) {
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const {
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    uploadPicture,
    file,
    setFile,
  } = useUploadPicture(getAccessTokenSilently, toast, setRequestIsLoading);
  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };
  const {
    teamName,
    setTeamName,
    picture,
    setPicture,
    description,
    setDescription,
    cleanStates,
    pendingUploadPicture,
    setPendingUploadPicture,
  } = useCreateTeamModal(
    getAccessTokenSilently,
    setTeams,
    organizationId,
    setRequestIsLoading,
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    onClose,
    showError,
    t
  );

  const { modal: modalColors } = useColorsContext();

  var { mode } = useLayoutMode();
  const modalSize = mode === LAYOUTMODES.MOBILE ? "md" : "2xl";

  return (
    <Modal size={modalSize} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay opacity="0.5" />
      <ModalContent
        pt="6"
        borderRadius="md"
        borderTop="4px"
        borderColor="teal.600"
      >
        <ModalBody>
          <VStack w="100%" spacing="1">
            <Input
              label={t("common.name")}
              isModal={true}
              isRequired
              value={teamName}
              onChange={setTeamName}
              pb="5"
            />
            <Textarea
              label={t("teams.description")}
              isModal={true}
              value={description}
              onChange={setDescription}
              pb="5"
            />
            <Upload
              label={t(`userClaims.${USERCLAIMS.PICTURE}`)}
              isModal={true}
              message={t("uploadFiles.mainMessage")}
              description={t("uploadFiles.restrictionsMessage")}
              fileUrl={picture}
              fileName={file?.name}
              setFile={(newFile) => {
                setFile(newFile);
                const url = URL.createObjectURL(newFile);
                setPicture(url);
                setPendingUploadPicture(true);
              }}
              clearFile={() => {
                setFile(undefined);
                setPicture(undefined);
                setPendingUploadPicture(false);
              }}
              showErrors={(errors) => {
                const error = errors[0];
                switch (error.code) {
                  case UPLOAD_ERRORCODES.INVALIDTYPE:
                    showError(t("errors.invalidType"));
                    break;
                  case UPLOAD_ERRORCODES.TOOMANY:
                    showError(t("errors.tooMany"));
                    break;
                  case UPLOAD_ERRORCODES.NOTSQUARE:
                    showError(t("errors.notSquare"));
                    break;
                  case UPLOAD_ERRORCODES.TOOLARGE:
                    showError(t("errors.pictureDimensions"));
                    break;
                  default:
                    showError(t("errors.pictureSize"));
                    break;
                }
              }}
            />
          </VStack>
        </ModalBody>
        <ModalFooter bg={modalColors.footer.background} borderBottomRadius="md">
          <ButtonGroup spacing="8">
            <Button
              variant="unstyled"
              onClick={() => {
                onClose();
                setRequestIsLoading(false);
                cleanStates();
              }}
            >
              {t("common.cancel")}
            </Button>
            <Button
              isDisabled={teamName.trim().length === 0 ? true : false}
              isLoading={requestIsLoading}
              loadingText={t("teams.createTeam")}
              spinnerPlacement="end"
              _hover={{ bgColor: "teal.600" }}
              bgColor="teal.600"
              textColor="teal.100"
              onClick={async () => {
                setRequestIsLoading(true);
                if (picture?.length === 0) {
                  setUploadProcessFinished(true);
                  return;
                }
                if (!pendingUploadPicture) {
                  setUploadProcessFinished(true);
                  return;
                }
                setPendingUploadPicture(false);
                let formData = new FormData();
                formData.append(fileName, file);
                await uploadPicture(
                  formData,
                  BlobsImagesUrl,
                  t("errors.createTeam"),
                  setPicture
                );
              }}
            >
              {t("teams.createTeam")}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
