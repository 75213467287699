import { ROLES } from "./memberRolesService";

const sortAlphabetically = (a, b) => {
  return a.toLowerCase() > b.toLowerCase()
    ? 1
    : a.toLowerCase() < b.toLowerCase()
    ? -1
    : 0;
};

export const sortMembers = (members) => {
  return members?.sort((a, b) => {
    return a.role === ROLES.owner
      ? -1
      : b.role === ROLES.owner
      ? 1
      : a.role === ROLES.admin &&
        (b.role === ROLES.standard || b.role === ROLES.guest)
      ? -1
      : a.role === ROLES.standard && b.role === ROLES.admin
      ? 1
      : a.role === ROLES.standard && b.role === ROLES.guest
      ? -1
      : a.role === ROLES.guest && b.role === ROLES.admin
      ? 1
      : a.role === ROLES.guest && b.role === ROLES.standard
      ? 1
      : sortAlphabetically(a.user.displayName, b.user.displayName);
  });
};
