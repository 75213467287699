import { useRef } from "react";
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
  Spacer,
} from "@chakra-ui/react";
import { LAYOUTMODES } from "../../hooks/useLayoutMode";
import { FaBars } from "react-icons/fa";
import { useIdentityContext } from "../../contexts/IdentityContext";
import ThemeSwitcher from "./ThemeSwitcher";
import IdentityDisplayMobile from "./IdentityDisplayMobile";
import LanguageSwitcher from "./LanguageSwitcher";
import BreadcrumbMobile from "./BreadcrumbMobile";

export default function HeaderMobile() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated } = useIdentityContext();
  const btnRef = useRef();

  const showNavigation = () => {
    if (isAuthenticated) {
      return (
        <>
          <BreadcrumbMobile />
          <Spacer />
        </>
      );
    }
  };

  return (
    <Flex w="full" h="full" m="3" justifyContent="end">
      {showNavigation()}
      <IconButton
        aria-label="Open menu"
        icon={<FaBars />}
        onClick={onOpen}
        ref={btnRef}
      />
      <Drawer
        finalFocusRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        placement="right"
        size="6xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>BuildSoft Identity</DrawerHeader>
          <DrawerBody>
            <IdentityDisplayMobile />
            <Divider mt="3" mb="5" />
            <ThemeSwitcher layoutMode={LAYOUTMODES.MOBILE} />
            <Divider mt="3" mb="5" />
            <LanguageSwitcher layoutMode={LAYOUTMODES.MOBILE} />
            <Divider mt="3" mb="5" />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
