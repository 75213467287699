import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  IconButton,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { LOGOS, useLogo } from "@buildsoft-eu/react";
import { useBreadcrumbsContext } from "../../contexts/BreadcrumbsContext";

export default function BreadcrumbDesktop() {
  const { breadcrumbs } = useBreadcrumbsContext();
  const BuildSoftLogo = useLogo(LOGOS.SMALL);

  const itemFactory = (item) => {
    return (
      <BreadcrumbItem key={item.name}>
        <BreadcrumbLink fontSize="sm" as={NavLink} to={`/${item.link}`}>
          {item.name}
        </BreadcrumbLink>
      </BreadcrumbItem>
    );
  };

  return (
    <>
      <IconButton
        as={NavLink}
        to="/"
        bg="transparent"
        _hover={{ bg: "transparent" }}
        size="sm"
        icon={<BuildSoftLogo width="32px" height="32px" />}
      />
      <Breadcrumb mt="2" separator="/">
        {breadcrumbs.map(itemFactory)}
      </Breadcrumb>
    </>
  );
}
