import {
  Avatar,
  Box,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  AvatarGroup,
  HStack,
  Text,
  IconButton,
  VStack,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { useColorsContext } from "@buildsoft-eu/react";
import { useIdentityContext } from "../contexts/IdentityContext";
import { useDisplayPreferencesContext } from "@buildsoft-eu/react";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import {
  TeamDetailNavLink,
  OrganizationDetailNavLink,
} from "../services/urlService";
import { organizationsTab } from "../services/PagesService";
import { generateWebsiteLink } from "../services/generateWebsiteLink";
import { ROLES } from "../services/memberRolesService";

export default function OrganizationInformation({ organization, t }) {
  const { user } = useIdentityContext();
  const { panel: panelColors } = useColorsContext();
  const {
    locale: { toDisplayDate },
  } = useDisplayPreferencesContext();

  const getUserRole = () => {
    return organization?.memberships.find(
      (m) => m.user.id === user?.[`${process.env.REACT_APP_APP_METADATA}`].id
    )?.role;
  };

  const avatarGroupProps = {
    max: 4,
    pl: "2.5",
    size: "sm",
    fontSize: "sm",
    spacing: "-1.5",
  };

  const displayMembers = (member) => {
    const memberInfo = member?.user;
    return (
      <Tooltip
        key={memberInfo?.id}
        label={memberInfo?.displayName}
        placement="bottom"
        hasArrow
      >
        <Avatar
          key={memberInfo?.id}
          marginStart="-1"
          name={memberInfo?.displayName}
          src={memberInfo?.picture}
          size="sm"
        />
      </Tooltip>
    );
  };

  const displayTeams = (team) => {
    return (
      <Tooltip
        key={team?.id}
        label={team?.displayName}
        placement="bottom"
        hasArrow
      >
        <Avatar
          key={team?.id}
          as={NavLink}
          to={TeamDetailNavLink(organization.id, team?.id)}
          state={organizationsTab}
          marginStart="-1"
          name={team?.displayName}
          src={team?.picture}
          size="sm"
        />
      </Tooltip>
    );
  };

  const displayOwner = () => {
    const owner = organization?.memberships.find(
      (m) => m.role === "owner"
    )?.user;
    return (
      <HStack w="100%" pl="1.5">
        <Avatar
          name={owner?.displayName}
          src={owner?.picture}
          pointerEvents="none"
          size="xs"
        />
        <Text pl="0" fontSize="sm" isTruncated={isNeededTruncate(owner)}>
          {owner?.displayName}
        </Text>
      </HStack>
    );
  };

  const isNeededTruncate = (owner) => {
    const words = owner?.displayName.split(" ");
    let isTruncated = false;
    for (var i = 0; i < words.length; i++) {
      if (words[i].length > 15) {
        isTruncated = true;
      }
    }
    return isTruncated;
  };

  const displayTeamsSection = () => {
    return organization?.teams?.length === 0 && getUserRole() === ROLES.guest
      ? false
      : true;
  };

  return (
    <Box
      bg={panelColors.background}
      h="100%"
      p="2"
      pb="4"
      rounded="2xl"
      borderWidth="1px"
    >
      <Flex w="100%" justifyContent="end" mb="0">
        <IconButton
          size="sm"
          icon={<HiOutlineDotsHorizontal />}
          as={NavLink}
          to={OrganizationDetailNavLink(organization?.id)}
        />
      </Flex>
      <VStack w="100%">
        <Center>
          <Avatar
            name={organization?.displayName}
            src={organization?.picture}
            pointerEvents="none"
            size="lg"
          />
        </Center>
        <Center>
          <VStack spacing="0">
            <Heading size="md">{organization?.displayName}</Heading>
            <Link
              href={generateWebsiteLink(organization?.website)}
              isExternal
              fontSize="sm"
              textColor="blue.400"
            >
              {organization?.website}
            </Link>
          </VStack>
        </Center>
        <Divider />
        <HStack w="100%" pl="6" alignItems="initial">
          <Box w="50%">
            <FormControl w="100%">
              <FormLabel fontSize="sm" fontWeight="light">
                {t("organizations.owner")}
              </FormLabel>
              {displayOwner()}
            </FormControl>
          </Box>
          <Box w="50%">
            <FormControl w="100%">
              <FormLabel fontSize="sm" fontWeight="light">
                {t("organizations.yourRole")}
              </FormLabel>
              <Text pl="1.5" fontSize="sm">
                {t(`roles.${getUserRole()}`)}
              </Text>
            </FormControl>
          </Box>
        </HStack>
        <HStack w="100%" pl="6" alignItems="initial">
          <Box w="50%">
            <FormControl w="100%">
              <FormLabel fontSize="sm" fontWeight="light">
                {t("organizations.country")}
              </FormLabel>
              <Text pl="1.5" fontSize="sm">
                {organization?.country
                  ? t(`country.${organization?.country}`)
                  : ""}
              </Text>
            </FormControl>
          </Box>
          <Box w="50%">
            <FormControl w="100%">
              <FormLabel fontSize="sm" fontWeight="light">
                {t("organizations.lastModified")}
              </FormLabel>
              <Text pl="1.5" fontSize="sm">
                {toDisplayDate(organization?.lastModified)}
              </Text>
            </FormControl>
          </Box>
        </HStack>
        <Divider />
        <Box w="100%" pl="6">
          <FormControl>
            <FormLabel fontSize="sm" fontWeight="light">
              {t("organizations.members")}
            </FormLabel>
            <AvatarGroup {...avatarGroupProps}>
              {organization?.memberships.map(displayMembers)}
            </AvatarGroup>
          </FormControl>
        </Box>
        {displayTeamsSection() && (
          <Box w="100%">
            <Divider />
            <Box w="100%" pl="6">
              <FormControl>
                <FormLabel fontSize="sm" fontWeight="light">
                  {t("organizations.teams")}
                </FormLabel>
                <AvatarGroup {...avatarGroupProps}>
                  {organization?.teams?.map(displayTeams)}
                </AvatarGroup>
              </FormControl>
            </Box>
          </Box>
        )}
      </VStack>
    </Box>
  );
}
