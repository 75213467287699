import { useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
  Button,
  VStack,
  HStack,
  Text,
  ButtonGroup,
} from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { LOGOS, useLogo } from "@buildsoft-eu/react";
import { useBreadcrumbsContext } from "../../contexts/BreadcrumbsContext";
import { useTranslation } from "react-i18next";
import { useBreadcrumbMobile } from "../../hooks/useBreadcrumbMobile";

export default function BreadcrumbMobile() {
  const { breadcrumbs } = useBreadcrumbsContext();
  const { t } = useTranslation("translation");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const BuildSoftLogo = useLogo(LOGOS.SMALL);
  const btnRef = useRef();
  useBreadcrumbMobile(onClose);

  const itemFactory = (item) => {
    return (
      <Button
        key={item.name}
        as={NavLink}
        to={`/${item.link}`}
        justifyContent="left"
      >
        {t(item.name)}
      </Button>
    );
  };

  return (
    <>
      <IconButton
        onClick={onOpen}
        bg="transparent"
        _hover={{ bg: "transparent" }}
        size="sm"
        icon={<BuildSoftLogo width="32px" height="32px" />}
      />
      <Drawer
        finalFocusRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        placement="left"
        size="6xl"
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>BuildSoft Identity</DrawerHeader>
          <DrawerBody>
            <VStack alignItems="left">
              <HStack mb="1">
                <Text fontSize="lg">{t("breadcrumb.title")}</Text>
              </HStack>
              <ButtonGroup spacing="0" variant="ghost" flexDirection="column">
                <Button as={NavLink} to="/" justifyContent="left">
                  {t("breadcrumb.home")}
                </Button>
                {breadcrumbs.map(itemFactory)}
              </ButtonGroup>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}
