import { Box, HStack, MenuGroup, MenuItem } from "@chakra-ui/react";

export default function MenuGroupSwitcher({ icon, title, items }) {
  const LeftIcon = icon;
  const menuItemFactory = (item) => {
    const props = {
      key: item.key,
      onClick: item.onClick,
    };

    if (item.icon) {
      props.icon = <item.icon />;
    }
    return <MenuItem {...props}>{item.label}</MenuItem>;
  };
  return (
    <MenuGroup size="md" color="current" icon={<LeftIcon />}>
      <HStack spacing="10px">
        <Box>
          <LeftIcon />
        </Box>
        <Box fontWeight="semibold">{title}</Box>
      </HStack>
      {items.map(menuItemFactory)}
    </MenuGroup>
  );
}
