import { React } from "react";
import { useLogo } from "@buildsoft-eu/react";

export default function Logo(props) {
  const BuildSoftLogo = useLogo();
  const size =
    props?.h ??
    props?.height ??
    props?.size ??
    props?.w ??
    props?.width ??
    "48px";
  return (
    <BuildSoftLogo alt="BuildSoft logo" {...props} height={size} width={size} />
  );
}
