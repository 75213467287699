import { extendTheme } from "@chakra-ui/react";

const config = {
  initialColorMode: "system",
  useSystemColorMode: false,
};

const labelInFocusStyles = {
  transform: "scale(0.85) translateY(-24px) translateX(-10px)",
};

const labelInFocusLightMode = {
  ...labelInFocusStyles,
  textColor: "#3182ce",
};

const labelInFocusDarkMode = {
  ...labelInFocusStyles,
  textColor: "#63b3ed",
};

const theme = extendTheme({
  config,
  fonts: {
    heading: "lato",
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              _light: {
                label: {
                  ...labelInFocusLightMode,
                },
              },
              _dark: {
                label: {
                  ...labelInFocusDarkMode,
                },
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...labelInFocusStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
    Alert: {
      variants: {
        customNotification: () => {
          return {
            icon: {
              _light: {
                color: "orange.500",
              },
              _dark: { color: "orange.300" },
            },
            title: {
              pb: "4",
            },
            container: {
              _light: {
                borderTop: "4px",
                borderTopColor: "orange.500",
                bg: "gray.100",
              },
              _dark: {
                borderTop: "4px",
                borderTopColor: "orange.300",
                bg: "#2a3343",
              },
            },
          };
        },
      },
    },
  },
});

export default theme;
