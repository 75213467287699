import {
  Avatar,
  Box,
  Center,
  Flex,
  Button,
  ButtonGroup,
  Text,
  UnorderedList,
  ListItem,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRequestIsLoading } from "../../hooks/useRequestIsLoading";
import { useColorsContext } from "@buildsoft-eu/react";
import { LAYOUTMODES, useLayoutMode } from "../../hooks/useLayoutMode";
import { useIdentityContext } from "../../contexts/IdentityContext";
import { ProfileUrl, UndoDeleteProfileUrl } from "../../services/urlService";
import { useAxios } from "../../hooks/useAxios";

export default function ConfirmProfileDeletionPage({ minH }) {
  const { user, logout } = useIdentityContext();
  const etag = user?.[`${process.env.REACT_APP_USER_METADATA}`].etag;
  const { deleteRequest, putRequest, getRequest } = useAxios();
  const navigate = useNavigate();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const code = urlSearchParams.get("code");
  var { mode } = useLayoutMode();
  const { t } = useTranslation();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const {
    requestIsLoading: undoRequestIsLoading,
    setRequestIsLoading: setUndoRequestIsLoading,
  } = useRequestIsLoading();
  const { panel: panelColors } = useColorsContext();
  const toast = useToast();
  const gridStyleProps = {
    bg: panelColors.background,
    w: "100%",
    boxShadow: "lg",
    display: "flex",
    borderWidth: "1px",
    rounded: "md",
    p: "10",
    spacing: "3",
    borderTop: "4px",
    borderTopColor: "red.600",
    direction: {
      base: "column",
      md: "row",
    },
    justify: { base: "center" },
    align: { base: "center", md: "center" },
  };
  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  return (
    <Center
      minH={minH}
      display="flex"
      alignItems="baseline"
      p="5"
      justifyContent="center"
    >
      <Box minW={{ base: "92vw", lg: "90vw", xl: "80vw", "2xl": "75vw" }}>
        <VStack {...gridStyleProps} justifyContent="center">
          <Flex
            w="100%"
            gap="10"
            direction={{ base: "column", md: "row" }}
            mt="1"
            alignItems="center"
            justify="center"
          >
            <VStack>
              <Avatar
                size={mode === LAYOUTMODES.DESKTOP ? "2xl" : "xl"}
                ml="10"
                mr="10"
                src={user?.picture}
              />
              <Text mb="-3">{user?.name}</Text>
              <Text fontSize="sm">{user?.email}</Text>
            </VStack>
            <VStack alignItems="flex-start">
              <Text>{t("deleteProfile.deletionMessage")}</Text>
              <UnorderedList pl="2" pt="4">
                <ListItem>{t("deleteProfile.list1")}</ListItem>
                <ListItem>{t("deleteProfile.list2")}</ListItem>
              </UnorderedList>
              <Text>{t("deleteProfile.questionToConfirm")}</Text>
            </VStack>
          </Flex>
          <ButtonGroup
            pt="5"
            variant="outline"
            spacing="8"
            w="100%"
            justifyContent="center"
          >
            <Button
              variant="unstyled"
              isLoading={undoRequestIsLoading}
              loadingText={t("deleteProfile.undoDeleteAccount")}
              onClick={async () => {
                setUndoRequestIsLoading(true);
                const getNewEtag = async () => {
                  const { response, isError } = await getRequest(ProfileUrl);
                  return isError ? null : response.data?.eTag;
                };
                const successActions = () => {
                  setUndoRequestIsLoading(false);
                  navigate("/", { replace: true });
                };
                const displayError = () => {
                  showError(t("errors.undoDeleteProfile"));
                  setUndoRequestIsLoading(false);
                };
                const { response, isError } = await putRequest(
                  UndoDeleteProfileUrl,
                  {},
                  etag
                );
                if (!isError) {
                  successActions();
                  return;
                }
                if (response.response?.status !== 409) {
                  if (
                    response.response?.data[0]?.source?.reference ===
                    "/deleteRequested"
                  ) {
                    successActions();
                    return;
                  }
                  displayError();
                  return;
                }
                const newEtag = await getNewEtag();
                if (!newEtag) {
                  displayError();
                  return;
                }
                const { response: responseAfterConflict, isError: isErrorConflict } = await putRequest(
                  UndoDeleteProfileUrl,
                  {},
                  newEtag
                );
                if (isErrorConflict) {
                  if (
                    responseAfterConflict.response?.data[0]?.source
                      ?.reference === "/deleteRequested"
                  ) {
                    successActions();
                    return;
                  }
                  displayError();
                  return;
                }
                successActions();
              }}
            >
              {t("deleteProfile.undoDeleteAccount")}
            </Button>
            <Button
              isLoading={requestIsLoading}
              loadingText={t("common.remove")}
              _hover={{ bgColor: "red.100" }}
              spinnerPlacement="end"
              bgColor="red.100"
              textColor="red.600"
              onClick={async () => {
                setRequestIsLoading(true);
                const getNewEtag = async () => {
                  const { response, isError } = await getRequest(ProfileUrl);
                  return isError ? null : response.data?.eTag;
                };
                const successActions = () => {
                  logout();
                  navigate("/", { replace: true });
                  setRequestIsLoading(false);
                };
                const displayError = () => {
                  showError(t("errors.undoDeleteProfile"));
                  setRequestIsLoading(false);
                };

                const { response, isError } = await deleteRequest(
                  ProfileUrl,
                  etag,
                  {
                    code: code,
                  }
                );
                if (!isError) {
                  successActions();
                  return;
                }
                if (response.response?.status !== 409) {
                  if (response.response?.status === 422) {
                    navigate("/resend-delete-code");
                  }
                  displayError();
                  return;
                }
                const newEtag = await getNewEtag();
                if (!newEtag) {
                  displayError();
                  return;
                }
                const {
                  response: responseAfterConflict,
                  isError: isErrorConflict,
                } = await deleteRequest(ProfileUrl, newEtag, {
                  code: code,
                });
                if (isErrorConflict) {
                  if (responseAfterConflict.response?.status === 422) {
                    navigate("/resend-delete-code");
                  }
                  displayError();
                  return;
                }
                successActions();
              }}
            >
              {t("common.remove")}
            </Button>
          </ButtonGroup>
        </VStack>
      </Box>
    </Center>
  );
}
