import { useState, useEffect } from "react";
import { TYPES } from "../services/mergeToolService";

export const useMergeToolContent = (conflict, setMergedValues) => {
  const [merge, setMerge] = useState(
    conflict.type === TYPES.PICTURE ? conflict.localData : ""
  );

  useEffect(() => {
    if (merge === "") {
      return;
    }
    if (conflict.type === TYPES.PICTURE) {
      conflict.setBlobUrl(merge);
    }
    setMergedValues((prevState) => {
      const newState = prevState.filter(
        (values) => values.key !== conflict.key
      );
      newState.push({
        key: conflict.key,
        value: merge,
        setData: conflict.setData,
      });
      return newState;
    });
  }, [merge, conflict, setMergedValues]);
  return { merge, setMerge };
};
