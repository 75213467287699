import { Box, Icon, useRadio, Input, HStack, Text } from "@chakra-ui/react";

export default function RadioButtons({
  radioProperties,
  styleProps,
  children,
  icon = null,
  profileUser = null,
  avatar = null,
}) {
  const { getInputProps, getCheckboxProps } = useRadio(radioProperties);
  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <Input {...input} />
      <Box {...checkbox} {...styleProps} p={2}>
        <HStack w="100%">
          {!icon ? null : <Icon as={icon} />}
          {!profileUser ? <Text as="span">{children}</Text> : profileUser}
          {!avatar ? null : avatar}
        </HStack>
      </Box>
    </Box>
  );
}
