import { useState } from "react";
import { createHeaders } from "../services/requestHeadersService";
import axios from "axios";

export const useUploadPicture = (
  getAccessTokenSilently,
  toastComponent,
  setRequestIsLoading
) => {
  const [file, setFile] = useState();
  const [blobUrl, setBlobUrl] = useState(null);
  const [uploadProcessFinished, setUploadProcessFinished] = useState(false);

  const showError = (errorMessage) => {
    toastComponent({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  const uploadPicture = async (
    payload,
    url,
    errorMessage,
    setCurrentDisplayedPicture,
    isPrivate
  ) => {
    const token = await getAccessTokenSilently();
    const headers = createHeaders(token);
    if (isPrivate) {
      headers.isPrivate = true;
    }
    return await axios({
      method: "post",
      url,
      data: payload,
      headers,
    })
      .then((response) => {
        setBlobUrl(response.data.url);
        setCurrentDisplayedPicture(response.data.url);
        setUploadProcessFinished(true);
      })
      .catch(() => {
        setBlobUrl(null);
        setRequestIsLoading(false);
        showError(errorMessage);
      });
  };

  return {
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    file,
    setFile,
    setUploadProcessFinished,
    uploadPicture,
  };
};
