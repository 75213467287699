import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { useMergeToolContent } from "../hooks/useMergeToolContent";
import MergeToolContentDesktop from "./MergeToolContentDesktop";
import MergeToolContentMobile from "./MergeToolContentMobile";

export default function MergeToolContent({
  conflict,
  mergedValues,
  setMergedValues,
  t,
}) {
  var { mode } = useLayoutMode();
  const { merge, setMerge } = useMergeToolContent(conflict, setMergedValues);
  const params = {
    propertyKey: conflict.key,
    localData: conflict.localData,
    serverData: conflict.serverData,
    setBlobUrl: conflict.setBlobUrl,
    type: conflict.type,
    options: conflict.options,
    mergedValues: mergedValues,
    t: t,
    merge: merge,
    setMerge: setMerge,
  };

  const determineContent = () => {
    switch (mode) {
      case LAYOUTMODES.MOBILE:
        return <MergeToolContentMobile {...params} />;
      default:
        return <MergeToolContentDesktop {...params} />;
    }
  };

  return determineContent();
}
