import { useLocation } from "react-router-dom";
import { useCompleteLinkUserAccounts } from "../hooks/useCompleteLinkUserAccounts";
import { useTranslation } from "react-i18next";
import LoadingPage from "./pages/LoadingPage";
import NotFoundPage from "./pages/NotFoundPage";
import BadRequestPage from "./pages/BadRequestPage";

export default function CompleteLinkUserAccounts({ minH }) {
  const { t } = useTranslation();
  const { search } = useLocation();
  const urlSearchParams = new URLSearchParams(search);
  const code = urlSearchParams.get("code");
  const state = urlSearchParams.get("state");
  let secondaryUserId;
  let secondaryUserprovider;
  let email;
  if (state) {
    const decodedState = JSON.parse(decodeURIComponent(state));
    const secondaryUserDataArray = decodedState.id.split("|");
    secondaryUserId = decodedState.id;
    secondaryUserprovider = secondaryUserDataArray[0];
    email = decodedState.email;
  }

  const { isLoading, apiResponse, isError } = useCompleteLinkUserAccounts(
    code,
    email,
    secondaryUserprovider,
    secondaryUserId
  );

  const displayErrors = () => {
    debugger;
    switch (apiResponse.status) {
      case 404:
        return <NotFoundPage minH={minH} title={t(`errors.userNotFound`)} />;
      case 422:
        return (
          <BadRequestPage
            minH={minH}
            title={t(`errors.emailNotMatchLinkingUserAccounts`)}
          />
        );
      default:
        return (
          <BadRequestPage
            minH={minH}
            title={t(`errors.aut0ErrorLinkingUserAccounts`)}
          />
        );
    }
  };

  if (isLoading) {
    return <LoadingPage minH={minH} />;
  }

  if (isError) {
    return displayErrors();
  }
}
