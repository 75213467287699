import {
  Box,
  Button,
  Input,
  Flex,
  Text,
  VStack,
  SimpleGrid,
  useToast,
  RadioGroup,
  Radio,
  Stack,
} from "@chakra-ui/react";
import { useDisplayPreferencesContext } from "@buildsoft-eu/react";
import { useColorsContext } from "@buildsoft-eu/react";
import { useInvitations } from "../hooks/useInvitations";
import { validateEmail } from "../services/validateEmailService";
import { RiMailSendLine } from "react-icons/ri";
import { InvitationRoles } from "../services/memberRolesService";
import { ROLES } from "../services/memberRolesService";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { useRequestIsLoading } from "../hooks/useRequestIsLoading";
import { useAxios } from "../hooks/useAxios";
import InvitationCard from "../components/InvitationCard";

export default function Invitations({
  entityId,
  createInvitationUrl,
  invitationByIdUrl,
  invitationRenewUrl,
  invitations,
  setInvitations,
  gridStyleProps,
  t,
}) {
  const { postRequest } = useAxios();
  const {
    locale: { display, toDisplayDate },
  } = useDisplayPreferencesContext();
  const { emailToInvite, setEmailToInvite, invitationRole, setInvitationRole } =
    useInvitations();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const toast = useToast();
  const {
    invitations: { create: createColors },
  } = useColorsContext();
  var { mode } = useLayoutMode();
  const headerInputSize = mode === LAYOUTMODES.DESKTOP ? "md" : "sm";

  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };

  const displayInvitationRoles = (role) => {
    return (
      <Radio
        key={role.name}
        colorScheme={role.color}
        borderColor={createColors.radio.border}
        value={role.name}
        size="sm"
      >
        {t(`roles.${role.name}`)}
      </Radio>
    );
  };

  const displayLanguage = display.name;

  const displayInvitations = (invitation) => {
    return (
      <InvitationCard
        key={invitation.id}
        entityId={entityId}
        invitation={invitation}
        setInvitations={setInvitations}
        t={t}
        invitationByIdUrl={invitationByIdUrl}
        invitationRenewUrl={invitationRenewUrl}
        displayLocale={displayLanguage}
        toDisplayDate={toDisplayDate}
      />
    );
  };

  return (
    <Box {...gridStyleProps}>
      <VStack w="100%">
        <Flex w="100%" justifyContent="start" mb="0">
          <Text fontWeight="light" fontSize="sm" pt="2">
            {t("invitations.name")}
          </Text>
        </Flex>
        <Stack
          direction={{ base: "column", md: "row" }}
          w="100%"
          alignItems="center"
          justifyContent="center"
          spacing="3"
          pt="2"
          pb="5"
        >
          <Stack
            direction={{ base: "column", md: "row" }}
            w={{ base: "100%", md: "70%", lg: "75%", xl: "80%", "2xl": "80%" }}
            alignItems="center"
            spacing={{ base: "3", md: "3", lg: "5" }}
          >
            <Input
              size={headerInputSize}
              fontSize={headerInputSize}
              variant="filled"
              borderRadius="xl"
              _focus={{
                bg: createColors.input.background,
              }}
              bg={createColors.input.background}
              placeholder="name@buildsoft.eu"
              type={"email"}
              w={{
                base: "100%",
                md: "65%",
                lg: "75%",
                xl: "80%",
                "2xl": "80%",
              }}
              value={emailToInvite}
              onChange={(event) => setEmailToInvite(event.target.value)}
            />
            <VStack
              spacing="1"
              justifyContent="center"
              w={{
                base: "100%",
                md: "35%",
                lg: "25%",
                xl: "20%",
                "2xl": "20%",
              }}
            >
              <Text fontSize="sm">{t("invitations.invitationRole")}</Text>
              <RadioGroup
                defaultValue={invitationRole}
                value={invitationRole}
                onChange={(event) => {
                  setInvitationRole(event);
                }}
              >
                <Stack spacing="3" direction="row">
                  {InvitationRoles.map(displayInvitationRoles)}
                </Stack>
              </RadioGroup>
            </VStack>
          </Stack>
          <Button
            w={{ base: "100%", md: "30%", lg: "25%", xl: "20%", "2xl": "20%" }}
            isDisabled={
              !validateEmail(emailToInvite) || invitationRole.length === 0
            }
            isLoading={requestIsLoading}
            leftIcon={<RiMailSendLine />}
            colorScheme="red"
            variant="outline"
            onClick={async () => {
              setRequestIsLoading(true);
              const payload = {
                email: emailToInvite,
                asGuest: invitationRole === ROLES.guest ? true : false,
                locale: displayLanguage,
              };
              const { response, isError } = await postRequest(
                createInvitationUrl(entityId),
                payload
              );
              if (isError) {
                showError(`${t("errors.invitation")}${emailToInvite}`);
                setRequestIsLoading(false);
                return;
              }
              setInvitations((prevState) => {
                let newState = Object.assign([], prevState);
                if (!newState.find((i) => i.id === response.data.id)) {
                  newState.push(response.data);
                }
                return newState;
              });
              setEmailToInvite("");
              setRequestIsLoading(false);
            }}
          >
            {t("invitations.sendInvitation")}
          </Button>
        </Stack>
        <SimpleGrid w="100%" columns={{ lg: 3, md: 2, sm: 1 }} spacing="5">
          {invitations?.map(displayInvitations)}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
