import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { createHeaders } from "../services/requestHeadersService";
import { useCallback } from "react";

export const useAxios = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getRequest = useCallback(
    async (url) => {
      const token = await getAccessTokenSilently();
      return await axios
        .get(url, { headers: createHeaders(token) })
        .then((response) => {
          return { response, isError: false };
        })
        .catch((response) => {
          return { response, isError: true };
        });
    },
    [getAccessTokenSilently]
  );
  const putRequest = useCallback(
    async (url, payload, etag = null) => {
      const token = await getAccessTokenSilently();
      return await axios
        .put(url, payload, { headers: createHeaders(token, etag) })
        .then((response) => {
          return { response, isError: false };
        })
        .catch((response) => {
          return { response, isError: true };
        });
    },
    [getAccessTokenSilently]
  );
  const putRequestWithParams = useCallback(
    async (url, payload, params, etag = null) => {
      const token = await getAccessTokenSilently();
      let header = params;
      header.headers = createHeaders(token, etag);
      return await axios
        .put(url, payload, header)
        .then((response) => {
          return { response, isError: false };
        })
        .catch((response) => {
          return { response, isError: true };
        });
    },
    [getAccessTokenSilently]
  );
  const postRequest = useCallback(
    async (url, payload, etag = null) => {
      const token = await getAccessTokenSilently();
      return await axios
        .post(url, payload, { headers: createHeaders(token, etag) })
        .then((response) => {
          return { response, isError: false };
        })
        .catch((response) => {
          return { response, isError: true };
        });
    },
    [getAccessTokenSilently]
  );
  const deleteRequest = useCallback(
    async (url, etag = null, payload = {}) => {
      const token = await getAccessTokenSilently();
      return await axios
        .delete(url, { headers: createHeaders(token, etag), data: payload })
        .then((response) => {
          return { response, isError: false };
        })
        .catch((response) => {
          return { response, isError: true };
        });
    },
    [getAccessTokenSilently]
  );

  return {
    getRequest,
    putRequest,
    putRequestWithParams,
    postRequest,
    deleteRequest,
  };
};
