import { Box, Flex, Text, VStack, SimpleGrid } from "@chakra-ui/react";
import {
  OrganizationMembersByIdUrl,
  OrganizationMembersTransferOwnershipUrl,
} from "../services/urlService";
import MemberCard from "./MemberCard";

export default function OrganizationMembers({
  user,
  organizationUserRole,
  organizationId,
  members,
  setOrganization,
  roleList,
  gridStyleProps,
  t,
  etag,
  setEtag,
}) {
  const displayMembers = (member) => {
    return (
      <MemberCard
        key={member.user.id}
        user={user}
        organizationUserRole={organizationUserRole}
        entityId={organizationId}
        member={member}
        members={members}
        setEntity={setOrganization}
        roleList={roleList}
        membersByIdUrl={OrganizationMembersByIdUrl}
        membersTransferOwnershipUrl={OrganizationMembersTransferOwnershipUrl}
        confirmRemoveMessage={t("organizations.confirmRemoveMember")}
        errorUpdateMember={t("errors.updateOrganizationMember")}
        errorDeleteMember={t("errors.deleteOrganizationMember")}
        errorTransferOwnership={t("errors.transferOrganizationOwnership")}
        t={t}
        etag={etag}
        setEtag={setEtag}
      ></MemberCard>
    );
  };

  return (
    <Box {...gridStyleProps}>
      <VStack w="100%">
        <Flex w="100%" justifyContent="left" mb="0">
          <Text fontWeight="light" fontSize="sm" pt="2">
            {t("organizations.members")}
          </Text>
        </Flex>
        <SimpleGrid
          w="100%"
          columns={{ xl: 3, lg: 2, md: 2, sm: 1 }}
          spacing="5"
        >
          {members?.map(displayMembers)}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
