const _404ERRORTITLES = {
  UserNotFound: "User not found",
  InvitationNotFound: "Invitation not found",
  OrganizationNotFound: "Organization not found",
};

export const getLocalizedErrors = (errorTitle) => {
  switch (errorTitle) {
    case _404ERRORTITLES.UserNotFound:
      return "userNotFound";
    case _404ERRORTITLES.InvitationNotFound:
      return "invitationNotFound";
    case _404ERRORTITLES.OrganizationNotFound:
      return "organizationNotFound";
    default:
      return null;
  }
};
