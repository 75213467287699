import { createContext, useContext, useEffect, useState } from "react";
import { useIdentityContext } from "./IdentityContext";
import { NotificationsUrl } from "../services/urlService";
import { useAuth0 } from "@auth0/auth0-react";
import * as signalR from "@microsoft/signalr";

const HubContext = createContext();

const HubProvider = ({ children }) => {
  const { isAuthenticated } = useIdentityContext();
  const [connection, setConnection] = useState();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const createConnection = async () => {
      const token = await getAccessTokenSilently();
      const options = {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => token,
      };
      const connect = new signalR.HubConnectionBuilder()
        .withUrl(NotificationsUrl, options)
        .withAutomaticReconnect()
        .build();
      setConnection(connect);
    };

    if (isAuthenticated) {
      createConnection();
      return;
    }
    setConnection(null);
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (connection) {
      connection.start().catch((error) => console.log(error.toString()));
    }
  }, [connection, getAccessTokenSilently]);

  const hubConnection = {
    connection,
  };

  return (
    <HubContext.Provider value={hubConnection}>{children}</HubContext.Provider>
  );
};

const useHubContext = () => {
  return useContext(HubContext);
};

export { useHubContext, HubProvider };
