export const ROLES = {
  admin: "admin",
  owner: "owner",
  standard: "standard",
  guest: "guest",
};

export const IS_NOT_A_MEMBER = "notMember";
export const TeamRoles = [
  { name: ROLES.admin, color: "red" },
  { name: ROLES.standard, color: "green" },
];

export const OrganizationRoles = [
  ...TeamRoles,
  { name: ROLES.guest, color: "yellow" },
];

export const InvitationRoles = [
  { name: ROLES.standard, color: "green" },
  { name: ROLES.guest, color: "yellow" },
];
