import { IoMdBusiness } from "react-icons/io";
import { FaUserAlt, FaUsers } from "react-icons/fa";

export const userProfileTabs = [
  { value: "profileInformation", icon: FaUserAlt },
  { value: "organizations", icon: IoMdBusiness },
  { value: "teams", icon: FaUsers },
];
export const profileInformationTab = "profileInformation";
export const organizationsTab = "organizations";
export const teamsTab = "teams";
export const organizationDetails = "organizationDetails";

export const USERCLAIMS = {
  GIVENNAME: "given_name",
  FAMILYNAME: "family_name",
  DISPLAYNAME: "name",
  PICTURE: "picture",
  COUNTRY: "country",
  LOCALE: "locale",
  PREFERREDTHEME: "preferred_theme",
  TRAININGUPDATES: "training_updates",
  DISTRIBUTORUPDATES: "distributor_updates",
};

export const ORGANIZATIONPROPERTIES = {
  DISPLAYNAME: "displayName",
  WEBSITE: "website",
  PICTURE: "picture",
};

export const TEAMPROPERTIES = {
  DISPLAYNAME: "displayName",
  DESCRIPTION: "description",
  PICTURE: "picture",
};

export const NOTIFICATIONNAMES = {
  RECEIVENOTIFICATION: "receivenotification",
};

export const NOTIFICATIONTYPES = {
  ADDED: "added",
  CREATED: "created",
  DELETED: "deleted",
  REMOVED: "removed",
  UPDATED: "updated",
};

export const ENTITYTYPES = {
  USER: "User",
  ORGANIZATION: "Organization",
  TEAM: "Team",
};

export const POINTERTYPES = {
  INVITATIONS: "/invitations",
  MEMBERS: "/members/",
};

export const MEMBERSACTIONS = {
  ADDED: "added",
  MODIFIED: "modified",
  REMOVED: "removed",
};
