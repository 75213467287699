import { Box, Grid, VStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import LoadingPage from "./LoadingPage";
import ProfilePage from "./ProfilePage";
import Logo from "../Logo";
import { useIdentityContext } from "../../contexts/IdentityContext";

export default function LandingPage({ minH }) {
  const { t } = useTranslation("translation");
  const { isAuthenticated, isLoading } = useIdentityContext();

  if (isLoading) {
    return <LoadingPage minH={minH} />;
  }

  if (isAuthenticated) {
    return <ProfilePage minH={minH} />;
  }

  return (
    <Box fontSize="xl" textAlign="center">
      <Grid minH={minH}>
        <VStack spacing={8} justifyContent="center">
          <Logo h="40vmin" pointerEvents="none" />
          <Text>{t("app.welcome")}</Text>
        </VStack>
      </Grid>
    </Box>
  );
}
