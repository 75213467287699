export const fileName = "image";
export const ERRORCODES = {
  INVALIDTYPE: "file-invalid-type",
  TOOMANY: "too-many-files",
};

const MAXDIMENSIONS = 512;

export const isValidDimension = (width, height) => {
  return width > MAXDIMENSIONS ? false : height > MAXDIMENSIONS ? false : true;
};
