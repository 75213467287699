import { Box, Button } from "@chakra-ui/react";
import { useIdentityContext } from "../../contexts/IdentityContext";
import { FiLogIn, FiLogOut } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import ProfileUser from "../ProfileUser";
import LoadingPage from "../pages/LoadingPage";

export default function IdentityDisplayMobile() {
  const { loginWithRedirect, isAuthenticated, logout, isLoading, user } =
    useIdentityContext();
  const { t, i18n } = useTranslation("translation");

  if (isLoading) {
    return <LoadingPage spinnerSize="lg" />;
  }

  if (isAuthenticated) {
    return (
      <Box>
        <ProfileUser mb={3} user={user} />
        <Button
          justifyContent="left"
          leftIcon={<FiLogOut />}
          mt="2"
          onClick={logout}
          variant="ghost"
          w="100%"
        >
          {t("identityDisplay.logout")}
        </Button>
      </Box>
    );
  }
  return (
    <Button
      leftIcon={<FiLogIn />}
      onClick={() => loginWithRedirect({ ui_locales: i18n.language })}
      justifyContent="left"
      w="full"
      variant="ghost"
    >
      {t("identityDisplay.login")}
    </Button>
  );
}
