import { useState, useEffect } from "react";
import * as jsonWebToken from "jose";

export const useVerifyToken = (token, secret) => {
  const [tokenDecoded, setTokenDecoded] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const verifyToken = async (token) => {
      const encodeSecret = new TextEncoder().encode(secret);
      try {
        const { payload } = await jsonWebToken.jwtVerify(token, encodeSecret);
        setTokenDecoded(payload);
        setIsLoading(false);
      } catch (e) {
        console.log(e);
        setIsLoading(false);
        setTokenDecoded(null);
      }
    };
    verifyToken(token);
  }, [token, secret]);

  return { tokenDecoded, isLoading };
};
