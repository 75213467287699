import {
  Box,
  Flex,
  Stack,
  VStack,
  ButtonGroup,
  Button,
  useToast,
  useDisclosure,
  UnorderedList,
  ListItem,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useDisplayPreferencesContext,
  Checkbox,
  Input,
  Select,
  SelectOption,
  Upload,
  UPLOAD_ERRORCODES,
} from "@buildsoft-eu/react";
import { LAYOUTMODES, useLayoutMode } from "../hooks/useLayoutMode";
import { useProfileInformation } from "../hooks/useProfileInformation";
import { USERCLAIMS } from "../services/PagesService";
import { BlobsImagesUrl } from "../services/urlService";
import { useRequestIsLoading } from "../hooks/useRequestIsLoading";
import { useUploadPicture } from "../hooks/useUploadPicture";
import { fileName } from "../services/uploadImageService";
import { useHubContext } from "../contexts/HubContext";
import { useAxios } from "../hooks/useAxios";
import { DeleteProfileUrl, UndoDeleteProfileUrl } from "../services/urlService";
import { FaRegTrashAlt } from "react-icons/fa";
import { GrUndo } from "react-icons/gr";
import LoadingPage from "./pages/LoadingPage";
import ConfirmDeletionModal from "./ConfirmDeletionModal";

export default function ProfileInformation({ gridStyleProps, t, user }) {
  const { putRequest } = useAxios();
  const defaultUserPicture =
    "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp";
  const { getAccessTokenSilently } = useAuth0();
  const { connection } = useHubContext();
  var { mode } = useLayoutMode();
  const { theme, locale } = useDisplayPreferencesContext();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const {
    requestIsLoading: deleteRequestIsLoading,
    setRequestIsLoading: setDeleteRequestIsLoading,
  } = useRequestIsLoading();
  const {
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    uploadPicture,
    file,
    setFile,
  } = useUploadPicture(getAccessTokenSilently, toast, setRequestIsLoading);
  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };
  const confirmDeletionMessage = () => {
    return (
      <Box>
        <Text>{t("deleteProfile.deleteRequestMessage")}</Text>
        <UnorderedList pl="2" pt="4">
          <ListItem>{t("deleteProfile.list1")}</ListItem>
          <ListItem>{t("deleteProfile.list2")}</ListItem>
          <ListItem>{t("deleteProfile.list3")}</ListItem>
        </UnorderedList>
      </Box>
    );
  };
  const {
    picture,
    setPicture,
    givenName,
    setGivenName,
    familyName,
    setFamilyName,
    displayName,
    setDisplayName,
    country,
    preferredLocale,
    setPreferredLocale,
    preferredTheme,
    setPreferredTheme,
    trainingUpdates,
    setTrainingUpdates,
    distributorUpdates,
    setDistributorUpdates,
    userMetadata,
    userUpdates,
    setUserUpdates,
    pendingUploadPicture,
    setPendingUploadPicture,
    setEtag,
    etag,
    isDeleteRequested,
    setIsDeleteRequested,
    deleteButtonRef,
  } = useProfileInformation(
    user,
    getAccessTokenSilently,
    setRequestIsLoading,
    blobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    showError,
    t,
    connection,
    isOpen
  );

  const languages = locale.all.map((item) => {
    return {
      key: item.name,
      label: t(item.label),
    };
  });

  const appThemes = theme.all.map((item) => {
    return {
      key: item.name,
      label: t(item.label),
      icon: item.icon,
    };
  });

  const iconDeleteButton = () => {
    return isDeleteRequested ? <GrUndo /> : <FaRegTrashAlt />;
  };
  const oncliCkDeleteButton = async () => {
    return isDeleteRequested ? await undoDeleteRequest() : onOpen();
  };
  const textDeleteButton = () => {
    return isDeleteRequested
      ? t("deleteProfile.undoDeleteAccount")
      : t("deleteProfile.deleteAccount");
  };

  const addStateToUserUpdates = (stateName) => {
    if (userUpdates.indexOf(stateName) === -1) {
      setUserUpdates((prevState) => [...prevState, stateName]);
    }
  };

  const removeStateToUserUpdates = (stateName) => {
    setUserUpdates((prevState) => prevState.filter((st) => st !== stateName));
  };

  const discardChanges = () => {
    setUserUpdates([]);
    setPicture(user?.picture ?? "");
    setGivenName(user?.given_name ?? "");
    setFamilyName(user?.family_name ?? "");
    setDisplayName(user?.name ?? "");
    setPreferredLocale(user?.[`${process.env.REACT_APP_USER_METADATA}`].locale);
    setPreferredTheme(
      user?.[`${process.env.REACT_APP_USER_METADATA}`].preferred_theme
    );
    setTrainingUpdates(
      user?.[`${process.env.REACT_APP_USER_METADATA}`].training_updates
    );
    setDistributorUpdates(
      user?.[`${process.env.REACT_APP_USER_METADATA}`].distributor_updates
    );
    setEtag(user?.[`${process.env.REACT_APP_USER_METADATA}`].etag);
  };

  const undoDeleteRequest = async () => {
    setDeleteRequestIsLoading(true);
    const { isError } = await putRequest(UndoDeleteProfileUrl, {}, etag);
    if (isError) {
      showError(t("errors.undoDeleteProfile"));
      return;
    }
    setIsDeleteRequested(false);
    setDeleteRequestIsLoading(false);
  };

  if (!user) {
    return <LoadingPage />;
  }

  const isDisabledButtons = requestIsLoading || userUpdates.length === 0;
  return (
    <Box w="100%">
      <Stack {...gridStyleProps}>
        <Flex w="100%" gap="8" direction={{ base: "column", md: "row" }} mt="1">
          <Upload
            label={t(`userClaims.${USERCLAIMS.PICTURE}`)}
            message={t("uploadFiles.mainMessage")}
            description={t("uploadFiles.restrictionsMessage")}
            avatarName={displayName}
            fileUrl={picture}
            fileName={file?.name}
            layout="column"
            noBorder
            w={mode === LAYOUTMODES.MOBILE ? "100%" : "30%"}
            minW="240px"
            setFile={(newFile) => {
              setFile(newFile);
              const url = URL.createObjectURL(newFile);
              setPicture(url);
              setPendingUploadPicture(true);
            }}
            canClearFile={picture !== defaultUserPicture}
            clearFile={() => {
              setFile(undefined);
              setPicture(defaultUserPicture);
              setBlobUrl(defaultUserPicture);
              setPendingUploadPicture(false);
            }}
            showErrors={(errors) => {
              const error = errors[0];
              switch (error.code) {
                case UPLOAD_ERRORCODES.INVALIDTYPE:
                  showError(t("errors.invalidType"));
                  break;
                case UPLOAD_ERRORCODES.TOOMANY:
                  showError(t("errors.tooMany"));
                  break;
                case UPLOAD_ERRORCODES.NOTSQUARE:
                  showError(t("errors.notSquare"));
                  break;
                case UPLOAD_ERRORCODES.TOOLARGE:
                  showError(t("errors.pictureDimensions"));
                  break;
                default:
                  showError(t("errors.pictureSize"));
                  break;
              }
            }}
          />
          <VStack w={mode === LAYOUTMODES.MOBILE ? "100%" : "70%"} spacing="6">
            <Input
              label={t(`userClaims.${USERCLAIMS.GIVENNAME}`)}
              value={givenName}
              noBorder
              onChange={(newValue) => {
                setGivenName(newValue);
                if (newValue === user.given_name) {
                  removeStateToUserUpdates(USERCLAIMS.GIVENNAME);
                  return;
                }
                addStateToUserUpdates(USERCLAIMS.GIVENNAME);
              }}
            />
            <Input
              label={t(`userClaims.${USERCLAIMS.FAMILYNAME}`)}
              value={familyName}
              noBorder
              onChange={(newValue) => {
                setFamilyName(newValue);
                if (newValue === user.family_name) {
                  removeStateToUserUpdates(USERCLAIMS.FAMILYNAME);
                  return;
                }
                addStateToUserUpdates(USERCLAIMS.FAMILYNAME);
              }}
            />
            <Input
              label={t(`userClaims.${USERCLAIMS.DISPLAYNAME}`)}
              value={displayName}
              noBorder
              onChange={(newValue) => {
                setDisplayName(newValue);
                if (newValue === user.name) {
                  removeStateToUserUpdates(USERCLAIMS.DISPLAYNAME);
                  return;
                }
                addStateToUserUpdates(USERCLAIMS.DISPLAYNAME);
              }}
            />
            <Input
              label={t(`userClaims.${USERCLAIMS.COUNTRY}`)}
              value={t(`country.${country}`)}
              noBorder
              isReadOnly
            />
          </VStack>
          {mode === LAYOUTMODES.DESKTOP ? (
            <Tooltip hasArrow label={textDeleteButton()}>
              <Button
                mt="-3"
                isLoading={deleteRequestIsLoading}
                size="sm"
                ref={deleteButtonRef}
                onClick={async () => await oncliCkDeleteButton()}
              >
                {iconDeleteButton()}
              </Button>
            </Tooltip>
          ) : (
            <Button
              w="100%"
              isLoading={deleteRequestIsLoading}
              loadingText={textDeleteButton()}
              size="xs"
              variant="ghost"
              fontWeight="ligth"
              leftIcon={iconDeleteButton()}
              onClick={async () => {
                await oncliCkDeleteButton();
              }}
            >
              {textDeleteButton()}
            </Button>
          )}
        </Flex>
        <ConfirmDeletionModal
          isOpen={isOpen}
          onClose={onClose}
          t={t}
          message={confirmDeletionMessage()}
          requestIsLoading={deleteRequestIsLoading}
          setRequestIsLoading={setDeleteRequestIsLoading}
          picture={picture}
          name={displayName}
          email={user?.email}
          confirmButton="common.send"
          action={async () => {
            setDeleteRequestIsLoading(true);
            const { isError } = await putRequest(DeleteProfileUrl, {}, etag);
            if (isError) {
              showError(t("errors.deleteProfile"));
              return;
            }
            setIsDeleteRequested(true);
            setDeleteRequestIsLoading(false);
            onClose();
          }}
        />
      </Stack>
      <VStack {...gridStyleProps} mt="2">
        <Stack
          direction={{
            base: "column",
            md: "row",
          }}
          w="100%"
        >
          <Select
            label={t(`userClaims.${USERCLAIMS.LOCALE}`)}
            selected={preferredLocale}
            items={languages.map((l) => {
              return new SelectOption(l.key, l.key, l.label);
            })}
            noBorder
            onChange={(newValue) => {
              setPreferredLocale(newValue);
              if (newValue === userMetadata?.locale) {
                removeStateToUserUpdates(USERCLAIMS.LOCALE);
                return;
              }
              addStateToUserUpdates(USERCLAIMS.LOCALE);
            }}
            mt="1"
          />
          <Select
            label={t(`userClaims.${USERCLAIMS.PREFERREDTHEME}`)}
            selected={preferredTheme}
            items={appThemes.map((t) => {
              return new SelectOption(t.key, t.key, t.label);
            })}
            noBorder
            onChange={(newValue) => {
              setPreferredTheme(newValue);
              if (newValue === userMetadata?.preferred_theme) {
                removeStateToUserUpdates(USERCLAIMS.PREFERREDTHEME);
                return;
              }
              addStateToUserUpdates(USERCLAIMS.PREFERREDTHEME);
            }}
            mt="1"
          />
        </Stack>
        <VStack w="100%">
          <Checkbox
            label={t(`userClaims.${USERCLAIMS.TRAININGUPDATES}`)}
            value={trainingUpdates}
            onChange={(newValue) => {
              setTrainingUpdates(newValue);
              if (newValue === userMetadata?.training_updates) {
                removeStateToUserUpdates(USERCLAIMS.TRAININGUPDATES);
                return;
              }
              addStateToUserUpdates(USERCLAIMS.TRAININGUPDATES);
            }}
          />
          <Checkbox
            label={t(`userClaims.${USERCLAIMS.DISTRIBUTORUPDATES}`)}
            value={distributorUpdates}
            onChange={(newValue) => {
              setDistributorUpdates(newValue);
              if (newValue === userMetadata?.distributor_updates) {
                removeStateToUserUpdates(USERCLAIMS.DISTRIBUTORUPDATES);
                return;
              }
              addStateToUserUpdates(USERCLAIMS.DISTRIBUTORUPDATES);
            }}
          />
        </VStack>
      </VStack>
      <ButtonGroup
        variant="outline"
        spacing="3"
        w="100%"
        justifyContent="end"
        p="5"
      >
        <Button
          isLoading={requestIsLoading}
          loadingText={t("common.save")}
          spinnerPlacement="end"
          colorScheme="red"
          isDisabled={isDisabledButtons}
          onClick={async () => {
            setRequestIsLoading(true);
            if (picture === user.picture) {
              setUploadProcessFinished(true);
              return;
            }
            if (!pendingUploadPicture) {
              setUploadProcessFinished(true);
              return;
            }
            setPendingUploadPicture(false);
            let formData = new FormData();
            formData.append(fileName, file);
            await uploadPicture(
              formData,
              BlobsImagesUrl,
              t("errors.updateUserProfile"),
              setPicture,
              true
            );
          }}
        >
          {t("common.save")}
        </Button>
        <Button isDisabled={isDisabledButtons} onClick={() => discardChanges()}>
          {t("common.discard")}
        </Button>
      </ButtonGroup>
    </Box>
  );
}
