import { Box, HStack, Stack, useRadioGroup, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useColorsContext } from "@buildsoft-eu/react";
import { useProfilePage } from "../../hooks/useProfilePage";
import {
  userProfileTabs,
  profileInformationTab,
} from "../../services/PagesService";
import { useIdentityContext } from "../../contexts/IdentityContext";
import ProfileUser from "../ProfileUser";
import Organizations from "../Organizations";
import Teams from "../Teams";
import RadioButtons from "../RadioButtons";
import ProfileInformation from "../ProfileInformation";

export default function ProfilePage({ minH }) {
  const { user } = useIdentityContext();
  const lastSelectedTab = window.history.state?.usr?.tab;
  const {
    selectedTab,
    setSelectedTab,
    organizations,
    setOrganizations,
    teams,
    setTeams,
  } = useProfilePage(lastSelectedTab ?? profileInformationTab);
  const { panel: panelColors } = useColorsContext();
  const { t } = useTranslation();
  const gridStyleProps = {
    bg: panelColors.background,
    w: "100%",
    boxShadow: "lg",
    display: "flex",
    borderWidth: "1px",
    rounded: "2xl",
    p: "4",
    spacing: "3",
    direction: {
      base: "column",
      md: "row",
    },
    justify: { base: "center" },
    align: { base: "center", md: "center" },
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: "userTabs",
    defaultValue: selectedTab,
    onChange: (value) => {
      setSelectedTab(value);
    },
  });

  const selectContent = () => {
    const props = {
      gridStyleProps: gridStyleProps,
      t: t,
      user: user,
    };
    switch (selectedTab) {
      case "organizations":
        return (
          <Organizations
            {...props}
            organizations={organizations}
            setOrganizations={setOrganizations}
          />
        );
      case "teams":
        return <Teams {...props} teams={teams} setTeams={setTeams} />;
      default:
        return <ProfileInformation {...props} />;
    }
  };
  const groupProperties = getRootProps();

  const styleProps = {
    fontSize: "sm",
    cursor: "pointer",
    borderWidth: "0.5px",
    borderRadius: "md",
    boxShadow: "sm",
    _checked: {
      bg: "red.600",
      color: "white",
      borderColor: "red.600",
    },
  };

  return (
    <Box
      minH={minH}
      display="flex"
      alignItems="baseline"
      p="5"
      justifyContent="center"
    >
      <VStack minW={{ base: "92vw", lg: "90vw", xl: "80vw", "2xl": "75vw" }}>
        <Stack {...gridStyleProps}>
          <ProfileUser user={user} />
          <HStack {...groupProperties} w="100%" justify="end">
            {userProfileTabs.map(({ value, icon }) => {
              const radioProperties = getRadioProps({ value });
              return (
                <RadioButtons
                  key={value}
                  radioProperties={radioProperties}
                  icon={icon}
                  styleProps={styleProps}
                >
                  {t(`common.${value}`)}
                </RadioButtons>
              );
            })}
          </HStack>
        </Stack>
        {selectContent()}
      </VStack>
    </Box>
  );
}
