import {
  Avatar,
  Box,
  ButtonGroup,
  Button,
  Center,
  Flex,
  Text,
  IconButton,
  Spacer,
  VStack,
  useDisclosure,
  useToast,
  HStack,
} from "@chakra-ui/react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  useColorsContext,
  useDisplayPreferencesContext,
  Input,
  Textarea,
  Upload,
  UPLOAD_ERRORCODES,
} from "@buildsoft-eu/react";
import { useRef } from "react";
import { TeamByIdUrl, BlobsImagesUrl } from "../../services/urlService";
import { useTranslation } from "react-i18next";
import { useTeamDetailsPage } from "../../hooks/useTeamDetailsPage";
import { useIdentityContext } from "../../contexts/IdentityContext";
import { useParams, useNavigate } from "react-router-dom";
import { FaRegTrashAlt, FaUsers } from "react-icons/fa";
import { useRequestIsLoading } from "../../hooks/useRequestIsLoading";
import {
  TEAMPROPERTIES,
  ENTITYTYPES,
  teamsTab,
  organizationDetails,
} from "../../services/PagesService";
import { TeamRoles } from "../../services/memberRolesService";
import {
  canDeleteTeam,
  canUpdateTeamInformation,
} from "../../services/rulesService";
import { useLocation } from "react-router-dom";
import { LAYOUTMODES, useLayoutMode } from "../../hooks/useLayoutMode";
import { IoMdBusiness } from "react-icons/io";
import { useUploadPicture } from "../../hooks/useUploadPicture";
import { fileName } from "../../services/uploadImageService";
import { sortMembers } from "../../services/sortingService";
import { useAxios } from "../../hooks/useAxios";
import NotFoundPage from "./NotFoundPage";
import LoadingPage from "./LoadingPage";
import ConfirmDeletionModal from "../ConfirmDeletionModal";
import TeamMembers from "../TeamMembers";
import MergeTool from "../MergeTool";
import NotifyEntityDeletionModal from "../NotifyEntityDeletionModal";
import { MERGEACTION } from "../../services/mergeToolService";
import { USERCLAIMS } from "../../services/PagesService";

export default function TeamDetailsPage({ minH }) {
  const { state: previousPage } = useLocation();
  const { user } = useIdentityContext();
  const {
    locale: { toDisplayDate },
  } = useDisplayPreferencesContext();
  const { organizationId, teamId } = useParams();
  const { getRequest, deleteRequest } = useAxios();
  const { getAccessTokenSilently } = useAuth0();
  const { t } = useTranslation();
  const toast = useToast();
  const toastIdRef = useRef();
  const { requestIsLoading, setRequestIsLoading } = useRequestIsLoading();
  const {
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    uploadPicture,
    file,
    setFile,
  } = useUploadPicture(getAccessTokenSilently, toast, setRequestIsLoading);
  const showError = (errorMessage) => {
    toast({
      title: errorMessage,
      status: "error",
      isClosable: true,
    });
  };
  const closeToast = () => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
  };
  const addNotificationButtons = () => {
    return (
      <HStack w="100%" justify="end">
        <ButtonGroup spacing="8">
          <Button
            size="sm"
            variant="unstyled"
            onClick={() => {
              setNotificationAction(MERGEACTION.TAKESERVER);
              closeToast();
            }}
          >
            {t("mergeTool.takeServer")}
          </Button>
          <Button
            size="sm"
            variant="unstyled"
            loadingText={t("common.remove")}
            onClick={() => {
              setNotificationAction(MERGEACTION.DISPLAYMERGETOOL);
              closeToast();
            }}
          >
            {t("mergeTool.compareChanges")}
          </Button>
        </ButtonGroup>
      </HStack>
    );
  };
  const notificationTeamUpdated = (notificationMessage) => {
    if (!toast.isActive("notificationToast")) {
      toastIdRef.current = toast({
        title: notificationMessage,
        description: addNotificationButtons(),
        id: "notificationToast",
        isClosable: false,
        duration: null,
        position: "bottom",
        variant: ["customNotification"],
      });
    }
  };
  const {
    isOpen: isOpenMergeTool,
    onOpen: onOpenMergeTool,
    onClose: onCloseMergeTool,
  } = useDisclosure();
  const {
    isOpen: isOpenNotifyDeletion,
    onOpen: onOpenNotifyDeletion,
    onClose: onCloseNotifyDeletion,
  } = useDisclosure();
  const navigate = useNavigate();
  const goToTeams = () =>
    navigate("/", { replace: true, state: { tab: previousPage ?? teamsTab } });

  const goToOrganizationDetail = () =>
    navigate(`/organization/${organizationId}`, { replace: true });

  const goToPreviousPage = () => {
    return previousPage === organizationDetails
      ? goToOrganizationDetail()
      : goToTeams();
  };

  const {
    team,
    setTeam,
    teamName,
    setTeamName,
    teamPicture,
    setTeamPicture,
    teamDescription,
    setTeamDescription,
    teamUpdates,
    setTeamUpdates,
    pageIsLoading,
    organization,
    organizationMembersOutOfTeam,
    etag,
    setEtag,
    pendingUploadPicture,
    setPendingUploadPicture,
    contentToMerge,
    mergedValues,
    setMergedValues,
    setTakeMergedValues,
    setTakeServer,
    teamInServer,
    countDown,
    entityDeleted,
    setNotificationAction,
    deletionMessageModal,
    organizationUserRole,
    teamUserRole,
  } = useTeamDetailsPage(
    teamId,
    organizationId,
    user,
    getAccessTokenSilently,
    setRequestIsLoading,
    blobUrl,
    setBlobUrl,
    uploadProcessFinished,
    setUploadProcessFinished,
    showError,
    t,
    onOpenMergeTool,
    file,
    onOpenNotifyDeletion,
    goToPreviousPage,
    navigate,
    notificationTeamUpdated
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { panel: panelColors } = useColorsContext();
  const gridStyleProps = {
    bg: panelColors.background,
    w: "100%",
    boxShadow: "lg",
    display: "flex",
    borderWidth: "1px",
    rounded: "2xl",
    p: "4",
    spacing: "3",
    direction: {
      base: "column",
      md: "row",
    },
    justify: { base: "center" },
    align: { base: "center", md: "center" },
  };
  var { mode } = useLayoutMode();

  const canUpdateTeamInfo = canUpdateTeamInformation(
    organizationUserRole,
    teamUserRole
  );

  const discardChanges = () => {
    setTeamUpdates([]);
    setTeamName(team?.displayName);
    setTeamPicture(team?.picture);
    setTeamDescription(team?.description ?? "");
    setEtag(team?.eTag);
  };

  const updatePicture = () => {
    return (
      <Upload
        label={t(`userClaims.${USERCLAIMS.PICTURE}`)}
        message={t("uploadFiles.mainMessage")}
        description={t("uploadFiles.restrictionsMessage")}
        w={mode === LAYOUTMODES.MOBILE ? "100%" : "25%"}
        layout="column"
        noBorder
        avatarName={team?.displayName}
        fileUrl={teamPicture}
        fileName={file?.name}
        setFile={(newFile) => {
          setFile(newFile);
          const url = URL.createObjectURL(newFile);
          setTeamPicture(url);
          setPendingUploadPicture(true);
        }}
        canClearFile={teamPicture?.length > 0}
        clearFile={() => {
          setFile(undefined);
          setTeamPicture("");
          setBlobUrl("");
          setPendingUploadPicture(false);
        }}
        showErrors={(errors) => {
          const error = errors[0];
          switch (error.code) {
            case UPLOAD_ERRORCODES.INVALIDTYPE:
              showError(t("errors.invalidType"));
              break;
            case UPLOAD_ERRORCODES.TOOMANY:
              showError(t("errors.tooMany"));
              break;
            case UPLOAD_ERRORCODES.NOTSQUARE:
              showError(t("errors.notSquare"));
              break;
            case UPLOAD_ERRORCODES.TOOLARGE:
              showError(t("errors.pictureDimensions"));
              break;
            default:
              showError(t("errors.pictureSize"));
              break;
          }
        }}
      />
    );
  };

  const displayTeamPicture = () => {
    return (
      <Center>
        <Avatar
          size={mode === LAYOUTMODES.DESKTOP ? "2xl" : "xl"}
          ml="10"
          mr="10"
          src={teamPicture}
        />
      </Center>
    );
  };

  const addStateToTeamUpdates = (stateName) => {
    if (teamUpdates.indexOf(stateName) === -1) {
      setTeamUpdates((prevState) => [...prevState, stateName]);
    }
  };

  const removeStateToTeamUpdates = (stateName) => {
    setTeamUpdates((prevState) => prevState.filter((st) => st !== stateName));
  };

  if (pageIsLoading) {
    return <LoadingPage minH={minH} />;
  }

  if (Object.keys(team).length === 0) {
    return <NotFoundPage minH={minH} />;
  }

  return (
    <Box
      minH={minH}
      display="flex"
      alignItems="baseline"
      p="5"
      justifyContent="center"
    >
      <VStack minW={{ base: "92vw", lg: "90vw", xl: "80vw", "2xl": "75vw" }}>
        <VStack {...gridStyleProps}>
          <Box w="100%">
            <Flex w="100%" justifyContent="end" mb="0">
              <Text fontWeight="light" fontSize="sm" pt="2">
                {t("organizations.lastModified")}
                {": "}
                {toDisplayDate(team?.lastModified)}
              </Text>
              <Spacer />
              {canDeleteTeam(organizationUserRole) && (
                <IconButton
                  size="sm"
                  icon={<FaRegTrashAlt />}
                  onClick={onOpen}
                />
              )}
            </Flex>
            <ConfirmDeletionModal
              isOpen={isOpen}
              onClose={onClose}
              t={t}
              message={<Text>{t("teams.confirmRemove")}</Text>}
              requestIsLoading={requestIsLoading}
              setRequestIsLoading={setRequestIsLoading}
              picture={team.picture}
              name={team.displayName}
              action={async () => {
                const getNewEtag = async () => {
                  const { response, isError } = await getRequest(
                    TeamByIdUrl(teamId)
                  );
                  return isError ? null : response.data?.eTag;
                };
                const updateStates = () => {
                  setRequestIsLoading(false);
                  goToPreviousPage();
                  onClose();
                };
                const displayError = () => {
                  showError(t("errors.deleteTeam"));
                  setRequestIsLoading(false);
                  onClose();
                };
                setRequestIsLoading(true);

                const { response, isError } = await deleteRequest(
                  TeamByIdUrl(team.id),
                  etag
                );
                if (!isError) {
                  updateStates();
                  return;
                }
                if (response.response?.status !== 409) {
                  displayError();
                  return;
                }
                const newEtag = await getNewEtag();
                if (!newEtag) {
                  return;
                }
                const { isError: isErrorConflict } = await deleteRequest(
                  TeamByIdUrl(team.id),
                  newEtag
                );
                if (isErrorConflict) {
                  displayError();
                  return;
                }
                updateStates();
              }}
            />
          </Box>
          <Flex
            w="100%"
            gap="8"
            direction={{ base: "column", md: "row" }}
            mt="1"
          >
            {canUpdateTeamInfo ? updatePicture() : displayTeamPicture()}
            <VStack
              w={mode === LAYOUTMODES.MOBILE ? "100%" : "70%"}
              spacing="6"
            >
              <Input
                label={t(`organizations.nameLowerCase`)}
                value={organization?.displayName ?? ""}
                noBorder
                icon={IoMdBusiness}
                isReadOnly
              />
              <Input
                label={t("common.name")}
                value={teamName}
                noBorder
                icon={FaUsers}
                isReadOnly={!canUpdateTeamInfo}
                onChange={(newValue) => {
                  setTeamName(newValue);
                  const valueToCompare = teamInServer ? teamInServer : team;
                  if (newValue === valueToCompare.displayName) {
                    removeStateToTeamUpdates(TEAMPROPERTIES.DISPLAYNAME);
                    return;
                  }
                  addStateToTeamUpdates(TEAMPROPERTIES.DISPLAYNAME);
                }}
              />
              <Textarea
                label={t("teams.description")}
                value={teamDescription}
                noBorder
                isReadOnly={!canUpdateTeamInfo}
                onChange={(newValue) => {
                  setTeamDescription(newValue);
                  const valueToCompare = teamInServer ? teamInServer : team;
                  if (newValue === valueToCompare.description) {
                    removeStateToTeamUpdates(TEAMPROPERTIES.DESCRIPTION);
                    return;
                  }
                  addStateToTeamUpdates(TEAMPROPERTIES.DESCRIPTION);
                }}
              />
            </VStack>
          </Flex>

          <Flex w="100%" justifyContent="end" mb="0">
            {canUpdateTeamInfo && (
              <ButtonGroup
                variant="outline"
                spacing="3"
                w="100%"
                justifyContent="end"
                pr="5"
              >
                <Button
                  isLoading={requestIsLoading}
                  isDisabled={
                    requestIsLoading
                      ? requestIsLoading
                      : teamUpdates.length > 0
                      ? false
                      : true
                  }
                  loadingText={t("common.save")}
                  spinnerPlacement="end"
                  colorScheme="red"
                  onClick={async () => {
                    setRequestIsLoading(true);
                    closeToast();
                    if (teamPicture === team.picture) {
                      setUploadProcessFinished(true);
                      return;
                    }
                    if (!pendingUploadPicture) {
                      setUploadProcessFinished(true);
                      return;
                    }
                    setPendingUploadPicture(false);
                    let formData = new FormData();
                    formData.append(fileName, file);
                    await uploadPicture(
                      formData,
                      BlobsImagesUrl,
                      t("errors.updateTeam"),
                      setTeamPicture
                    );
                  }}
                >
                  {t("common.save")}
                </Button>
                <Button
                  isDisabled={
                    requestIsLoading
                      ? requestIsLoading
                      : teamUpdates.length > 0
                      ? false
                      : true
                  }
                  onClick={() => discardChanges()}
                >
                  {t("common.discard")}
                </Button>
              </ButtonGroup>
            )}
          </Flex>
        </VStack>
        <TeamMembers
          user={user}
          organizationUserRole={organizationUserRole}
          teamUserRole={teamUserRole}
          teamId={teamId}
          organizationId={organizationId}
          organizationMembersOutOfTeam={organizationMembersOutOfTeam}
          members={sortMembers(team.memberships)}
          setTeam={setTeam}
          roleList={TeamRoles}
          t={t}
          etag={etag}
          setEtag={setEtag}
          gridStyleProps={gridStyleProps}
        />
      </VStack>
      <MergeTool
        isOpen={isOpenMergeTool}
        onClose={onCloseMergeTool}
        contentToMerge={contentToMerge}
        t={t}
        mergedValues={mergedValues}
        setMergedValues={setMergedValues}
        setTakeMergedValues={setTakeMergedValues}
        setTakeServer={setTakeServer}
      />
      <NotifyEntityDeletionModal
        isOpen={isOpenNotifyDeletion}
        onClose={onCloseNotifyDeletion}
        message={`${t(deletionMessageModal)} ${countDown} ${t(
          "common.seconds"
        )}`}
        picture={
          entityDeleted === ENTITYTYPES.TEAM
            ? team?.picture
            : organization?.picture
        }
        name={
          entityDeleted === ENTITYTYPES.TEAM
            ? team?.displayName
            : organization?.displayName
        }
      />
    </Box>
  );
}
