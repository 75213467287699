import { useAuth0 } from "@auth0/auth0-react";
import { LOCALENAMES, THEMENAMES } from "@buildsoft-eu/react";

const getUserPreferredTheme = (user) => {
  const userMetadata = user?.[`${process.env.REACT_APP_USER_METADATA}`];
  return userMetadata?.preferred_theme ?? THEMENAMES.SYSTEM;
};

const getUserLocale = (user) => {
  const userMetadata = user?.[`${process.env.REACT_APP_USER_METADATA}`];
  return userMetadata?.locale ?? LOCALENAMES.SYSTEM;
};

export const useUserDisplayPreferences = () => {
  const { user } = useAuth0();
  const theme = getUserPreferredTheme(user);
  const locale = getUserLocale(user);
  return { locale, theme };
};
