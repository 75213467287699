export const TYPES = {
  INPUT: "input",
  PICTURE: "picture",
  SELECT: "select",
  RADIO: "radio",
};

export const MERGEACTION = {
  TAKESERVER: "takeServer",
  DISPLAYMERGETOOL: "mergeTool",
};

export const addConflictToList = (
  list,
  key,
  localData,
  serverData,
  setData,
  type = TYPES.INPUT,
  options = null,
  setBlobUrl = null
) => {
  list.push({
    key: key,
    localData: localData,
    serverData: serverData,
    setData: setData,
    type: type,
    options: options,
    setBlobUrl: setBlobUrl,
  });
};
